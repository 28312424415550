<div
  *ngIf="
    user.data.id === taskDataObj?.assignedUserId ||
    user.data.claimId === 5 ||
    user.data.claimId === 6
  "
>
  <button
    (click)="open(editTasks)"
    mat-mini-fab
    color="primary"
    class="mr-1"
    matTooltip="Detay"
  >
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>

<ng-template #editTasks let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Görev Detayı</h5>
    <button
      class="close"
      type="button"
      data-dismiss="modal"
      aria-label="Close"
      data-original-title=""
      title=""
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-row">
      <div class="form-group col-md-12">
        <label>Atanan Kullanıcı</label>
        <select
          class="form-control m-5"
          [(ngModel)]="taskDataObj.assignedUserId"
          [disabled]="user.data.claimId < 5"
        >
          <option selected hidden>
            {{ taskData[0]?.assignedUserName }}
          </option>
          <option [value]="item.id" *ngFor="let item of users">
            {{ item.fullName }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="bm-desc">Görev Detayı</label>
        <textarea
          class="form-control"
          id="bm-desc"
          type="text"
          [(ngModel)]="taskDataObj.details"
          autocomplete="off"
          placeholder="Görev Detayı"
          [disabled]="user.data.claimId < 5"
        ></textarea>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="bm-desc">Başlangıç Tarihi</label>
        <p>
          <strong>
            {{ taskDataObj?.startDate | date : " dd.MM.yyyy HH:mm" }}
          </strong>
        </p>
      </div>
      <div class="form-group col-md-6">
        <label for="bm-desc">Bitiş Tarihi</label>
        <p>
          <strong>
            {{ taskDataObj?.endDate | date : " dd.MM.yyyy HH:mm" }}
          </strong>
        </p>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="bm-desc">Tahmini Saat</label>
        <p *ngIf="user.data.claimId < 5">
          <strong>
            {{ taskDataObj?.estimatedHour }}
          </strong>
        </p>
        <input
          class="form-control"
          id="bm-desc"
          type="number"
          autocomplete="off"
          [(ngModel)]="taskDataObj.estimatedHour"
          placeholder="Tahmini Saat"
          *ngIf="user.data.claimId > 4"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="bm-desc">Tahmini Gün</label>
        <p *ngIf="user.data.claimId < 5">
          <strong>
            {{ taskDataObj?.estimatedDay }}
          </strong>
        </p>
        <input
          class="form-control"
          id="bm-desc"
          type="text"
          autocomplete="off"
          [(ngModel)]="taskDataObj.estimatedDay"
          placeholder="Tahmini Gün"
          *ngIf="user.data.claimId > 4"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="bm-desc">Asıl Saat</label>
        <p>
          <strong>
            {{ taskDataObj?.actualHour }}
          </strong>
        </p>
        <!-- <input
          class="form-control"
          id="bm-desc"
          type="text"
          autocomplete="off"
          placeholder="Asıl Saat"
          (change)="actualHourF()"
          [(ngModel)]="taskDataObj.actualHour"
        /> -->
      </div>
      <div class="form-group col-md-6">
        <label for="bm-desc">Asıl Gün</label>
        <p>
          <strong>
            {{ taskDataObj?.actualDay }}
          </strong>
        </p>
        <!-- <input
          class="form-control"
          id="bm-desc"
          type="text"
          autocomplete="off"
          placeholder="Asıl Gün"
          [(ngModel)]="taskDataObj.actualDay"
        /> -->
      </div>
    </div>
    <div *ngIf="user.data.claimId > 4">
      <button class="btn btn-success mr-1" id="Bookmark" (click)="saveTask()">
        Kaydet
      </button>
      <button
        class="btn btn-danger"
        type="button"
        data-dismiss="modal"
        (click)="closeModal()"
      >
        İptal
      </button>
    </div>
  </div>
</ng-template>
