import { Routes } from "@angular/router";
import { JobComponent } from "src/app/components/job/job.component";
import { ProfileComponent } from "src/app/components/profile/profile.component";
import { ProjectDetailComponent } from "src/app/tables/projects-table/projects-table/project-detail/project-detail.component";
import { SprintComponent } from "src/app/tables/sprint/sprint.component";
import * as CryptoJS from "crypto-js";
import { CreateJobPageComponent } from "src/app/components/job/create-job-page/create-job-page.component";
import { EditJobComponent } from "src/app/components/job/edit-job/edit-job.component";

let allowedRoutes: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../components/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "deneme",
    loadChildren: () =>
      import("../../components/deneme/deneme.module").then(
        (m) => m.DenemeModule
      ),
  },
  {
    path: "report",
    loadChildren: () =>
      import("../../components/rapor/rapor.module").then((m) => m.RaporModule),
  },

  {
    path: "jobtypes",
    loadChildren: () =>
      import("../../tables/job-types/job-types/job-types.module").then(
        (m) => m.JobTypesModule
      ),
  },
  {
    path: "customer",
    loadChildren: () =>
      import("../../tables/customer/customer/customer.module").then(
        (m) => m.CustomerModule
      ),
  },
  {
    path: "jobpriorities",
    loadChildren: () =>
      import("../../tables/job-priority/job-priority/job-priority.module").then(
        (m) => m.JobPriorityModule
      ),
  },
  {
    path: "jobstatuses",
    loadChildren: () =>
      import("../../tables/job-statuses/job-statuses/job-statuses.module").then(
        (m) => m.JobStatusesModule
      ),
  },
  {
    path: "job-approval-types",
    loadChildren: () =>
      import("../../tables/job-approval-type/job-approval-type.module").then(
        (m) => m.JobApprovalTypeModule
      ),
  },
  {
    path: "projects",
    loadChildren: () =>
      import(
        "../../tables/projects-table/projects-table/projects-table.module"
      ).then((m) => m.ProjectsTableModule),
  },
  {
    path: "sprints-definition",
    component: SprintComponent,
  },
  {
    path: "sprints",
    loadChildren: () =>
      import("../../components/sprint-customer/sprint-customer.module").then(
        (m) => m.SprintCustomerModule
      ),
  },
  {
    path: "project-detail",
    component: ProjectDetailComponent,
  },
  {
    path: "profile",
    component: ProfileComponent,
  },
  {
    path: "project-users",
    loadChildren: () =>
      import(
        "../../tables/project-users/project-users/project-users.module"
      ).then((m) => m.ProjectUsersModule),
  },
  {
    path: "confirmations",
    loadChildren: () =>
      import("../../tables/confirmations/confirmations.module").then(
        (m) => m.ConfirmationsModule
      ),
  },
  {
    path: "jobs/job-detail",
    component: JobComponent,
  },
  {
    path: "jobs/create-job",
    component: CreateJobPageComponent,
  },
  {
    path: "jobs/edit-job",
    component: EditJobComponent,
  },
  {
    path: "jobs",
    loadChildren: () =>
      import("../../components/job/job/job.module").then((m) => m.JobModule),
  },
  {
    path: "logs",
    loadChildren: () =>
      import("../../tables/logs/logs/logs.module").then((m) => m.LogsModule),
  },
  {
    path: "users",
    loadChildren: () =>
      import("../../tables/users/users/users.module").then(
        (m) => m.UsersModule
      ),
  },
];

let user;
if (sessionStorage.getItem("user")) {
  var decrypted = CryptoJS.AES.decrypt(
    sessionStorage.getItem("user"),
    "2233**superkeyKANLIsuperkey2233*"
  ).toString(CryptoJS.enc.Utf8);

  user = JSON.parse(decrypted);

  if (user.data.claimId < 4) {
    allowedRoutes = allowedRoutes.filter((route) => route.path !== "jobtypes");
    allowedRoutes = allowedRoutes.filter((route) => route.path !== "customer");
    allowedRoutes = allowedRoutes.filter(
      (route) => route.path !== "jobpriorities"
    );
    allowedRoutes = allowedRoutes.filter(
      (route) => route.path !== "jobstatuses"
    );

    allowedRoutes = allowedRoutes.filter(
      (route) => route.path !== "project-users"
    );
    allowedRoutes = allowedRoutes.filter((route) => route.path !== "jobs");
    allowedRoutes = allowedRoutes.filter((route) => route.path !== "logs");
    allowedRoutes = allowedRoutes.filter((route) => route.path !== "users");
    allowedRoutes = allowedRoutes.filter(
      (route) => route.path !== "sprints-definition"
    );
    allowedRoutes = allowedRoutes.filter(
      (route) => route.path !== "create-job"
    );
  } else if (user.data.claimId === 4) {
    allowedRoutes = allowedRoutes.filter((route) => route.path !== "report");
    allowedRoutes = allowedRoutes.filter((route) => route.path !== "jobtypes");
    allowedRoutes = allowedRoutes.filter((route) => route.path !== "customer");
    allowedRoutes = allowedRoutes.filter(
      (route) => route.path !== "jobpriorities"
    );
    allowedRoutes = allowedRoutes.filter(
      (route) => route.path !== "jobstatuses"
    );
    allowedRoutes = allowedRoutes.filter(
      (route) => route.path !== "sprints-definition"
    );
    allowedRoutes = allowedRoutes.filter(
      (route) => route.path !== "project-users"
    );

    allowedRoutes = allowedRoutes.filter((route) => route.path !== "jobs");
    allowedRoutes = allowedRoutes.filter((route) => route.path !== "logs");
    allowedRoutes = allowedRoutes.filter((route) => route.path !== "users");
    allowedRoutes = allowedRoutes.filter(
      (route) => route.path !== "create-job"
    );
    allowedRoutes = allowedRoutes.filter((route) => route.path !== "edit-job");
  } else if (user.data.claimId === 5) {
    allowedRoutes = allowedRoutes.filter((route) => route.path !== "jobtypes");

    allowedRoutes = allowedRoutes.filter(
      (route) => route.path !== "jobpriorities"
    );
    allowedRoutes = allowedRoutes.filter(
      (route) => route.path !== "jobstatuses"
    );
  }
}

export const content: Routes = allowedRoutes;

// export const content: Routes = [
//   {
//     path: "dashboard",
//     loadChildren: () =>
//       import("../../components/dashboard/dashboard.module").then(
//         (m) => m.DashboardModule
//       ),
//   },
//   {
//     path: "report",
//     loadChildren: () =>
//       import("../../components/rapor/rapor.module").then((m) => m.RaporModule),
//   },

//   {
//     path: "jobtypes",
//     loadChildren: () =>
//       import("../../tables/job-types/job-types/job-types.module").then(
//         (m) => m.JobTypesModule
//       ),
//   },
//   {
//     path: "customer",
//     loadChildren: () =>
//       import("../../tables/customer/customer/customer.module").then(
//         (m) => m.CustomerModule
//       ),
//   },
//   {
//     path: "jobpriorities",
//     loadChildren: () =>
//       import("../../tables/job-priority/job-priority/job-priority.module").then(
//         (m) => m.JobPriorityModule
//       ),
//   },
//   {
//     path: "jobstatuses",
//     loadChildren: () =>
//       import("../../tables/job-statuses/job-statuses/job-statuses.module").then(
//         (m) => m.JobStatusesModule
//       ),
//   },
//   {
//     path: "projects",
//     loadChildren: () =>
//       import(
//         "../../tables/projects-table/projects-table/projects-table.module"
//       ).then((m) => m.ProjectsTableModule),
//   },
//   {
//     path: "sprints-definition",
//     component: SprintComponent,
//   },
//   {
//     path: "sprints",
//     loadChildren: () =>
//       import("../../components/sprint-customer/sprint-customer.module").then(
//         (m) => m.SprintCustomerModule
//       ),
//   },
//   {
//     path: "project-detail",
//     component: ProjectDetailComponent,
//   },
//   {
//     path: "profile",
//     component: ProfileComponent,
//   },
//   {
//     path: "project-users",
//     loadChildren: () =>
//       import(
//         "../../tables/project-users/project-users/project-users.module"
//       ).then((m) => m.ProjectUsersModule),
//   },
//   {
//     path: "confirmations",
//     loadChildren: () =>
//       import("../../tables/confirmations/confirmations.module").then(
//         (m) => m.ConfirmationsModule
//       ),
//   },
//   {
//     path: "jobs",
//     loadChildren: () =>
//       import("../../components/job/job/job.module").then((m) => m.JobModule),
//   },
//   {
//     path: "logs",
//     loadChildren: () =>
//       import("../../tables/logs/logs/logs.module").then((m) => m.LogsModule),
//   },
//   {
//     path: "users",
//     loadChildren: () =>
//       import("../../tables/users/users/users.module").then(
//         (m) => m.UsersModule
//       ),
//   },
//   // {
//   //   path: "day-confirmation",
//   //   loadChildren: () =>
//   //     import("../../components/day-confirmation/day-confirmation.module").then(
//   //       (m) => m.DayConfirmationModule
//   //     ),
//   // },

//   // {
//   //   path: "jobs-pending-approval",
//   //   loadChildren: () =>
//   //     import(
//   //       "../../components/jobs-pending-approval/jobs-pending-approval.module"
//   //     ).then((m) => m.JobsPendingApprovalModule),
//   // },

//   // {
//   //   path: "widgets",
//   //   loadChildren: () =>
//   //     import("../../components/widgets/widgets.module").then(
//   //       (m) => m.WidgetsModule
//   //     ),
//   // },
//   // {
//   //   path: "ui-kits",
//   //   loadChildren: () =>
//   //     import("../../components/ui-kits/ui-kits.module").then(
//   //       (m) => m.UiKitsModule
//   //     ),
//   // },
//   // {
//   //   path: "base",
//   //   loadChildren: () =>
//   //     import("../../components/bonus-ui/base/base.module").then(
//   //       (m) => m.BaseModule
//   //     ),
//   // },
//   // {
//   //   path: "advance",
//   //   loadChildren: () =>
//   //     import("../../components/bonus-ui/advance/advance.module").then(
//   //       (m) => m.AdvanceModule
//   //     ),
//   // },
//   // {
//   //   path: "buttons",
//   //   loadChildren: () =>
//   //     import("../../components/buttons/buttons.module").then(
//   //       (m) => m.ButtonsModule
//   //     ),
//   // },
//   // {
//   //   path: "to-do",
//   //   loadChildren: () =>
//   //     import("../../components/apps/todo/todo.module").then(
//   //       (m) => m.TodoModule
//   //     ),
//   // },
//   // {
//   //   path: "to-do-firebase",
//   //   loadChildren: () =>
//   //     import("../../components/apps/to-do-firebase/to-do-firebase.module").then(
//   //       (m) => m.ToDoFirebaseModule
//   //     ),
//   // },
//   // {
//   //   path: "editor",
//   //   loadChildren: () =>
//   //     import("../../components/editors/editor.module").then(
//   //       (m) => m.EditorModule
//   //     ),
//   // },
//   // {
//   //   path: "chart",
//   //   loadChildren: () =>
//   //     import("../../components/charts/charts.module").then(
//   //       (m) => m.ChartModule
//   //     ),
//   // },
//   // {
//   //   path: "icons",
//   //   loadChildren: () =>
//   //     import("../../components/icons/icons.module").then((m) => m.IconsModule),
//   // },
//   // {
//   //   path: "knowledgebase",
//   //   loadChildren: () =>
//   //     import("../../components/apps/knowledge-base/knowledge-base.module").then(
//   //       (m) => m.KnowledgeBaseModule
//   //     ),
//   // },
//   // {
//   //   path: "job",
//   //   loadChildren: () =>
//   //     import("../../components/apps/job-search/job-search.module").then(
//   //       (m) => m.JobSearchModule
//   //     ),
//   // },
//   // {
//   //   path: "blog",
//   //   loadChildren: () =>
//   //     import("../../components/apps/blog/blog.module").then(
//   //       (m) => m.BlogModule
//   //     ),
//   // },
//   // {
//   //   path: "learning",
//   //   loadChildren: () =>
//   //     import("../../components/apps/learning/learning.module").then(
//   //       (m) => m.LearningModule
//   //     ),
//   // },
//   // {
//   //   path: "faq",
//   //   loadChildren: () =>
//   //     import("../../components/apps/faq/faq.module").then((m) => m.FaqModule),
//   // },
//   // {
//   //   path: "gallery",
//   //   loadChildren: () =>
//   //     import("../../components/apps/gallery/gallery.module").then(
//   //       (m) => m.GalleryDemoModule
//   //     ),
//   // },
//   // {
//   //   path: "user",
//   //   loadChildren: () =>
//   //     import("../../components/apps/users/users.module").then(
//   //       (m) => m.UsersModule
//   //     ),
//   // },
//   // {
//   //   path: "social-app",
//   //   loadChildren: () =>
//   //     import("../../components/apps/social-app/social-app.module").then(
//   //       (m) => m.SocialAppModule
//   //     ),
//   // },
//   // {
//   //   path: "chat",
//   //   loadChildren: () =>
//   //     import("../../components/apps/chat/chat.module").then(
//   //       (m) => m.ChatModule
//   //     ),
//   // },
//   // {
//   //   path: "email",
//   //   loadChildren: () =>
//   //     import("../../components/apps/email/email.module").then(
//   //       (m) => m.EmailModule
//   //     ),
//   // },
//   // {
//   //   path: "calender",
//   //   loadChildren: () =>
//   //     import("../../components/apps/calender/calender.module").then(
//   //       (m) => m.CalenderModule
//   //     ),
//   // },
//   // {
//   //   path: "file-manager",
//   //   loadChildren: () =>
//   //     import("../../components/apps/file-manager/file-manager.module").then(
//   //       (m) => m.FileManagerModule
//   //     ),
//   // },
//   // {
//   //   path: "project",
//   //   loadChildren: () =>
//   //     import("../../components/apps/project/project.module").then(
//   //       (m) => m.ProjectModule
//   //     ),
//   // },
//   // {
//   //   path: "bookmarks",
//   //   loadChildren: () =>
//   //     import("../../components/apps/bookmarks/bookmarks.module").then(
//   //       (m) => m.BookmarksModule
//   //     ),
//   // },
//   // {
//   //   path: "contacts",
//   //   loadChildren: () =>
//   //     import("../../components/apps/contacts/contacts.module").then(
//   //       (m) => m.ContactsModule
//   //     ),
//   // },
//   // {
//   //   path: "tasks",
//   //   loadChildren: () =>
//   //     import("../../components/apps/tasks/tasks.module").then(
//   //       (m) => m.TasksModule
//   //     ),
//   // },
//   // {
//   //   path: "map",
//   //   loadChildren: () =>
//   //     import("../../components/apps/map/map.module").then((m) => m.MapModule),
//   // },
//   // {
//   //   path: "support-ticket",
//   //   loadChildren: () =>
//   //     import("../../components/apps/support-ticket/support-ticket.module").then(
//   //       (m) => m.SupportTicketModule
//   //     ),
//   // },
//   // {
//   //   path: "form",
//   //   loadChildren: () =>
//   //     import("../../components/forms/forms.module").then((m) => m.FormModule),
//   // },
//   // {
//   //   path: "table",
//   //   loadChildren: () =>
//   //     import("../../components/table/table.module").then((m) => m.TableModule),
//   // },
//   // {
//   //   path: "cards",
//   //   loadChildren: () =>
//   //     import("../../components/cards/cards.module").then((m) => m.CardsModule),
//   // },
//   // {
//   //   path: "search-pages",
//   //   loadChildren: () =>
//   //     import("../../components/others/search-result/search-result.module").then(
//   //       (m) => m.SearchResultModule
//   //     ),
//   // },
//   // {
//   //   path: "sample-page",
//   //   loadChildren: () =>
//   //     import("../../components/others/sample/sample.module").then(
//   //       (m) => m.SampleModule
//   //     ),
//   // },
//   // {
//   //   path: "ecommerce",
//   //   loadChildren: () =>
//   //     import("../../components/apps/e-commerce/e-commerce.module").then(
//   //       (m) => m.ECommerceModule
//   //     ),
//   // },
// ];
