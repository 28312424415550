import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "src/app/shared/services/customer.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { SprintsService } from "src/app/shared/services/sprints.service";

@Component({
  selector: "app-edit-sprint",
  templateUrl: "./edit-sprint.component.html",
  styleUrls: ["./edit-sprint.component.scss"],
})
export class EditSprintComponent implements OnInit {
  @ViewChild("editSprints", { static: false })
  editSprints: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  public editSprintForm: FormGroup;
  public sprintObj: any;
  public customers: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sprintService: SprintsService,
    private customerService: CustomerService,
    private snackBarService: SnackbarService
  ) {
    this.editSprintForm = this.fb.group({
      name: ["", [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      customer: ["", [Validators.required]],
    });
  }

  async ngOnInit() {
    await this.customerService.getAllCustomers().then((res) => {
      this.customers = res;
    });
  }

  openModal(id: number) {
    if (isPlatformBrowser(this.platformId)) {
      this.sprintService.getById(id).then((resp) => {
        this.sprintObj = resp;

        this.editSprintForm.get("name").setValue(this.sprintObj.name);
        this.editSprintForm
          .get("startDate")
          .setValue(
            new Date(this.sprintObj.startDate).toLocaleDateString("en-CA")
          );
        this.editSprintForm
          .get("endDate")
          .setValue(
            new Date(this.sprintObj.endDate).toLocaleDateString("en-CA")
          );
        this.editSprintForm.get("customer").setValue(this.sprintObj.customerId);
      });
      // For SSR
      this.modalService
        .open(this.editSprints, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.editSprintForm.reset();
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  saveSprint() {
    this.sprintService
      .updateSprints({
        id: this.sprintObj.id,
        name: this.editSprintForm.value.name,
        startDate: this.editSprintForm.value.startDate,
        endDate: this.editSprintForm.value.endDate,
        customerId: this.editSprintForm.value.customer,
      })
      .then((a) => {
        this.editSprintForm.reset();
        this.modalService.dismissAll();
        this.onSave.emit();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
