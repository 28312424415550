import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/shared/services/auth.service";
import { JobTasksService } from "src/app/shared/services/job-tasks.service";
import { JobEmitService } from "src/app/shared/services/jobEmit.service";
import { JobsService } from "src/app/shared/services/jobs.service";
import { UserService } from "src/app/shared/services/user.service";
import * as CryptoJS from "crypto-js";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-create-task",
  templateUrl: "./create-task.component.html",
  styleUrls: ["./create-task.component.scss"],
})
export class CreateTaskComponent implements OnInit {
  users: any;
  @Input() jobId: any = 0;
  @Output() onSave = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  public addTaskForm: FormGroup;
  public userId: number = 0;
  user: any;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private taskService: JobTasksService,
    private emitJobService: JobEmitService,
    private userService: UserService,
    private snackBarService: SnackbarService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    //this.user = this.authService.decyptedUser;
    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);

    await this.userService.getByCustomerId(1).then((res) => {
      this.users = res;
    });

    this.addTaskForm = this.fb.group({
      details: ["", [Validators.required]],
      assignedUser: ["", [Validators.required]],
      estimatedHour: [""],
      estimatedDay: [""],
    });
  }

  saveTask() {
    this.taskData = {
      ...this.taskData,
      jobId: Number(this.jobId),
      details: this.addTaskForm.value.details,
      estimatedHour: Number(this.addTaskForm.value.estimatedHour),
      estimatedDay: Number(this.addTaskForm.value.estimatedHour / 8),
      assignedUserId: this.addTaskForm.value.assignedUser,
      startDate: null,
    };

    this.taskService
      .addJobTasks({
        ...this.taskData,
      })
      .then((a) => {
        this.addTaskForm.reset();
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(true);
        this.emitJobService.setEmitServis(null);
        this.onSave.emit();
      })
      .catch((err) => {
        this.addTaskForm.reset();
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });
    this.modalService.dismissAll();
    this.emitJobService.setEmitServis(null);
  }

  taskData = {
    jobId: 0,
    assignedUserId: null,
    details: "",
    estimatedHour: 0,
    estimatedDay: 0,
    startDate: null,
    endDate: null,
    actualHour: 0,
    actualDay: 0,
  };

  open(content) {
    // this.setJobData();
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
