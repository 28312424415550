import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AngularMultiSelect } from "angular2-multiselect-dropdown";
import { JobStatusesService } from "src/app/shared/services/job-statuses.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-edit-job-status",
  templateUrl: "./edit-job-status.component.html",
  styleUrls: ["./edit-job-status.component.scss"],
})
export class EditJobStatusComponent implements OnInit {
  @ViewChild("editJobStatuses", { static: false })
  editJobStatuses: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();
  @Input() jobStatuses = [];

  public closeResult: string;
  public modalOpen: boolean = false;
  public editJobStatusForm: FormGroup;
  public jobStatusObj: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private jobStatusServ: JobStatusesService,
    private snackBarService: SnackbarService
  ) {
    this.editJobStatusForm = this.fb.group({
      id: [{ value: "", disabled: true }, [Validators.required]],
      name: ["", [Validators.required]],
      previousStatus: ["", [Validators.required]],
      nextStatus: ["", [Validators.required]],
      description: [""],
    });
  }

  ngOnInit(): void {}

  openModal(id: number) {
    if (isPlatformBrowser(this.platformId)) {
      this.jobStatusServ.getById(id).then((resp) => {
        this.jobStatusObj = resp;
        this.editJobStatusForm.get("id").setValue(this.jobStatusObj.id);
        this.editJobStatusForm.get("name").setValue(this.jobStatusObj.name);
        this.editJobStatusForm
          .get("previousStatus")
          .setValue(this.jobStatusObj.previousStatus);
        this.editJobStatusForm
          .get("nextStatus")
          .setValue(this.jobStatusObj.nextStatus);
        this.editJobStatusForm
          .get("description")
          .setValue(this.jobStatusObj.description);
      });
      // For SSR
      this.modalService
        .open(this.editJobStatuses, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.editJobStatusForm.reset();
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  saveJobStatus() {
    this.jobStatusServ
      .updateJobStatuses({
        id: this.jobStatusObj.id,
        name: this.editJobStatusForm.value.name,
        previousStatus: this.editJobStatusForm.value.previousStatus,
        nextStatus: this.editJobStatusForm.value.nextStatus,
        description: this.editJobStatusForm.value.description,
      })
      .then((a) => {
        this.editJobStatusForm.reset();
        this.modalService.dismissAll();
        this.onSave.emit();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
