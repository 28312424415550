import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "src/app/shared/services/customer.service";
import { ProjectsService } from "src/app/shared/services/projects.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { SprintsService } from "src/app/shared/services/sprints.service";

@Component({
  selector: "app-add-sprint",
  templateUrl: "./add-sprint.component.html",
  styleUrls: ["./add-sprint.component.scss"],
})
export class AddSprintComponent implements OnInit {
  @ViewChild("addSprints", { static: false }) addCustomers: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  public addSprintForm: FormGroup;
  public projects: any;
  public customers: any;
  public addSprint: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sprintService: SprintsService,
    private projectService: ProjectsService,
    private customerService: CustomerService,
    private snackBarService: SnackbarService
  ) {
    this.addSprintForm = this.fb.group({
      name: ["", [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      customer: ["", [Validators.required]],
    });
  }

  async ngOnInit() {
    // this.projectService.getAllProjects().then((res) => {
    //   this.projects = res;
    // });
    await this.customerService.getAllCustomers().then((res) => {
      this.customers = res;
    });
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.addCustomers, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.addSprintForm.reset();
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  async saveSprint() {
    this.addSprint = {
      name: this.addSprintForm.value.name,
      startDate: new Date(
        this.addSprintForm.value.startDate
      ).toLocaleDateString("en-CA"),
      endDate: new Date(this.addSprintForm.value.endDate).toLocaleDateString(
        "en-CA"
      ),
      customerId: this.addSprintForm.value.customer.id,
    };
    console.log(this.addSprint);
    await this.sprintService
      .addSprints(this.addSprint)
      .then((a) => {
        this.addSprintForm.reset();
        this.modalService.dismissAll();
        this.onSave.emit();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.addSprintForm.reset();
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
