<div class="card-body">
  <!-- Tasklar listesi -->
  <div class="mt-4">
    <div class="card">
      <div class="card-header m-b-15">
        <h6>Görevleri Görüntüle</h6>
        <div>
          <app-create-task
            [jobId]="jobID"
            (onSave)="getJobTasks()"
            *ngIf="user.data.claimId > 4"
          ></app-create-task>
        </div>
      </div>
      <div class="card-body">
        <div *ngIf="tasks.length > 0">
          <div class="mat-elevation-z8">
            <p-table
              [value]="tasks"
              [columns]="taskColumns"
              [tableStyle]="{ 'min-width': '50rem' }"
              responsiveLayout="scroll"
              [rows]="10"
              [showCurrentPageReport]="true"
              [paginator]="true"
              [rowsPerPageOptions]="[10, 25, 50]"
              currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} gösteriliyor"
              #tt
              [globalFilterFields]="dynamicGlobalFilterFieldsJobs"
              [scrollable]="true"
              scrollHeight="600px"
            >
              <ng-template pTemplate="caption">
                <div class="flex justify-content-end align-items-center">
                  <div class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input
                      type="text"
                      pInputText
                      placeholder="Ara"
                      (input)="tt.filterGlobal($event.target.value, 'contains')"
                    />
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.prop">
                    {{ col.name }}
                    <p-sortIcon [field]="col.prop"></p-sortIcon>
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td *ngFor="let col of taskColumns">
                    {{ rowData[col.prop] }}
                  </td>
                  <td>
                    <div class="example-button-container d-flex">
                      <app-task-detail
                        [jobId]="jobID"
                        [taskId]="rowData.id"
                        (onSave)="getJobTasks()"
                      ></app-task-detail>
                      <button
                        (click)="startTask(rowData.id)"
                        mat-mini-fab
                        color="accent"
                        class="mr-1"
                        matTooltip="Başla"
                        *ngIf="
                          user.data.fullName === rowData.assignedUserName &&
                          rowData.startDate === null
                        "
                      >
                        <mat-icon>play_arrow</mat-icon>
                      </button>
                      <button
                        (click)="finishTask(rowData.id)"
                        mat-mini-fab
                        color="accent"
                        class="mr-1"
                        matTooltip="Bitir"
                        *ngIf="
                          user.data.fullName === rowData.assignedUserName &&
                          rowData.startDate !== null &&
                          rowData.endDate === null
                        "
                      >
                        <mat-icon>stop</mat-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="100%">Gösterilecek veri bulunmamaktadır</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
