import { Component, OnInit } from "@angular/core";
import { JobsService } from "src/app/shared/services/jobs.service";
import { JobPrioritiesService } from "src/app/shared/services/job-priorities.service";
import { JobTypesService } from "src/app/shared/services/job-types.service";
import { JobStatusesService } from "src/app/shared/services/job-statuses.service";
import { UserService } from "src/app/shared/services/user.service";
import { ProjectsService } from "src/app/shared/services/projects.service";
import * as CryptoJS from "crypto-js";
import { ActivatedRoute, Router } from "@angular/router";
import { SprintsService } from "src/app/shared/services/sprints.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-edit-job",
  templateUrl: "./edit-job.component.html",
  styleUrls: ["./edit-job.component.scss"],
})
export class EditJobComponent implements OnInit {
  public jobObj?: any = null;
  public jobDto?: any = null;

  public priorities: any;
  public jobTypes: any;
  public jobStatuses: any;
  private sprints: any;
  public users: any;
  public projects: any;
  public jobID: any = 0;
  public projectId: any = 0;
  public projectObj: any;
  public user: any;

  public jobList: any;

  public nullValue = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,

    private prioriServ: JobPrioritiesService,
    private jobTypeService: JobTypesService,
    private jobStatusService: JobStatusesService,
    private userService: UserService,
    private projectService: ProjectsService,
    private jobService: JobsService,
    private sprintService: SprintsService,
    private snackBarService: SnackbarService
  ) {
    this.jobDto = null;
    this.jobObj = null;
  }

  async ngOnInit() {
    // child iş olup olmadığını anlamak için
    var routeParam = false;
    this.route.queryParams.subscribe((params) => {
      routeParam = params["child"]; // Belirli bir sorgu parametresini alır
    });

    if (routeParam) {
      this.jobID = localStorage.getItem("childJobId");
    } else {
      this.jobID = localStorage.getItem("jobId");
    }

    //this.user = this.authService.decyptedUser;

    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);

    await this.jobService.getByDTOId(this.jobID).then((res) => {
      this.jobDto = res;
    });

    await this.jobService.getById(this.jobID).then((res) => {
      this.jobObj = res;
      console.log(this.jobObj);
      this.projectId = this.jobObj.projectId;

      if (
        this.jobObj.startDate !== null &&
        this.jobObj.startDate !== undefined &&
        this.jobObj.startDate !== ""
      ) {
        this.jobObj.startDate = new Date(
          this.jobObj.startDate
        ).toLocaleDateString("en-CA");
      }
      if (
        this.jobObj.endDate !== null &&
        this.jobObj.endDate !== undefined &&
        this.jobObj.endDate !== ""
      ) {
        this.jobObj.endDate = new Date(this.jobObj.endDate).toLocaleDateString(
          "en-CA"
        );
      }
      if (
        this.jobObj.testDate !== null &&
        this.jobObj.testDate !== undefined &&
        this.jobObj.testDate !== ""
      ) {
        this.jobObj.testDate = new Date(
          this.jobObj.testDate
        ).toLocaleDateString("en-CA");
      }
      if (
        this.jobObj.prodDate !== null &&
        this.jobObj.prodDate !== undefined &&
        this.jobObj.prodDate !== ""
      ) {
        this.jobObj.prodDate = new Date(
          this.jobObj.prodDate
        ).toLocaleDateString("en-CA");
      }

      this.projectService.getById(this.projectId).then((res) => {
        this.projectObj = res;
      });

      this.jobService.getJobsByDTOProjectID(this.projectId).then((res) => {
        this.jobList = res;
        this.jobList = [
          { id: null, code: "", name: "Üst İş Bulunmamakta -" },
          ...this.jobList,
        ];
      });

      // this.jobObj = {
      //   ...this.jobObj,
      //   startDate: new Date(this.jobObj.startDate).toLocaleDateString("en-CA"),
      //   endDate: new Date(this.jobObj.endDate).toLocaleDateString("en-CA"),
      //   testDate: new Date(this.jobObj.testDate).toLocaleDateString("en-CA"),
      //   prodDate: new Date(this.jobObj.prodDate).toLocaleDateString("en-CA"),
      // };
    });

    await this.prioriServ.getAllJobPriorities().then((res) => {
      this.priorities = res;
    });
    await this.jobTypeService.getAllJobTypes().then((res) => {
      this.jobTypes = res;
    });
    await this.jobStatusService.getAllJobStatuses().then((res) => {
      this.jobStatuses = res;
    });
    await this.sprintService
      .getByDTOCustomerId(this.projectObj.customerId, false)
      .then((res) => {
        this.sprints = res;
        // console.log("sprints: ", this.sprints);
      });
    await this.userService
      .getByDTOCustomerId(this.projectObj.customerId)
      .then((res) => {
        this.users = res;
        if (this.projectObj.customerId != 1) {
          this.userService.getByDTOCustomerId(1).then((resp) => {
            this.users = this.users.concat(resp);
          });
        }
      });
    if (this.user.data.claimId > 4) {
      await this.projectService.getAllProjectsDTO().then((res) => {
        this.projects = res;
        this.projects = res.filter(function (item): boolean {
          return item.isActive == true;
        });
      });
    } else {
      await this.projectService
        .getProjectByDTOUserId(this.user.data.id)
        .then((res) => {
          this.projects = res;
          this.projects = res.filter(function (item): boolean {
            return item.isActive == true;
          });
        });
    }
  }

  onSubmit() {
    if (this.jobObj.billedDay >= 0 && this.jobObj.billedDay !== null) {
      this.jobObj.isBilled = true;
    } else if (this.jobObj.billedDay == null) {
      this.jobObj.isBilled = false;
    }

    this.jobService
      .updateJobs(this.jobObj)
      .then((res) => {
        this.snackBarService.openSnackBar(true);
        localStorage.setItem("jobId", this.jobID);
        this.router.navigateByUrl("/jobs/job-detail");
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }
}
