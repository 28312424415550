import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ClaimService } from "src/app/shared/services/claim.service";
import { CustomerService } from "src/app/shared/services/customer.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"],
})
export class EditUserComponent implements OnInit {
  @ViewChild("editUsers", { static: false }) editUsers: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  public editUserForm: FormGroup;
  public userObj: any;
  public customers: any;
  public claims: any;
  public isVisibleChangePassword: boolean = true;
  public newPassword: string = "";

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private userServ: UserService,
    private customerService: CustomerService,
    private claimService: ClaimService,
    private snackBarService: SnackbarService
  ) {
    this.editUserForm = this.fb.group({
      id: [{ value: "", disabled: true }, [Validators.required]],
      userName: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      fullName: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      customerId: ["", [Validators.required]],
      claimId: ["", [Validators.required]],
      isActive: [false, [Validators.required]],
      isLocked: [false, [Validators.required]],
      newPassword: [""],
    });
  }

  async ngOnInit() {
    await this.customerService.getAllCustomers().then((res) => {
      this.customers = res;
    });
    await this.claimService.getAllClaims().then((res) => {
      this.claims = res;
    });
  }

  openModal(id: number) {
    if (isPlatformBrowser(this.platformId)) {
      this.userServ.getById(id).then((resp) => {
        this.userObj = resp;
        this.editUserForm.get("id").setValue(this.userObj.id);
        this.editUserForm.get("userName").setValue(this.userObj.userName);
        this.editUserForm.get("email").setValue(this.userObj.email);
        this.editUserForm.get("fullName").setValue(this.userObj.fullName);
        this.editUserForm.get("phone").setValue(this.userObj.phone);
        this.editUserForm.get("customerId").setValue(this.userObj.customerId);
        this.editUserForm.get("claimId").setValue(this.userObj.claimId);
        this.editUserForm.get("isActive").setValue(this.userObj.isActive);
        this.editUserForm.get("isLocked").setValue(this.userObj.isLocked);
      });
      // For SSR
      this.modalService
        .open(this.editUsers, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.editUserForm.reset();
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  saveUser() {
    this.userServ
      .updateUsers({
        id: this.userObj.id,
        userName: this.editUserForm.value.userName,
        email: this.editUserForm.value.email,
        fullName: this.editUserForm.value.fullName,
        phone: this.editUserForm.value.phone,
        customerId: this.editUserForm.value.customerId,
        claimId: this.editUserForm.value.claimId,
        isActive: this.editUserForm.value.isActive,
        isLocked: this.editUserForm.value.isLocked,
      })
      .then((a) => {
        this.modalService.dismissAll();
        this.onSave.emit();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });
  }

  changePassword() {
    this.isVisibleChangePassword = false;
  }

  async updatePassword() {
    console.log(this.newPassword);

    await this.userServ
      .updateUserPassword(this.userObj.id, this.editUserForm.value.newPassword)
      .then((res) => {
        if (res.success === true) {
          this.snackBarService.openSnackBar(true);
          this.editUserForm.value.newPassword = "";
          this.isVisibleChangePassword = true;
        }
      });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
