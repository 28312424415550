import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProjectUsersService } from "src/app/shared/services/project-users.service";
import { ProjectsService } from "src/app/shared/services/projects.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-edit-project-user",
  templateUrl: "./edit-project-user.component.html",
  styleUrls: ["./edit-project-user.component.scss"],
})
export class EditProjectUserComponent implements OnInit {
  @ViewChild("editProjectUsers", { static: false })
  editProjectUsers: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();
  public users: any;
  @Input() projects: any;

  public closeResult: string;
  public modalOpen: boolean = false;
  public editProjectUserForm: FormGroup;
  public projectUserObj: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private projectUserServ: ProjectUsersService,
    private projectService: ProjectsService,
    private userService: UserService,
    private snackBarService: SnackbarService
  ) {
    this.editProjectUserForm = this.fb.group({
      id: [{ value: "", disabled: true }, [Validators.required]],
      projectId: ["", [Validators.required]],
      userId: ["", [Validators.required]],
    });
  }

  async ngOnInit() {}

  async usersOfCustomer(id) {
    await this.projectService.getById(id).then((res) => {
      const project = res;

      this.userService.getByCustomerId(project.customerId).then((res) => {
        this.users = res;
      });
    });
  }

  openModal(id: number) {
    if (isPlatformBrowser(this.platformId)) {
      this.projectUserServ.getById(id).then((resp) => {
        this.projectUserObj = resp;
        this.userService
          .getByCustomerId(this.projectUserObj.customerId)
          .then((res) => {
            this.users = res;
          });
        this.userService.getByCustomerId(1).then((res) => {
          this.users = [...this.users, ...res];
        });

        this.editProjectUserForm
          .get("projectId")
          .setValue(this.projectUserObj.projectId);
        this.editProjectUserForm
          .get("userId")
          .setValue(this.projectUserObj.userId);
      });
      // For SSR
      this.modalService
        .open(this.editProjectUsers, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.editProjectUserForm.reset();
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  saveProjectUser() {
    this.projectUserServ
      .updateProjectUsers({
        id: this.projectUserObj.id,
        projectId: this.editProjectUserForm.value.projectId,
        userId: Number(this.editProjectUserForm.value.userId),
        project: this.projectUserObj.project,
      })
      .then((a) => {
        this.editProjectUserForm.reset();
        this.modalService.dismissAll();
        this.onSave.emit();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
