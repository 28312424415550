import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { JobListComponent } from "../job-list/job-list.component";
import { ProjectsTableComponent } from "src/app/tables/projects-table/projects-table.component";
import { JobComponent } from "../job.component";
import { KanbanBoardComponent } from "../../kanban-board/kanban-board.component";
import { CreateJobPageComponent } from "../create-job-page/create-job-page.component";
import { EditJobComponent } from "../edit-job/edit-job.component";

const routes: Routes = [
  { path: "", component: KanbanBoardComponent },
  // { path: "job-detail", component: JobComponent },
  // { path: "create-job", component: CreateJobPageComponent },
  // { path: "edit-job", component: EditJobComponent },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JobRoutingModule {}
