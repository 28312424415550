import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";

import { Router } from "@angular/router";

import { AddProjectComponent } from "./add-project/add-project.component";
import { DeleteProjectComponent } from "./delete-project/delete-project.component";
import { EditProjectComponent } from "./edit-project/edit-project.component";
import { ProjectsService } from "../../shared/services/projects.service";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { CustomerService } from "src/app/shared/services/customer.service";
import { UserService } from "src/app/shared/services/user.service";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProjectEmitService } from "src/app/shared/services/projectEmit.service";
import { AuthService } from "src/app/shared/services/auth.service";
import * as CryptoJS from "crypto-js";

import { LiveAnnouncer } from "@angular/cdk/a11y";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-projects-table",
  templateUrl: "./projects-table.component.html",
  styleUrls: ["./projects-table.component.scss"],
})
export class ProjectsTableComponent implements OnInit {
  @ViewChild("addProjects") AddProjects: AddProjectComponent;
  @ViewChild("editProjects") EditProject: EditProjectComponent;
  @ViewChild("deleteProjects") DeleteProjects: DeleteProjectComponent;
  @ViewChild("projectDatatable") datatable: DatatableComponent;

  public outputID: number;
  public customers = null;
  public users = null;
  public projects: any = [{}];
  public filteredProjects: any;
  public user: any;
  public projectState: number = 1;

  // itUserName analysisiApprover olacak

  //{ prop: "itUserName", name: "Analiz Onaylayan", colspan: 1 },
  public dynamicGlobalFilterFields: any;
  public columns = [
    { prop: "code", name: "Proje Kodu", colspan: 1 },
    { prop: "name", name: "Proje Adı", colspan: 2 },
    { prop: "customerName", name: "Kurum", colspan: 1 },
    { prop: "managerUserName", name: "İş Onaylayan", colspan: 1 },
    { prop: "analysisApproverUserName", name: "Analiz Onaylayan", colspan: 1 },
    { prop: "dayApproverUserName", name: "Gün Onaylayan", colspan: 1 },
  ];

  displayedColumns: string[] = [
    "code",
    "name",
    "customerName",
    "managerUserName",
    "analysisApproverUserName",
    "dayApproverUserName",

    "button",
  ];

  closeResult;

  constructor(
    private projectServ: ProjectsService,
    private custServ: CustomerService,
    private userServ: UserService,
    private router: Router,
    private modalService: NgbModal,
    private emitProjectService: ProjectEmitService,
    private authService: AuthService,
    private _liveAnnouncer: LiveAnnouncer,
    private snackBarService: SnackbarService
  ) {}

  async ngOnInit() {
    //this.user = this.authService.decyptedUser;
    //console.log(this.user);
    this.dynamicGlobalFilterFields = this.columns.map((col) => col.prop);

    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);
    localStorage.removeItem("projectDetailFilters");
    localStorage.removeItem("sprintDetailFilters");
    localStorage.removeItem("selectedSprint");
    localStorage.removeItem("selectedCustomer");
    localStorage.removeItem("selectedIsActive");
    localStorage.removeItem("jobsFilters");

    this.user = JSON.parse(decrypted);

    this.getEmit();
    this.test();
  }

  async test() {
    if (this.user.data.claimId < 5) {
      this.projectServ.getProjectByDTOUserId(this.user.data.id).then((resp) => {
        this.projects = resp;
        this.filteredProjects = resp;
        this.getProjectList(this.projectState);

        this.userServ.getAllUsersDTO().then((resp) => {
          this.users = resp;
        });
      });
    } else {
      this.projectServ.getAllProjectsDTO().then((resp) => {
        this.projects = resp;
        this.filteredProjects = resp;
        this.getProjectList(this.projectState);

        this.userServ.getAllUsersDTO().then((resp) => {
          this.users = resp;
        });
      });
    }
  }

  selectKurum = "-1";

  async getProjectList(number: number) {
    this.projectState = number;

    //console.log(this.projects);

    if (this.selectKurum != "-1") {
      if (this.projectState == 0) {
        this.filteredProjects = this.projects = this.projects.filter(
          (element) => element.customerName == this.selectKurum
        );
      } else if (this.projectState == 1) {
        this.filteredProjects = this.projects.filter(
          (element) =>
            element.isActive == true && element.customerName == this.selectKurum
        );
      } else if (this.projectState == -1) {
        this.filteredProjects = this.projects.filter(
          (element) =>
            element.isActive == false &&
            element.customerName == this.selectKurum
        );
      }
    } else {
      if (this.projectState == 0) {
        this.filteredProjects = this.projects;
      } else if (this.projectState == 1) {
        this.filteredProjects = this.projects.filter(
          (element) => element.isActive == true
        );
      } else if (this.projectState == -1) {
        this.filteredProjects = this.projects.filter(
          (element) => element.isActive == false
        );
      }
    }
  }

  getEmit() {
    this.emitProjectService.getEmit.subscribe((data) => {
      this.updateTable();
    });
    this.custServ.getAllCustomers().then((resp) => {
      this.customers = resp;
    });
  }
  async updateTable() {
    //console.log(this.user);
    this.test();
  }

  async onChangeCustomer(event) {
    this.selectKurum = event;
    if (this.selectKurum != "-1") {
      this.test();
    }
    this.filteredProjects = this.projects.filter(
      (element) => element.customerName == this.selectKurum
    );

    event == "-1" ? this.test() : "";
  }

  onClick(id) {
    localStorage.setItem("projectId", id.toString());
    this.router.navigateByUrl("projects/project-detail");
  }

  deleteProject(id) {
    this.projectServ
      .deleteProjects(id)
      .then((resp) => {
        this.updateTable();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });

    //console.log("tetiklendi", id);
  }

  deleteProjectModal(content, id) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
        centered: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.deleteProject(id);
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
