import { Component, Input, OnInit } from "@angular/core";
import { JobLogsService } from "src/app/shared/services/job-logs.service";

@Component({
  selector: "app-job-logs",
  templateUrl: "./job-logs.component.html",
  styleUrls: ["./job-logs.component.css"],
})
export class JobLogsComponent implements OnInit {
  public logsColumn = [
    { prop: "userName", name: "Kullanıcı", colspan: 1 },
    { prop: "date", name: "Tarih", colspan: 1 },
    { prop: "detail", name: "Detay", colspan: 1 },
  ];

  public jobLogs: any;
  @Input() jobID: number;

  constructor(private jobLogService: JobLogsService) {}

  async ngOnInit() {
    await this.jobLogService.getListByDTOJobID(this.jobID).then((res) => {
      this.jobLogs = res;
      //console.log("jobLogsDto: ", this.jobLogsDto);
    });
  }
}
