import { Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";

@Injectable({
  providedIn: "root",
})
export class ProjectsService {
  public ProjectsList = [];
  constructor(private http: HttpClientService) {}

  async getAllProjects() {
    return await this.http.httpGet("/Projects/getall").then((res) => {
      return res.data;
    });
  }

  async getAllProjectsDTO() {
    return await this.http.httpGet("/Projects/getdtoall").then((res) => {
      return res.data;
    });
  }

  async getById(projectID: Number) {
    return await this.http
      .httpGet("/Projects/getbyid?projectID=" + projectID)
      .then((res) => {
        return res.data;
      });
  }

  async getByDTOId(projectID: Number) {
    return await this.http
      .httpGet("/Projects/getbydtoid?projectID=" + projectID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByCustomerId(customerID: Number) {
    return await this.http
      .httpGet("/Projects/getlistbycustomerid?customerID=" + customerID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByDTOCustomerId(customerID: Number, isActive: boolean) {
    return await this.http
      .httpGet(
        "/Projects/getlistbydtocustomerid?customerID=" +
          customerID +
          "&isActive=" +
          isActive
      )
      .then((res) => {
        return res.data;
      });
  }
  async getProjectByUserId(userId: Number) {
    return await this.http
      .httpGet("/Projects/Getprojectbyuserid?userID=" + userId)
      .then((res) => {
        return res.data;
      });
  }
  async getProjectByDTOUserId(userId: Number) {
    return await this.http
      .httpGet("/Projects/Getprojectbydtouserid?userID=" + userId)
      .then((res) => {
        return res.data;
      });
  }

  async getProjectStatus(customerId: number) {
    return await this.http
      .httpGet("/Projects/getprojectstatus?customerID=" + customerId)
      .then((res) => {
        return res.data;
      });
  }

  async addProjects(data) {
    return await this.http.httpPost("/Projects/add", data);
  }
  async deleteProjects(data) {
    return await this.http.httpDelete("/Projects/delete", data);
  }
  async updateProjects(data) {
    return await this.http.httpPut("/Projects/update", data);
  }
}
