import { Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class JobLogsService {
  constructor(private http: HttpClientService, private datePipe: DatePipe) {}

  async getById(jobLogID: Number) {
    return await this.http
      .httpGet("/JobLogs/getbyid?jobLogID=" + jobLogID)
      .then((res) => {
        return res.data;
      });
  }

  async getByDTOId(jobLogID: Number) {
    return await this.http
      .httpGet("/JobLogs/getbydtoid?jobLogID=" + jobLogID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobLogs/getlistbyjobid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByDTOJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobLogs/getlistbydtojobid?jobID=" + jobID)
      .then((res) => {
        res = res.data.map((job) => {
          return {
            ...job,
            date: this.datePipe.transform(job.date, "dd.MM.yyyy HH:mm"),
          };
        });

        return res;
      });
  }

  async addJobLogs(data) {
    return await this.http.httpPost("/JobLogs/add", data);
  }
  async deleteJobLogs(data) {
    return await this.http.httpDelete("/JobLogs/delete", data);
  }
  async updateJobLogs(data) {
    return await this.http.httpPut("/JobLogs/update", data);
  }
}
