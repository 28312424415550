import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { JobsService } from "src/app/shared/services/jobs.service";
import * as CryptoJS from "crypto-js";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-child-jobs-table",
  templateUrl: "./child-jobs-table.component.html",
  styleUrls: ["./child-jobs-table.component.scss"],
})
export class ChildJobsTableComponent implements OnInit {
  @Input() jobID: number;
  public childJobList: any;

  public columnsChildJobs: any = [
    { prop: "code", name: "İş Kodu", colspan: 2 },
    { prop: "name", name: "İş Adı", colspan: 2 },
    // { prop: "projectName", name: "Proje Adı", colspan: 2 },
    { prop: "sprintName", name: "Sprint", colspan: 1 },
    { prop: "jobTypeName", name: "İş Tipi", colspan: 1 },
    { prop: "jobStatusName", name: "İş Durumu", colspan: 1 },
    { prop: "assignedUserName", name: "Atanan Kullanıcı", colspan: 1 },
  ];
  public dynamicGlobalFilterFields: any;

  constructor(
    private jobServ: JobsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.dynamicGlobalFilterFields = this.columnsChildJobs.map(
      (col) => col.prop
    );

    this.jobServ.getJobsByDTOParentJobId(this.jobID).then((response: any) => {
      this.childJobList = response; // Veriyi diziye dönüştürüyoruz
      console.log(response);
    });
  }

  onClickChildJob(id: number) {
    console.log(id);
    localStorage.setItem("childJobId", id.toString());

    this.router
      .navigateByUrl("/jobs/job-detail?child=true")
      .then(() => window.location.reload());
  }
}
