import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { JobsService } from "src/app/shared/services/jobs.service";
import { JobTasksService } from "src/app/shared/services/job-tasks.service";
import { FormBuilder, Validators } from "@angular/forms";
import { JobEmitService } from "src/app/shared/services/jobEmit.service";
import { Job } from "src/app/shared/model/job.model";
import { JobPrioritiesService } from "src/app/shared/services/job-priorities.service";
import { JobTypesService } from "src/app/shared/services/job-types.service";
import { JobStatusesService } from "src/app/shared/services/job-statuses.service";
import { UserService } from "src/app/shared/services/user.service";
import { ProjectsService } from "src/app/shared/services/projects.service";
import { AuthService } from "src/app/shared/services/auth.service";
import * as CryptoJS from "crypto-js";
import { SprintsService } from "src/app/shared/services/sprints.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-create-job-page",
  templateUrl: "./create-job-page.component.html",
  styleUrls: ["./create-job-page.component.scss"],
})
export class CreateJobPageComponent implements OnInit {
  public jobObj: any;
  public addJobForm: FormGroup;
  public priorities: any;
  public jobTypes: any;
  public jobStatuses: any;
  public sprints: any;
  public users: any;
  public projects: any;
  public jobData: Job;
  public user: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private prioriServ: JobPrioritiesService,
    private jobTypeService: JobTypesService,
    private jobStatusService: JobStatusesService,
    private userService: UserService,
    private projectService: ProjectsService,
    private jobService: JobsService,
    private sprintService: SprintsService,
    private emitJobService: JobEmitService,
    private snackBarService: SnackbarService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    //this.user = this.authService.decyptedUser;

    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);

    await this.prioriServ.getAllJobPriorities().then((res) => {
      this.priorities = res;
    });
    await this.jobTypeService.getAllJobTypes().then((res) => {
      this.jobTypes = res;
    });
    await this.jobStatusService.getAllJobStatuses().then((res) => {
      this.jobStatuses = res;
    });
    await this.userService.getAllUsersDTO().then((res) => {
      this.users = res;
    });
    await this.projectService.getAllProjectsDTO().then((res) => {
      this.projects = res;
      this.projects = res.filter(function (item): boolean {
        return item.isActive == true;
      });
    });
    await this.sprintService.getDTOList().then((res) => {
      this.sprints = res;
    });

    this.addJobForm = this.fb.group({
      name: this.fb.control("", [Validators.required]),
      details: this.fb.control("", [Validators.required]),
      projectId: this.fb.control("", [Validators.required]),
      jobTypeId: this.fb.control(""),
      topic: this.fb.control(""),
      //jobStatusId: this.fb.control("", [Validators.required]),
      jobPriorityId: this.fb.control("", [Validators.required]),
      assignedUserId: this.fb.control(""),
      sprint: this.fb.control(""),
      estimatedDay: this.fb.control(0),
      actualDay: this.fb.control(0),
      billedDay: this.fb.control(0),
      startDate: this.fb.control(null),
      endDate: this.fb.control(null),
      testDate: this.fb.control(null),
      prodDate: this.fb.control(null),
    });
  }

  async onSubmit() {
    this.jobObj = this.addJobForm.value;

    await this.projectService.getById(this.jobObj.projectId).then((res) => {
      //console.log(res);
      this.jobObj = {
        ...this.jobObj,
        projectId: parseInt(this.jobObj.projectId),
        jobPriorityId: parseInt(this.jobObj.jobPriorityId),
        createDate: new Date(),
        createUserId: this.user.data.id,
        code: "",
        topic: null,
        analysis: null,
        sprint: parseInt(this.jobObj.sprint),
        isBilled: false,
        managerUserId: null,
        managerApproveDate: null,
        dayApproverUserId: null,
        dayApproveDate: null,
        parentJobId: null,
        analysisApproverUserId: null,
        analysisApproveDate: null,
        testDetail: null,
        isActive: true,
        jobTypeId: parseInt(this.jobObj.jobTypeId),
        jobStatusId: 1,
        assignedUserId: parseInt(this.jobObj.assignedUserId),
      };
    });

    //console.log(this.jobObj);

    await this.jobService
      .addJobs(this.jobObj)
      .then((res) => {
        this.snackBarService.openSnackBar(true);
        localStorage.setItem("jobId", res.data.id);
        this.router.navigateByUrl("/jobs/job-detail");
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }
}
