<ng-template #areyousure let-modal>
  <div class="modal-header">
      <h4 class="modal-title" id="exampleModalLabel">Silmek istediğinizden emin misiniz?</h4>
      <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""
          (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
      <div>
          <h5>Bu iş onay/red tanımı silmek istediğinizden emin misiniz?</h5>
      </div>
      <div style="margin-top:20px;justify-content: flex-end;display: flex;">
          <button style="margin-right:10px;" class="btn btn-success" (click)="deleteJobApprovalType()">
              Evet
          </button>
          <button class="btn btn-danger" type="button" data-dismiss="modal" (click)="closeModal()">
              Hayır
          </button>
      </div>
  </div>
</ng-template>