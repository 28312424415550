import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { JobQAsService } from "src/app/shared/services/job-qas.service";
import { JobsService } from "src/app/shared/services/jobs.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-analysis-and-qa",
  templateUrl: "./analysis-and-qa.component.html",
  styleUrls: ["./analysis-and-qa.component.css"],
})
export class AnalysisAndQaComponent implements OnInit {
  @Input() user: any;
  @Input() jobID: number;
  @Output() getEmit: EventEmitter<void> = new EventEmitter<void>();
  @Input() jobDto: any;
  @Input() jobObj: any; //This is for the update

  public jobQAs: any;
  public jobQAsObj: any;

  public safeAnalysis: any = "";

  public addJobQForm: any;
  public addJobAForm: any;

  public answeredQuestionId: number;

  public isAnswerInputOpen: boolean = false;

  constructor(
    private jobServ: JobsService,
    private jobQAService: JobQAsService,
    private snackBarService: SnackbarService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder
  ) {
    this.addJobQForm = this.fb.group({
      question: ["", [Validators.required]],
    });
    this.addJobAForm = this.fb.group({
      answer: ["", [Validators.required]],
    });
  }

  async ngOnInit() {
    await this.jobQAService.getListByDTOJobID(this.jobID).then((resp) => {
      this.safeAnalysis = this.sanitizer.bypassSecurityTrustHtml(
        this.jobObj.analysis
      );
      if (this.jobObj.analysis === null || this.jobObj.analysis === "") {
        this.safeAnalysis = this.sanitizer.bypassSecurityTrustHtml(
          "Henüz analiz girilmemiş!"
        );
      }

      this.jobQAs = resp;
      console.log(this.jobQAs);
    });
  }

  async sendQ() {
    const clientDate = new Date();
    const timezoneOffsetInMinutes = clientDate.getTimezoneOffset();
    const clientDateWithTimezone = new Date(
      clientDate.getTime() - timezoneOffsetInMinutes * 60 * 1000
    ).toISOString();
    // clientDateWithTimezone'i back-end'e gönder

    await this.jobQAService
      .addJobQAs({
        jobId: this.jobID,
        questionUserId: this.user.data.id,
        questionDate: clientDateWithTimezone,
        question: this.addJobQForm.value.question,
      })
      .then((a) => {
        this.snackBarService.openSnackBar(true);
        this.jobQAService.getListByDTOJobID(this.jobID).then((resp) => {
          this.jobQAs = resp;
        });

        this.addJobQForm.reset();
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }
  async sendA(id: number) {
    id = this.answeredQuestionId;
    const clientDate = new Date();
    const timezoneOffsetInMinutes = clientDate.getTimezoneOffset();
    const clientDateWithTimezone = new Date(
      clientDate.getTime() - timezoneOffsetInMinutes * 60 * 1000
    ).toISOString();
    // clientDateWithTimezone'i back-end'e gönder

    //console.log("id: ", id);
    await this.jobQAService.getById(id).then((resp) => {
      this.jobQAsObj = resp;
      //console.log("sendA", this.jobQAsObj);
      this.jobQAService
        .updateJobQAs({
          ...this.jobQAsObj,
          answerDate: clientDateWithTimezone,
          answerUserId: this.user.data.id,
          answer: this.addJobAForm.value.answer,
        })
        .then((a) => {
          this.snackBarService.openSnackBar(true);
          this.jobQAService.getListByDTOJobID(this.jobID).then((resp) => {
            this.jobQAs = resp;
            this.answerClick(0, id);
          });

          this.addJobAForm.reset();
        })
        .catch((err) => {
          this.snackBarService.openSnackBar(false);
        });
    });
  }

  async answerClick(number: number, id: number) {
    if (number === 1) {
      this.isAnswerInputOpen = true;
      this.answeredQuestionId = id;
    } else if (number === 0) {
      this.isAnswerInputOpen = false;
    }
  }
  async deleteAnswer(id: number) {
    await this.jobQAService.getByDTOId(id).then((resp) => {
      this.jobQAService
        .updateJobQAs({
          ...resp[0],
          answerDate: null,
          answerUserId: null,
          answer: null,
        })
        .then((a) => {
          this.snackBarService.openSnackBar(true);
          this.jobQAService.getListByDTOJobID(this.jobID).then((resp) => {
            this.jobQAs = resp;
            this.answerClick(0, id);
          });
        })
        .catch((err) => {
          this.snackBarService.openSnackBar(false);
        });
    });
  }

  async deleteQuestion(id: any) {
    await this.jobQAService
      .deleteJobQAs(id)
      .then((res) => {
        this.snackBarService.openSnackBar(true);
        this.jobQAService.getListByDTOJobID(this.jobID).then((resp) => {
          this.jobQAs = resp;
        });
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }

  public config: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Analiz giriniz...",
    translate: "no",
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  public configQuestion: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Sorunuzu giriniz...",
    translate: "no",
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
  public configAnswer: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Yanıtınızı giriniz...",
    translate: "no",
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  public analysisHtml: SafeHtml;

  editAnalysisClick(bool: boolean) {
    this.config.editable = bool;
  }

  async saveAnalysisClick() {
    this.jobObj = {
      ...this.jobObj,
      jobStatusId: 4,
    };
    await this.jobServ
      .updateJobs(this.jobObj)
      .then((resp) => {
        this.snackBarService.openSnackBar(true);
        this.updatePage();
        this.getEmit.emit();
        this.editAnalysisClick(false);
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }

  async updatePage() {
    await this.jobServ.getById(this.jobID).then((res) => {
      this.jobObj = res;

      this.safeAnalysis = this.sanitizer.bypassSecurityTrustHtml(
        this.jobObj.analysis
      );
      if (this.jobObj.analysis === null || this.jobObj.analysis === "") {
        this.safeAnalysis = this.sanitizer.bypassSecurityTrustHtml(
          "Henüz analiz girilmemiş!"
        );
      }
    });
    await this.jobServ.getByDTOId(this.jobID).then((res) => {
      this.jobDto = res;
    });
  }
}
