<br />
<br />
<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <h3>Profil Sayfası</h3>
      <p>
        Şifrenizi değiştirebilir, almak istediğiniz mailleri seçebilirsiniz.
      </p>
    </div>
    <div class="card-body">
      <ngb-tabset [justify]="'end'">
        <ngb-tab title="Mail Ayarları">
          <ng-template ngbTabContent>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-3">
                    <div class="media" *ngFor="let item of userNotifications">
                      <label class="col-form-label">
                        {{ item.jobStatusName }}
                      </label>
                      <div
                        class="media-body text-right icon-state switch-outline"
                      >
                        <label class="switch">
                          <input
                            type="checkbox"
                            [(ngModel)]="item.sendNotification"
                            (change)="notificationChange(item.id)"
                          /><span class="switch-state bg-primary"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab title="Şifre Değiştirme">
          <ng-template ngbTabContent>
            <form class="theme-form" [formGroup]="registerForm">
              <div class="form-group">
                <label class="col-form-label">Yeni Şifre: </label>
                <input
                  class="form-control"
                  formControlName="password"
                  [type]="show1 ? 'text' : 'password'"
                  name="login[password]"
                  required=""
                  placeholder="*********"
                />
                <!-- <div class="show-hide" (click)="showPassword1()" *ngIf="!show1">
                  <span class="show"></span>
                </div>
                <div class="show-hide" (click)="showPassword1()" *ngIf="show1">
                  <span class="Hide"></span>
                </div> -->
                <div
                  *ngIf="
                    registerForm.controls.password.touched &&
                    registerForm.controls.password.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  Şifre girmek zorunludur.
                </div>
                <div
                  *ngIf="
                    registerForm.controls.password.touched &&
                    registerForm.controls.password.errors?.minlength
                  "
                  class="text text-danger mt-1"
                >
                  Şifreniz en az 8 karakterden oluşmalıdır.
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Yeni Şifre (Tekrar): </label>
                <input
                  class="form-control"
                  formControlName="repassword"
                  [type]="show2 ? 'text' : 'password'"
                  name="login[password]"
                  required=""
                  placeholder="*********"
                />
                <!-- <div class="show-hide" (click)="showPassword2()" *ngIf="!show2">
                  <span class="show"></span>
                </div>
                <div class="show-hide" (click)="showPassword2()" *ngIf="show2">
                  <span class="Hide"></span>
                </div> -->
                <div
                  *ngIf="
                    registerForm.controls.repassword.touched &&
                    registerForm.controls.repassword.errors?.confirmedValidator
                  "
                  class="text text-danger mt-1"
                >
                  Girilen şifreler aynı olmalıdır.
                </div>
              </div>
              <button
                (click)="updatePassword()"
                class="btn btn-primary btn-block"
                type="button"
                [disabled]="this.registerForm.invalid"
              >
                Şifre Yenile
              </button>
              <br />
            </form>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
</div>
