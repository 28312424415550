<div class="card-body">
  <h5 class="mt-4">Analiz ve Soru/Cevap</h5>
  <div class="form-group">
    <angular-editor
      *ngIf="
        user.data.claimId > 3 &&
        (config.editable === true ||
          jobObj.analysis === '' ||
          jobObj.analysis === null)
      "
      #description
      [config]="config"
      [(ngModel)]="jobObj.analysis"
      [disabled]="!config.editable"
    ></angular-editor>
    <br />
    <div
      *ngIf="
        config.editable === false &&
        (jobObj.analysis !== '' || jobObj.analysis !== null)
      "
      class="analysisDiv"
    >
      <div [innerHTML]="safeAnalysis" [ngClass]="'analysisDiv'"></div>
    </div>
  </div>
  <br />
  <div
    class="d-flex mb-3"
    *ngIf="user.data.claimId > 3 && jobDto.statusID === 3"
  >
    <button
      *ngIf="!config.editable"
      class="btn btn-outline-info"
      type="submit"
      (click)="editAnalysisClick(true)"
    >
      Düzenle
    </button>

    <button
      *ngIf="config.editable"
      class="btn btn-outline-success"
      type="submit"
      (click)="saveAnalysisClick()"
    >
      Kaydet
    </button>
    <div style="margin-right: 10px"></div>
    <button
      *ngIf="config.editable"
      class="btn btn-outline-danger"
      type="submit"
      (click)="editAnalysisClick(false)"
    >
      İptal
    </button>
  </div>

  <div class="card">
    <div class="card-header">
      <h6>Soru/Cevap</h6>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-xl-12 dflex m-b-30" *ngIf="jobObj.jobStatusId === 4">
          <form [formGroup]="addJobQForm">
            <div class="">
              <angular-editor
                formControlName="question"
                #question
                [config]="configQuestion"
                [(ngModel)]="addJobQForm.value.question"
              ></angular-editor>
              <div class="mt-3 d-flex justify-content-end">
                <button class="btn btn-primary" (click)="sendQ()" type="submit">
                  GÖNDER
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="chat">
        <div class="custom-scrollbar">
          <br />
          <br />
          <ul>
            <li *ngFor="let jobQA of jobQAs; let i = index">
              <div class="job-comment-box" *ngIf="jobQA.question !== null">
                <!-- Question Part -->
                <div class="row">
                  <div class="col-md-12">
                    <div class="job-comment-header">
                      <h6>
                        <strong>Soru: </strong>
                        <div
                          [innerHTML]="jobQA.question"
                          [ngClass]="'analysisDiv'"
                        ></div>
                      </h6>
                    </div>
                    <div class="job-comment-body">
                      <div
                        class="job-comment-time"
                        style="display: flex; justify-content: space-between"
                      >
                        <p>
                          {{ jobQA?.questionUserName }}
                        </p>

                        <p>
                          {{ jobQA?.questionDate | date : "medium" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row align-items-center justify-content-end"
                  *ngIf="jobObj.jobStatusId === 4"
                >
                  <div class="col-1">
                    <button
                      *ngIf="jobQA.answer === null"
                      class="answerButton"
                      (click)="answerClick(1, jobQA.id)"
                    >
                      <app-feather-icons
                        style="display: flex"
                        [icon]="'message-circle'"
                      ></app-feather-icons>
                    </button>
                  </div>

                  <div
                    class="col-1"
                    *ngIf="
                      user.data.id === 5 ||
                      user.data.id === 6 ||
                      jobQA.answer === null ||
                      jobQA.questionUserId === user.data.id
                    "
                  >
                    <button
                      class="cancelAnswerButton"
                      (click)="deleteQuestion(jobQA.id)"
                    >
                      <app-feather-icons
                        style="display: flex"
                        [icon]="'trash'"
                      ></app-feather-icons>
                    </button>
                  </div>
                </div>

                <br />

                <!-- Answer Part -->

                <form [formGroup]="addJobAForm" *ngIf="jobQA.answer === null">
                  <div class="row" *ngIf="isAnswerInputOpen">
                    <div *ngIf="jobQA.id == answeredQuestionId">
                      <div class="row mb-2">
                        <div class="col-12">
                          <div class="job-comment-header">
                            <h6>
                              <strong>Cevap: </strong>
                            </h6>
                          </div>
                          <br />

                          <div class="job-comment-body">
                            <angular-editor
                              formControlName="answer"
                              #answer
                              [config]="configAnswer"
                              [(ngModel)]="addJobQForm.value.answer"
                              cdkMonitorElementFocus
                            ></angular-editor>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row align-items-center justify-content-end">
                          <div class="col-1">
                            <button
                              class="sendAnswerButton"
                              (click)="sendA(id)"
                              type="button"
                            >
                              <app-feather-icons
                                style="display: flex"
                                [icon]="'send'"
                              ></app-feather-icons>
                            </button>
                          </div>

                          <div class="col-1">
                            <button
                              class="cancelAnswerButton"
                              (click)="answerClick(0)"
                            >
                              <app-feather-icons
                                style="display: flex"
                                [icon]="'x'"
                              ></app-feather-icons>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div class="row" *ngIf="jobQA.answer !== null">
                  <div class="col-md-12">
                    <div class="job-comment-header">
                      <h6>
                        <strong>Cevap: </strong>
                        <div
                          [innerHTML]="jobQA.answer"
                          [ngClass]="'analysisDiv'"
                        ></div>
                      </h6>
                    </div>
                    <div class="job-comment-body">
                      <div
                        class="job-comment-time"
                        style="display: flex; justify-content: space-between"
                      >
                        <p>
                          {{ jobQA?.answerUserName }}
                        </p>

                        <p>
                          {{ jobQA?.answerDate | date : "medium" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row justify-content-end"
                  *ngIf="jobQA.answer !== null"
                >
                  <div
                    class="col-1"
                    *ngIf="
                      user.data.id === 5 ||
                      user.data.id === 6 ||
                      (jobQA.answerUserId === user.data.id &&
                        jobObj.jobStatusId === 4)
                    "
                  >
                    <button
                      class="cancelAnswerButton"
                      (click)="deleteAnswer(jobQA.id)"
                    >
                      <app-feather-icons
                        style="display: flex"
                        [icon]="'trash'"
                      ></app-feather-icons>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
