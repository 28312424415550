<app-breadcrumb [title]="'Onaylar'" [active_item]="'Onaylar'"> </app-breadcrumb>

<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <!-- Individual column searching (text inputs) Starts-->
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Bekleyen Onaylar</h5>
          <span
            >Bu tabloda bekleyen onaylar listenmiştir, bekleyen iş durumlarını
            iş detay sayfasından onaylayabilirsiniz.
          </span>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md" *ngIf="user.data.claimId > 4">
              <select
                class="form-control"
                (change)="filterCustomer($event.target.value)"
              >
                <option value="default" default selected>Kurum Seçiniz</option>
                <option [value]="item.id" *ngFor="let item of customers">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <select
                class="form-control"
                (change)="filterData($event.target.value, 'projectName')"
              >
                <option value="default" default selected>Proje Seçiniz</option>
                <option [value]="item.name" *ngFor="let item of projects">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <select
                class="form-control"
                (change)="filterData($event.target.value, 'jobStatusName')"
              >
                <option value="default" default selected>
                  İş Durumu Seçiniz
                </option>
                <option value="İş Onayı Bekliyor">İş Onayı Bekleyenler</option>
                <option value="Analiz Onayı Bekliyor">
                  Analiz Onayı Bekleyenler
                </option>
                <option value="Gün Onayı Bekliyor">
                  Gün Onayı Bekleyenler
                </option>
                <!-- <option [value]="item.name" *ngFor="let item of jobStatuses">
                  {{ item.name }}
                </option> -->
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="col-lg-3 form-group">
              <div class="excellDownloadBtn" (click)="export()">
                <app-feather-icons [icon]="'download'"></app-feather-icons>
              </div>
            </div>
          </div>

          <div>
            <div class="mat-elevation-z8">
              <p-table
                [value]="filteredData"
                [columns]="columns"
                [tableStyle]="{ 'min-width': '50rem' }"
                responsiveLayout="scroll"
                [rows]="10"
                [showCurrentPageReport]="true"
                [paginator]="true"
                [rowsPerPageOptions]="[10, 25, 50]"
                currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} gösteriliyor"
                #tt
                [globalFilterFields]="dynamicGlobalFilterFields"
                [scrollable]="true"
                scrollHeight="600px"
              >
                <ng-template pTemplate="caption">
                  <div class="flex justify-content-end align-items-center">
                    <div class="p-input-icon-left">
                      <i class="pi pi-search"></i>
                      <input
                        type="text"
                        pInputText
                        placeholder="Ara"
                        (input)="
                          tt.filterGlobal($event.target.value, 'contains')
                        "
                      />
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th
                      *ngFor="let col of columns"
                      [pSortableColumn]="col.prop"
                    >
                      {{ col.name }}
                      <p-sortIcon [field]="col.prop"></p-sortIcon>
                    </th>
                    <th></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>
                    <td *ngFor="let col of columns">
                      {{ rowData[col.prop] }}
                    </td>
                    <td>
                      <div class="example-button-container">
                        <button
                          (click)="onClickDetail(rowData.id)"
                          mat-mini-fab
                          color="primary"
                          style="margin-right: 10px"
                          matTooltip="Detay"
                        >
                          <mat-icon>arrow_forward</mat-icon>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="100%">Gösterilecek veri bulunmamaktadır</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
