<app-breadcrumb [title]="'Loglar'" [active_item]="'Loglar'"> </app-breadcrumb>
<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <!-- Individual column searching (text inputs) Starts-->
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Loglar</h5>
          <span>Bu tabloda loglar listenmiştir. </span>
        </div>

        <div class="card-body">
          <div class="">
            <div class="">
              <div>
                <div class="mat-elevation-z8">
                  <p-table
                    [value]="logs"
                    [columns]="columns"
                    [tableStyle]="{ 'min-width': '50rem' }"
                    responsiveLayout="scroll"
                    [rows]="10"
                    [showCurrentPageReport]="true"
                    [paginator]="true"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} gösteriliyor"
                    #tt
                    [scrollable]="true"
                    scrollHeight="600px"
                  >
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th
                          *ngFor="let col of columns"
                          [pSortableColumn]="col.prop"
                        >
                          {{ col.name }}
                          <p-sortIcon [field]="col.prop"></p-sortIcon>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      let-rowData
                      let-columns="columns"
                    >
                      <tr>
                        <td *ngFor="let col of columns">
                          {{ rowData[col.prop] }}
                        </td>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="100%">
                          Gösterilecek veri bulunmamaktadır
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Individual column searching (text inputs) Ends-->
  </div>
</div>

<app-add-log #addLogs (onSave)="updateTable()"></app-add-log>
<app-edit-log #editLogs (onSave)="updateTable()"></app-edit-log>
<app-delete-log #deleteLogs (onSave)="updateTable()"></app-delete-log>

<!-- Container-fluid Ends-->
