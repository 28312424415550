import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";

import { JobRoutingModule } from "./job-routing.module";
import { CreateJobComponent } from "../create-job/create-job.component";
import { SharedModule } from "src/app/shared/shared.module";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { TreeTableModule } from "primeng/treetable";
import { TableModule } from "primeng/table";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { InputTextareaModule } from "primeng/inputtextarea";
import { DialogModule } from "primeng/dialog";

@NgModule({
  declarations: [CreateJobComponent],
  imports: [
    CommonModule,
    JobRoutingModule,
    SharedModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    TreeTableModule,
    TableModule,
    InputTextareaModule,
    DialogModule,

    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
  ],
  providers: [DatePipe],
  exports: [CreateJobComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class JobModule {}
