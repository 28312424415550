import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProjectsService } from "src/app/shared/services/projects.service";
import { isPlatformBrowser } from "@angular/common";
import { CustomerService } from "src/app/shared/services/customer.service";
import { UserService } from "src/app/shared/services/user.service";
import { AuthService } from "src/app/shared/services/auth.service";
import * as CryptoJS from "crypto-js";
import { ProjectUsersService } from "src/app/shared/services/project-users.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-add-project",
  templateUrl: "./add-project.component.html",
  styleUrls: ["./add-project.component.scss"],
})
export class AddProjectComponent implements OnInit {
  @ViewChild("addProjects", { static: false }) addProjects: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();
  public users: any;
  public customers: any;

  public closeResult: string;
  public modalOpen: boolean = false;
  public addProjectForm: FormGroup;
  public user: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private projectServ: ProjectsService,
    private customerService: CustomerService,
    private userService: UserService,
    private projectUserService: ProjectUsersService,
    private authService: AuthService,
    private snackBarService: SnackbarService
  ) {
    this.addProjectForm = this.fb.group({
      name: ["", [Validators.required]],
      customerId: [""],
      code: ["", [Validators.required, Validators.maxLength(5)]],
      managerUserId: [
        "",
        [Validators.required, Validators.pattern("^[0-9]*$")],
      ],
      analysisApproverUserId: [
        "",
        [Validators.required, Validators.pattern("^[0-9]*$")],
      ],
      dayApproverUserId: [
        "",
        [Validators.required, Validators.pattern("^[0-9]*$")],
      ],
    });
  }

  async ngOnInit() {
    //this.user = this.authService.decyptedUser;
    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);
    //console.log(this.user);

    await this.customerService.getAllCustomers().then((res) => {
      this.customers = res;
    });
  }

  async openModal() {
    if (isPlatformBrowser(this.platformId)) {
      // For SSR

      await this.userService
        .getByCustomerId(this.user.data.customerId)
        .then((res) => {
          this.users = res;
        });

      this.modalService
        .open(this.addProjects, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.addProjectForm.reset();
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  async selectCustomer(value) {
    if (this.user.data.claimId < 4) {
      await this.userService
        .getByCustomerId(this.user.data.customerId)
        .then((res) => {
          this.users = res;
        });
    } else {
      await this.userService.getByCustomerId(value).then((res) => {
        this.users = res;
      });
    }
  }

  saveProject() {
    var data = {
      name: this.addProjectForm.value.name,
      customerId: this.addProjectForm.value.customerId,
      code: this.addProjectForm.value.code,
      managerUserId: this.addProjectForm.value.managerUserId,
      analysisApproverUserId: this.addProjectForm.value.analysisApproverUserId,
      dayApproverUserId: this.addProjectForm.value.dayApproverUserId,
      isActive: true,
    };

    if (this.user.data.claimId < 4) {
      data.customerId = this.user.data.customerId;
    }

    this.projectServ
      .addProjects(data)
      .then((a) => {
        this.addProjectForm.reset();
        this.modalService.dismissAll();
        this.onSave.emit();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
        this.addProjectForm.reset();
        this.modalService.dismissAll();
      });
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
