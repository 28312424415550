import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JobApprovalTypesService } from "src/app/shared/services/job-approval-types.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-add-job-approval-type",
  templateUrl: "./add-job-approval-type.component.html",
  styleUrls: ["./add-job-approval-type.component.css"],
})
export class AddJobApprovalTypeComponent implements OnInit {
  @ViewChild("addJobApprovalTypes", { static: false })
  addJobApprovalTypes: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  public addJobApprovalTypeForm: FormGroup;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private jobApprovalTypeServ: JobApprovalTypesService,
    private snackBarService: SnackbarService
  ) {
    this.addJobApprovalTypeForm = this.fb.group({
      name: ["", [Validators.required]],
    });
  }

  ngOnInit() {}

  openModal() {
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.addJobApprovalTypes, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.addJobApprovalTypeForm.reset();
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  async saveJobApprovalType() {
    await this.jobApprovalTypeServ
      .addJobApprovalTypes({
        name: this.addJobApprovalTypeForm.value.name,
      })
      .then((a) => {
        this.addJobApprovalTypeForm.reset();
        this.modalService.dismissAll();
        this.onSave.emit();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.addJobApprovalTypeForm.reset();
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
  closeModal() {
    this.modalService.dismissAll();
  }
}
