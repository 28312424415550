import { HttpErrorResponse } from "@angular/common/http";
import { ComponentFactoryResolver, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { isThisISOWeek } from "date-fns";
//import { HttpClientService } from "./http-client.service";
//import { UserService } from "./user.service";
import { HttpClient } from "@angular/common/http";
import * as CryptoJS from "crypto-js";
import { Observable } from "rxjs";
import { SnackbarService } from "./snackbar.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    //private userServ: UserService,
    //private http: HttpClientService,
    private httpAngular: HttpClient,
    private router: Router,
    private helper: JwtHelperService,
    private snackBarService: SnackbarService
  ) {}

  public password = "2233**superkeyKANLIsuperkey2233*";
  public decyptedUser;
  public baseUrl = "https://wfapi.monassist.com/api";
  //public baseUrl = "https://localhost:7195/api";
  public user: any;

  // async loginWithEmailAndPassword(email: string, password: string) {
  //   this.http
  //     .httpLoginPost(
  //       "/Auth/login",
  //       {
  //         Email: email,
  //         Password: password,
  //       },
  //       false,
  //       true
  //     )
  //     .then(
  //       (resp) => {
  //         this.userServ.saveUser(resp);
  //         let user = JSON.parse(sessionStorage.getItem("user"));
  //         const decodedToken = this.helper.decodeToken(resp.token);
  //         this.userServ
  //           .getUserById(
  //             decodedToken[
  //               "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
  //             ]
  //           )
  //           .then((resp) => {
  //             user.data = resp;
  //             this.userServ.saveUser(user);
  //             //user = { ...user, data: resp };

  //             const dataJson = JSON.stringify(user);

  //             var encrypted = CryptoJS.AES.encrypt(dataJson, this.password);
  //             console.log(encrypted);
  //             sessionStorage.setItem("user", encrypted.toString());

  //             var decrypted = CryptoJS.AES.decrypt(
  //               sessionStorage.getItem("user"),
  //               this.password
  //             ).toString(CryptoJS.enc.Utf8);

  //             this.decyptedUser = JSON.parse(decrypted);

  //             this.router.navigateByUrl("/dashboard");
  //           });
  //       },
  //       (error) => {
  //         sessionStorage.setItem("user", null);
  //       }
  //     );
  // }

  async loginWithEmailAndPassword(email: string, password: string) {
    this.httpAngular
      .post(this.baseUrl + "/Auth/login", {
        Email: email,
        Password: password,
      })
      .subscribe(
        (res: any) => {
          this.user = res;
          //console.log(this.user);
          const decodedToken = this.helper.decodeToken(res.token);
          const userId =
            decodedToken[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
            ];

          this.httpAngular
            .get(this.baseUrl + "/Auth/getuserbyid?userId=" + userId)
            .subscribe({
              next: (res: any) => {
                this.user.data = res.data;

                const dataJson = JSON.stringify(this.user);
                var encrypted = CryptoJS.AES.encrypt(dataJson, this.password);
                sessionStorage.setItem("user", encrypted.toString());

                var decrypted = CryptoJS.AES.decrypt(
                  sessionStorage.getItem("user"),
                  this.password
                ).toString(CryptoJS.enc.Utf8);

                this.decyptedUser = JSON.parse(decrypted);

                this.snackBarService.openSnackBarWithMessageSuccess(
                  "Giriş Başarılı"
                );
                this.router.navigateByUrl("/dashboard");
              },
              error: (err) => {
                this.snackBarService.openSnackBarWithMessageError(err.message);
              },
            });
        },
        (err) => {
          this.snackBarService.openSnackBarWithMessageError(err.error.message);
        }
      );
  }

  register(data): Observable<any> {
    return this.httpAngular.post<any>(this.baseUrl + "/Auth/register", data);
  }
}
