<div class="card">
  <div class="card-header heading">
    <h6>Yorumlar</h6>
  </div>
  <div class="card-body chat-message clearfix">
    <div class="row">
      <div class="col-xl-12 m-b-30">
        <form [formGroup]="addJobCommentForm">
          <div class="">
            <textarea
              formControlName="comment"
              type="text"
              class="form-control input-txt-bx"
              placeholder="Bir yorum yazın..."
              style="min-height: 4rem !important"
            ></textarea>
            <div class="mt-3 d-flex justify-content-end">
              <button
                class="btn btn-primary"
                (click)="sendComment()"
                type="button"
                [disabled]="!addJobCommentForm.valid"
              >
                GÖNDER
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="chat">
      <div class="custom-scrollbar">
        <ul>
          <li *ngFor="let jobComment of jobComments">
            <div class="job-comment-box">
              <div class="row">
                <div class="col-md-12">
                  <div
                    class="job-comment-header d-flex justify-content-between pb-3"
                  >
                    <div>
                      <p class="mb-1">
                        <strong class="message-data-time"
                          >{{ jobComment.userName }}
                        </strong>
                      </p>
                      <div class="job-comment-time text-right">
                        <span class="message-data-time">{{
                          jobComment?.date | date : "dd.MM.yyyy HH:mm"
                        }}</span>
                      </div>
                    </div>
                    <div>
                      <button
                        pButton
                        class="p-button-text p-button-rounded p-button-outlined p-button-danger"
                        icon="pi pi-trash"
                        [rounded]="true"
                        severity="danger"
                        [outlined]="true"
                        (click)="deleteComment(jobComment.id)"
                        *ngIf="jobComment.userId === user.data.id"
                      ></button>
                    </div>
                  </div>
                  <div class="job-comment-body pt-3">
                    <div
                      class="job-comment-content"
                      style="white-space: pre-wrap"
                    >
                      <p>{{ jobComment?.comment }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
