import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";

@Injectable({
  providedIn: "root",
})
export class SprintsService {
  constructor(private http: HttpClientService, private datePipe: DatePipe) {}

  async getList() {
    return await this.http.httpGet("/Sprints/getlist").then((res) => {
      return res.data;
    });
  }
  

  async getDTOList() {
    return await this.http.httpGet("/Sprints/getdtolist").then((res) => {
      res = res.data.map((sprint) => {
        return {
          ...sprint,
          endDate: this.datePipe.transform(sprint.endDate, "dd.MM.yyyy HH:mm"),
          startDate: this.datePipe.transform(
            sprint.startDate,
            "dd.MM.yyyy HH:mm"
          ),
        };
      });

      return res;
    });
  }

  async getByProjectID(projectID: Number) {
    return await this.http
      .httpGet("/Sprints/getbyprojectid?projectID=" + projectID)
      .then((res) => {
        return res.data;
      });
  }

  async getByDTOProjectID(projectID: Number) {
    return await this.http
      .httpGet("/Sprints/getbydtoprojectid?projectID=" + projectID)
      .then((res) => {
        return res.data;
      });
  }

  async getById(sprintID: Number) {
    return await this.http
      .httpGet("/Sprints/getbyid?sprintID=" + sprintID)
      .then((res) => {
        return res.data;
      });
  }

  async getByDTOId(sprintID: Number) {
    return await this.http
      .httpGet("/Sprints/getbydtoid?sprintID=" + sprintID)
      .then((res) => {
        res = res.data.map((sprint) => {
          return {
            ...sprint,
            startDate: this.datePipe.transform(
              sprint.startDate,
              "dd.MM.yyyy HH:mm"
            ),
            endDate: this.datePipe.transform(
              sprint.endDate,
              "dd.MM.yyyy HH:mm"
            ),
          };
        });
        return res;
      });
  }

  async getByDTOCustomerId(customerId: Number, live: Boolean) {
    if (live === true || live === false) {
      return await this.http
        .httpGet(
          "/Sprints/getbydtocustomerid?customerId=" +
            customerId +
            "&guncel=" +
            live
        )
        .then((res) => {
          res = res.data.map((sprint) => {
            return {
              ...sprint,
              startDate: this.datePipe.transform(
                sprint.startDate,
                "dd.MM.yyyy HH:mm"
              ),
              endDate: this.datePipe.transform(
                sprint.endDate,
                "dd.MM.yyyy HH:mm"
              ),
            };
          });
          return res;
        });
    } else {
      return await this.http
        .httpGet("/Sprints/getbydtocustomerid?customerId=" + customerId)
        .then((res) => {
          res = res.data.map((sprint) => {
            return {
              ...sprint,
              startDate: this.datePipe.transform(
                sprint.startDate,
                "dd.MM.yyyy HH:mm"
              ),
              endDate: this.datePipe.transform(
                sprint.endDate,
                "dd.MM.yyyy HH:mm"
              ),
            };
          });
          return res;
        });
    }
  }

  async addSprints(data) {
    return await this.http.httpPost("/Sprints/add", data);
  }
  async deleteSprints(data) {
    return await this.http.httpDelete("/Sprints/delete", data);
  }
  async updateSprints(data) {
    return await this.http.httpPut("/Sprints/update", data);
  }
}
