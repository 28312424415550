<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="login-main">
            <div>
              <a class="logo" routerLink="/auth/login">
                <img
                  class="img-fluid for-light"
                  src="https://monassist.com/img/Logo_Kirpilmis.png"
                  alt="looginpage"
                  style="width: 200px !important"
                />
                <img
                  class="img-fluid for-dark"
                  src="https://monassist.com/img/Logo_Kirpilmis.png"
                  alt="looginpage"
                  style="width: 200px !important"
                />
              </a>
            </div>
            <form class="theme-form" [formGroup]="loginForm">
              <h4 class="text-center">Hesapta oturum açın</h4>
              <p class="text-center">
                Giriş yapmak için e-posta adresinizi ve şifrenizi girin.
              </p>
              <div class="form-group">
                <label class="col-form-label">E-Posta </label>
                <input
                  class="form-control"
                  type="email"
                  required
                  placeholder="E-Posta"
                  formControlName="email"
                />
                <div
                  *ngIf="
                    loginForm.controls.email.touched &&
                    loginForm.controls.email.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  Email girmek zorunludur.
                </div>
                <div
                  *ngIf="
                    loginForm.controls.email.touched &&
                    loginForm.controls.email.errors?.email &&
                    loginForm.get('email').hasError('pattern')
                  "
                  class="text text-danger mt-1"
                >
                  Lütfen geçerli bir email adresi giriniz.
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Şifre</label>
                <input
                  class="form-control"
                  [type]="show ? 'text' : 'password'"
                  formControlName="password"
                  required
                  placeholder="Şifre"
                />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                  <span class="show"></span>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show">
                  <span class="Hide"></span>
                </div>
                <div
                  *ngIf="
                    loginForm.controls.password.touched &&
                    loginForm.controls.password.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  Şifre girmek zorunludur.
                </div>
              </div>
              <div class="form-group mb-0">
                <!-- <a [routerLink]="'/authentication/forgot-password'" class="link">Şifremi Unuttum?</a> -->
                <a href="#">
                  <button
                    class="btn btn-primary btn-block"
                    [class.loader--text]="authService.showLoader"
                    [disabled]="!loginForm.valid || authService.showLoader"
                    (click)="login()"
                    type="submit"
                  >
                    <span>{{ authService.showLoader ? "" : "Giriş" }}</span>
                  </button>
                </a>
              </div>
              <!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6>
              <div class="social mt-4">
                <div class="btn-showcase">
                  <a class="btn btn-light" (click)="loginFacebook()" target="_blank">
                      <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>Facebook
                  </a>
                  <a class="btn btn-light" (click)="loginTwitter()" target="_blank">
                    <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter
                  </a>
                  <a class="btn btn-light" (click)="loginGoogle()" target="_blank">
                      <app-feather-icons [icon]="'log-in'" class="txt-google"></app-feather-icons>Google +
                  </a>
                </div>
              </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
