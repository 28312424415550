import { Component, OnInit, ViewChild } from "@angular/core";
import { ProjectUsersService } from "src/app/shared/services/project-users.service";
import { ProjectsService } from "src/app/shared/services/projects.service";
import { UserService } from "src/app/shared/services/user.service";
import { AddProjectUserComponent } from "./add-project-user/add-project-user.component";
import { DeleteProjectUserComponent } from "./delete-project-user/delete-project-user.component";
import { EditProjectUserComponent } from "./edit-project-user/edit-project-user.component";

@Component({
  selector: "app-project-users",
  templateUrl: "./project-users.component.html",
  styleUrls: ["./project-users.component.scss"],
})
export class ProjectUsersComponent implements OnInit {
  @ViewChild("addProjectUsers") AddProjectUsers: AddProjectUserComponent;
  @ViewChild("editProjectUsers") EditProjectUser: EditProjectUserComponent;
  @ViewChild("deleteProjectUsers")
  DeleteProjectUsers: DeleteProjectUserComponent;
  public outputID: number;
  public projectUsers = [{}];
  public users = [];
  public projects = [];
  public columns = [
    { prop: "id", name: "Numara" },
    { prop: "projectName", name: "Proje Adı" },
    { prop: "userName", name: "Kullanıcı Adı" },
  ];
  public dynamicGlobalFilterFields: any;

  constructor(
    private projectUserServ: ProjectUsersService,
    private userServ: UserService,
    private projectServ: ProjectsService
  ) {}

  ngOnInit() {
    this.dynamicGlobalFilterFields = this.columns.map((col) => col.prop);

    this.projectUserServ.getAllProjectUsersDTO().then((resp) => {
      this.projectUsers = resp;

      this.userServ.getAllUsersDTO().then((resp) => {
        this.users = resp;
        this.projectServ.getAllProjectsDTO().then((resp) => {
          this.projects = resp;
          this.projects = resp.filter(function (item): boolean {
            return item.isActive == true;
          });
        });
      });
    });
  }
  async updateTable() {
    await this.projectUserServ.getAllProjectUsersDTO().then((resp) => {
      this.projectUsers = resp;
    });
  }
}
