import { Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";

@Injectable({
  providedIn: "root",
})
export class ProjectUsersService {
  constructor(private http: HttpClientService) {}

  async getAllProjectUsers() {
    return await this.http.httpGet("/ProjectUsers/getall").then((res) => {
      return res.data;
    });
  }

  async getAllProjectUsersDTO() {
    return await this.http.httpGet("/ProjectUsers/getdtoall").then((res) => {
      return res.data;
    });
  }

  async getListByProjectID(projectID: Number) {
    return await this.http
      .httpGet("/ProjectUsers/getlistbyprojectid?projectID=" + projectID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByDTOProjectID(projectID: Number) {
    return await this.http
      .httpGet("/ProjectUsers/getlistbydtoprojectid?projectID=" + projectID)
      .then((res) => {
        return res.data;
      });
  }

  async getProjectListByUserID(userID: Number) {
    return await this.http
      .httpGet("/ProjectUsers/getprojectlistbyuserid?userID=" + userID)
      .then((res) => {
        return res.data;
      });
  }

  async getProjectListByDTOUserID(userID: Number) {
    return await this.http
      .httpGet("/ProjectUsers/getprojectlistbydtouserid?userID=" + userID)
      .then((res) => {
        return res.data;
      });
  }

  async getUserListByProjectID(projectID: Number) {
    return await this.http
      .httpGet("/ProjectUsers/getuserlistbyprojectid?projectID=" + projectID)
      .then((res) => {
        return res.data;
      });
  }

  async getUserListByDTOProjectID(projectID: Number) {
    return await this.http
      .httpGet("/ProjectUsers/getuserlistbydtoprojectid?projectID=" + projectID)
      .then((res) => {
        return res.data;
      });
  }

  async getById(projectUserID: Number) {
    return await this.http
      .httpGet("/ProjectUsers/getbyid?projectUserID=" + projectUserID)
      .then((res) => {
        return res.data;
      });
  }

  async getByDTOId(projectUserID: Number) {
    return await this.http
      .httpGet("/ProjectUsers/getbyid?projectUserID=" + projectUserID)
      .then((res) => {
        return res.data;
      });
  }

  async addProjectUsers(data) {
    return await this.http.httpPost("/ProjectUsers/add", data);
  }
  async deleteProjectUsers(data) {
    return await this.http.httpDelete("/ProjectUsers/delete", data);
  }
  async updateProjectUsers(data) {
    return await this.http.httpPut("/ProjectUsers/update", data);
  }
}
