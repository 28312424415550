import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class JobQAsService {

  constructor(private http : HttpClientService) { }


  async getById(jobQAID: Number) {
    return await this.http
      .httpGet("/JobQAs/getbyid?jobQAID=" + jobQAID)
      .then((res) => {
        return res.data;
      });
  }

  async getByDTOId(jobQAID: Number) {
    return await this.http
      .httpGet("/JobQAs/getbydtoid?jobQAID=" + jobQAID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobQAs/getlistbyjobid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByDTOJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobQAs/getlistbydtojobid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }

  async addJobQAs(data) {
    return await this.http.httpPost("/JobQAs/add", data);
  }
  async deleteJobQAs(data) {
    return await this.http.httpDelete("/JobQAs/delete", data);
  }
  async updateJobQAs(data) {
    return await this.http.httpPut("/JobQAs/update", data);
  }
}
