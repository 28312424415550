import { Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";

@Injectable({
  providedIn: "root",
})
export class UserNotificationsService {
  constructor(private http: HttpClientService) {}

  async getAllUserNotifications() {
    return await this.http.httpGet("/UserNotifications/getall").then((res) => {
      return res.data;
    });
  }

  async getAllUserNotificationsDTO() {
    return await this.http
      .httpGet("/UserNotifications/getdtoall")
      .then((res) => {
        return res.data;
      });
  }

  async getById(userNotificationID: Number) {
    return await this.http
      .httpGet(
        "/UserNotifications/getbyid?userNotificationID=" + userNotificationID
      )
      .then((res) => {
        return res.data;
      });
  }

  async getByDTOId(userNotificationID: Number) {
    return await this.http
      .httpGet(
        "/UserNotifications/getbydtoid?userNotificationID=" + userNotificationID
      )
      .then((res) => {
        return res.data;
      });
  }
  async getByDTOUserId(userNotificationID: Number) {
    return await this.http
      .httpGet("/UserNotifications/getbydtouserid?userID=" + userNotificationID)
      .then((res) => {
        return res.data;
      });
  }
  async getByUserId(userNotificationID: Number) {
    return await this.http
      .httpGet("/UserNotifications/getbyuserid?userID=" + userNotificationID)
      .then((res) => {
        return res.data;
      });
  }

  async addUserNotifications(data) {
    return await this.http.httpPost("/UserNotifications/add", data);
  }
  async deleteUserNotifications(data) {
    return await this.http.httpDelete("/UserNotifications/delete", data);
  }
  async updateUserNotifications(data) {
    return await this.http.httpPut("/UserNotifications/update", data);
  }
}
