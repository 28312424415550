<app-breadcrumb
  [title]="'İş Düzenle'"
  [items]="['İşler']"
  [active_item]="'İşler'"
>
</app-breadcrumb>

<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <h5 style="padding: 5px 15px">
          İş Kodu: <span> {{ jobObj?.code }} </span>
        </h5>
      </div>
      <div class="row">
        <div class="col-md-6 pr-8">
          <div class="form-group">
            <label>Üst İş</label>
            <select
              class="form-control"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.parentJobId
              "
            >
              <option [value]="null" selected hidden>
                Lütfen Üst İş Seçiniz...
              </option>
              <option [value]="item.id" *ngFor="let item of jobList">
                {{ item.code + " - " + item.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="bm-name"
              >İş Adı <span style="color: red">*</span></label
            >
            <input
              class="form-control"
              type="text"
              name="myControl"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.name
              "
              placeholder="İş Adı"
            />
          </div>

          <div class="form-group">
            <label for="bm-name">Konu </label>
            <input
              class="form-control"
              id="bm-name"
              type="text"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.topic
              "
              autocomplete="off"
              placeholder="İşin Konusu"
            />
          </div>

          <div class="form-group">
            <label for="bm-name"
              >Açıklama <span style="color: red">*</span></label
            >
            <textarea
              class="form-control"
              style="height: 125px !important"
              type="text"
              name="myControl2"
              placeholder="Açıklama"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.details
              "
            ></textarea>
          </div>

          <div class="form-group">
            <label>Proje <span style="color: red">*</span></label>
            <select
              name="myControl3"
              class="form-control"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.projectId
              "
            >
              <option selected hidden>
                {{ jobDto?.jobPriorityName }}
              </option>
              <option [value]="item.id" *ngFor="let item of projects">
                {{ item?.name }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label>İş Önceliği <span style="color: red">*</span></label>
            <select
              class="form-control"
              name="myControl4"
              [(ngModel)]="
                jobObj == null || jobObj == undefined
                  ? ''
                  : jobObj.jobPriorityId
              "
            >
              <option selected hidden>
                {{ jobDto?.jobPriorityName }}
              </option>
              <option [value]="item.id" *ngFor="let item of priorities">
                {{ item?.name }}
              </option>
            </select>
          </div>

          <div class="form-group" *ngIf="user.data.claimId > 3">
            <label>İş Durumu <span style="color: red">*</span></label>
            <select
              name="myControl5"
              class="form-control"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.jobStatusId
              "
            >
              <option selected hidden>
                {{ jobDto?.jobStatusName }}
              </option>
              <option [value]="item.id" *ngFor="let item of jobStatuses">
                {{ item?.name }}
              </option>
            </select>
          </div>

          <div class="form-group" *ngIf="user.data.claimId > 3">
            <label>İş Tipi</label>
            <select
              class="form-control"
              name="myControl6"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.jobTypeId
              "
            >
              <option selected hidden>
                {{ jobDto?.jobTypeName }}
              </option>
              <option [value]="item.id" *ngFor="let item of jobTypes">
                {{ item?.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-6 pr-8" *ngIf="user.data.claimId > 3">
          <div class="form-group" *ngIf="user.data.claimId > 3">
            <label>Atanan Kullanıcı</label>
            <select
              name="myControl7"
              class="form-control"
              [(ngModel)]="
                jobObj == null || jobObj == undefined
                  ? ''
                  : jobObj.assignedUserId
              "
            >
              <option selected hidden>
                {{ jobDto?.assignedUserName }}
              </option>
              <option [value]="item.id" *ngFor="let item of users">
                {{ item?.fullName }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label>Sprint</label>
            <select
              class="form-control"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.sprint
              "
            >
              <option selected hidden>{{ sprint?.name }}</option>
              <option [ngValue]="nullValue">Sprint Seçiniz</option>
              <option [value]="item.id" *ngFor="let item of sprints">
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="bm-name">Tahmini Gün</label>
            <input
              class="form-control"
              type="number"
              name="myControl8"
              placeholder="Tahmini Gün"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.estimatedDay
              "
            />
          </div>

          <div class="form-group">
            <label for="bm-name">Gerçekleşen Gün</label>
            <input
              class="form-control"
              type="number"
              name="myControl9"
              placeholder="Gerçekleşen Gün"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.actualDay
              "
            />
          </div>

          <div class="form-group">
            <label for="bm-name">Fatura Edilen Gün</label>
            <input
              class="form-control"
              type="number"
              name="myControl10"
              placeholder="Fatura Edilen Gün"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.billedDay
              "
            />
          </div>

          <div class="form-group">
            <label for="bm-name">Başlama Tarihi</label>
            <input
              class="form-control"
              type="date"
              name="myControl11"
              placeholder="İşe Başlama Tarihi"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.startDate
              "
            />
          </div>

          <div class="form-group">
            <label for="bm-name">Bitiş Tarihi</label>
            <input
              class="form-control"
              type="date"
              name="myControl12"
              placeholder="İşi Bitirme Tarihi"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.endDate
              "
            />
          </div>

          <div class="form-group">
            <label for="bm-name">Test Tarihi</label>
            <input
              class="form-control"
              type="date"
              name="myControl13"
              placeholder="Teste Çıkma Tarihi"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.testDate
              "
            />
          </div>

          <div class="form-group">
            <label for="bm-name">Yayınlanma Tarihi</label>
            <input
              class="form-control"
              type="date"
              name="myControl14"
              placeholder="Yayınlanma Tarihi"
              [(ngModel)]="
                jobObj == null || jobObj == undefined ? '' : jobObj.prodDate
              "
            />
          </div>
        </div>

        <div class="col-10">
          <button class="btn btn-info" type="submit" (click)="onSubmit()">
            Kaydet
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
