<!-- Page Header Start-->
<div
  class="page-header"
  [class.close_icon]="navServices.collapseSidebar"
  style="margin-bottom: 50px"
>
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img
            class="img-fluid for-light"
            src="https://monassist.com/img/Logo_Kirpilmis.png"
            alt=""
          />
          <img
            class="img-fluid for-dark"
            src="https://monassist.com/img/Logo_Kirpilmis.png"
            alt=""
          />
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons
          [icon]="'align-center'"
          class="status_toggle middle"
        ></app-feather-icons>
      </div>
    </div>
    <div class="left-header col horizontal-wrapper pl-0" hidden>
      <app-mega-menu></app-mega-menu>
    </div>
    <div class="nav-right col-12 pull-right right-header p-0">
      <ul class="nav-menus">
        <li class="language-nav" (click)="languageToggle()" hidden>
          <app-languages></app-languages>
        </li>
        <li>
          <span class="header-search" (click)="searchToggle()" hidden>
            <app-feather-icons [icon]="'search'"></app-feather-icons>
          </span>
        </li>

        <li>
          <div class="mode" (click)="layoutToggle()">
            <i class="fa fa-moon-o" *ngIf="!dark"></i>
            <i class="fa fa-lightbulb-o" style="color: orange" *ngIf="dark"></i>
          </div>
        </li>

        <li class="maximize" hidden>
          <a
            class="text-dark"
            href="javascript:void(0)"
            (click)="toggleFullScreen()"
          >
            <app-feather-icons [icon]="'maximize'"></app-feather-icons>
          </a>
        </li>
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
