<!-- <button
  *ngIf="user.data.claimId < 4 || user.data.claimId === 5"
  class="btn btn-success"
  style="margin-right: 10px"
  (click)="open(editProjects)"
>
  <span style="display: flex; justify-content: center; align-items: center">
    <app-feather-icons style="margin-right: 5px" [icon]="'edit'">
    </app-feather-icons>
    Düzenle
  </span>
</button> -->

<button
  class="btn btn-success"
  mat-mini-fab
  style="margin-right: 10px"
  (click)="open(editProjects)"
  color="warn"
  matTooltip="Düzenle"
>
  <mat-icon>edit</mat-icon>
</button>

<ng-template #editProjects let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Proje Düzenle</h5>
    <button
      class="close"
      type="button"
      data-dismiss="modal"
      aria-label="Close"
      data-original-title=""
      title=""
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="bm-name">İsim</label>
        <input
          disabled
          class="form-control"
          [(ngModel)]="projectData.name"
          type="text"
          autocomplete="off"
        />
      </div>
      <div class="form-group col-md-12" *ngIf="user.data.claimId > 4">
        <label for="bm-customerId">Kurum</label>
        <select
          disabled
          class="form-control col-12"
          [(ngModel)]="projectData.customerId"
        >
          <option *ngFor="let item of customers" [value]="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-12">
        <label for="bm-code">Kod</label>
        <input
          disabled
          class="form-control"
          id="bm-code"
          type="text"
          required=""
          [(ngModel)]="projectData.code"
          autocomplete="off"
        />
      </div>

      <div class="form-group col-md-12">
        <label for="bm-customerId">İşi Onaylayan Kullanıcı</label>
        <select
          class="form-control col-12"
          [(ngModel)]="projectData.managerUserId"
        >
          <option default selected hidden>
            {{ projectDto?.managerUserName }}
          </option>
          <option *ngFor="let item of users" [value]="item.id">
            {{ item.fullName }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-12">
        <label for="bm-customerId">Analizi Onaylayan Kullanıcı</label>
        <select
          class="form-control col-12"
          [(ngModel)]="projectData.analysisApproverUserId"
        >
          <option default selected hidden>
            {{ projectDto?.analysisApproverUserName }}
          </option>
          <option *ngFor="let item of users" [value]="item.id">
            {{ item.fullName }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-12">
        <label for="bm-customerId">Gün Onayı Veren Kullanıcı</label>
        <select
          class="form-control col-12"
          [(ngModel)]="projectData.dayApproverUserId"
        >
          <option default selected hidden>
            {{ projectDto?.dayApproverUserName }}
          </option>
          <option *ngFor="let item of users" [value]="item.id">
            {{ item.fullName }}
          </option>
        </select>
      </div>

      <div class="form-group col-md-12" *ngIf="user.data.claimId > 4">
        <label for="bm-isActive">Aktif Proje</label>
        <input
          (change)="isActiveChange()"
          [(ngModel)]="projectData.isActive"
          type="checkbox"
          id="bm-isActive"
        />
      </div>
    </div>
    <input id="index_var" type="hidden" value="6" />
    <button
      class="btn btn-success mr-1"
      id="Bookmark"
      [disabled]=""
      (click)="saveProject()"
    >
      Kaydet
    </button>
    <button
      class="btn btn-danger"
      type="button"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      İptal
    </button>
  </div>
</ng-template>
