import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as dragula from "dragula";
import { CustomerService } from "src/app/shared/services/customer.service";
import { JobsService } from "src/app/shared/services/jobs.service";
import { ProjectsService } from "src/app/shared/services/projects.service";
import { UserService } from "src/app/shared/services/user.service";
import { DeleteJobComponent } from "./delete-job/delete-job.component";
import * as CryptoJS from "crypto-js";
import { JobPrioritiesService } from "src/app/shared/services/job-priorities.service";
import { JobStatusesService } from "src/app/shared/services/job-statuses.service";
import { JobTypesService } from "src/app/shared/services/job-types.service";

import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { SprintsService } from "src/app/shared/services/sprints.service";
import * as XLSX from "xlsx";

@Component({
  selector: "app-kanban-board",
  templateUrl: "./kanban-board.component.html",
  styleUrls: ["./kanban-board.component.scss"],
})
export class KanbanBoardComponent implements OnInit {
  // @ViewChild("addProjects") AddProjects: AddProjectComponen;
  // @ViewChild("editProjects") EditProject: EditProjectComponent;
  @ViewChild("deleteJobs") deleteJobs: DeleteJobComponent;
  // @ViewChild("projectDatatable") datatable: DatatableComponent;

  selectedIndex: number = 2;
  public user: any;
  public filteredData: any;

  public sprints: any;
  public filteredSprints: any;
  public jobPriorities: any;
  public jobStatuses: any;
  public jobTypes: any;
  public projects: any;
  public filteredProjects: any;

  statusList = [
    { value: 1, desc: "Yapılacak-Beklemede" },
    { value: 2, desc: "Geliştiriliyor-İşlemde" },
    { value: 3, desc: "Test ediliyor" },
    { value: 4, desc: "Tamamlandı-Yayında" },
    { value: 5, desc: "İptal Edildi-Yapılmayacak" },
    { value: 6, desc: "Geliştirme Tamamlandı-Test Bekliyor" },
    { value: 7, desc: "Test Tamamlandı-Yayına Hazır" },
    { value: 8, desc: "Is Olusturuldu, Yetkili Onayi Bekliyor." },
    { value: 9, desc: "Efor Girilmesi Bekleniyor" },
    { value: 10, desc: "Gün Onayi Bekliyor" },
    { value: 11, desc: "Is Olusturuldu" },
  ];
  jobData = [
    {
      title: "Test1",
      priority: 1,
    },
    {
      title: "Test2",
      priority: 2,
    },
    {
      title: "Test3",
      priority: 3,
    },
    {
      title: "Test4",
      priority: 4,
    },
    {
      title: "Test5",
      priority: 5,
    },
  ];
  kanbanBoards = [];

  constructor(
    private custServ: CustomerService,
    private userServ: UserService,
    private router: Router,
    private jobServ: JobsService,
    private jobPrioritiesService: JobPrioritiesService,
    private jobStatusesService: JobStatusesService,
    private jobTypesService: JobTypesService,
    private sprintService: SprintsService,
    private projectService: ProjectsService,
    private modalService: NgbModal,
    private snackBarService: SnackbarService
  ) {}

  async ngOnInit() {
    this.dynamicGlobalFilterFields = this.columns.map((col) => col.prop);

    //this.user = this.authService.decyptedUser;
    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);
    if (await localStorage.getItem("jobsFilters")) {
      this.filters = await JSON.parse(localStorage.getItem("jobsFilters"));
      this.tempFilters = { ...this.filters };
    }

    localStorage.setItem("location", "jobs");

    localStorage.removeItem("projectDetailFilters");
    localStorage.removeItem("sprintDetailFilters");
    localStorage.removeItem("selectedSprint");
    localStorage.removeItem("selectedIsActive");
    localStorage.removeItem("selectedCustomer");

    dragula([
      document.getElementById("1"),
      document.getElementById("2"),
      document.getElementById("3"),
    ])
      .on("drag", function (el, target, source, sibling) {
        console.log("drag");
        el.classList.add("is-moving");
      })
      .on("dragend", function (el, target, source, sibling) {
        console.log("dragend");
        el.classList.remove("is-moving");
      })
      .on("drop", function (el, target, source, sibling) {
        console.log("drop");
        el.classList.add("is-moving");
      });

    await this.jobPrioritiesService.getAllJobPriorities().then((resp) => {
      this.jobPriorities = resp;
    });
    await this.jobStatusesService.getAllJobStatuses().then((resp) => {
      this.jobStatuses = resp;
    });
    await this.jobTypesService.getAllJobTypes().then((resp) => {
      this.jobTypes = resp;
    });
    await this.sprintService.getDTOList().then((resp) => {
      this.sprints = resp;

      if (this.sprints && this.sprints.length > 0) {
        if (String(this.filters.customerName) !== "default") {
          this.filteredSprints = this.sprints.filter((s) => {
            return String(s.customerName) === String(this.filters.customerName);
          });
        } else if (this.filters.customerName === "default") {
          this.filteredSprints = this.sprints;
        }
      }
    });
    await this.custServ.getAllCustomers().then((resp) => {
      this.customers = resp;
    });
    await this.userServ.getAllUsersDTO().then((resp) => {
      this.users = resp;
    });
    await this.projectService.getAllProjectsDTO().then((res) => {
      this.projects = res;
      this.projects = res.filter(function (item): boolean {
        return item.isActive == true;
      });
      if (this.projects && this.projects.length > 0) {
        console.log(this.filters.customerName);
        if (String(this.filters.customerName) !== "default") {
          this.filteredProjects = this.projects.filter((p) => {
            return String(p.customerName) === String(this.filters.customerName);
          });

          console.log(this.filteredProjects);
        } else if (this.filters.customerName === "default") {
          this.filteredProjects = this.projects;
        }
      }
    });
    await this.jobServ.getAllJobsWithChild().then((resp) => {
      this.jobs = resp;
      this.filteredData = this.convertToPrimeNgTreeData(resp);

      if (this.filters) {
        this.filterData("", "");
      }
    });
  }

  onClick(index: number) {
    this.selectedIndex = index;
    if (this.selectedIndex === 1) {
    } else if (this.selectedIndex === 2) {
    }
  }

  public outputID: number;
  public customers = null;
  public users = null;
  public jobs = [{}];
  public columns = [
    { prop: "code", name: "İş Kodu", colspan: 2, width: "8%" },
    { prop: "name", name: "İş Adı", colspan: 1, width: "14%" },
    { prop: "projectName", name: "Proje Adı", colspan: 2, width: "12%" },
    // {
    //   prop: "createUserName",
    //   name: "Oluşturan Kullanıcı",
    //   colspan: 1,
    //   width: "12%",
    // },
    { prop: "sprintName", name: "Sprint", colspan: 1, width: "12%" },
    { prop: "jobTypeName", name: "İş Tipi", colspan: 1, width: "8%" },
    { prop: "jobStatusName", name: "İş Durumu", colspan: 1, width: "12%" },
    {
      prop: "assignedUserName",
      name: "Atanan Kullanıcı",
      colspan: 1,
      width: "12%",
    },
  ];
  public dynamicGlobalFilterFields: any;

  displayedColumns: string[] = [
    "code",
    "name",
    "projectName",
    "createUserName",
    "sprintName",
    "jobTypeName",
    "jobStatusName",
    "assignedUserName",
    "button",
  ];

  async updateTable() {
    await this.jobServ.getAllJobsWithChild().then((resp) => {
      this.jobs = resp;

      this.filteredData = this.convertToPrimeNgTreeData(resp);
    });
  }

  onClickCreate() {
    this.router.navigateByUrl("/jobs/create-job");
  }

  onClickDetail(id) {
    localStorage.setItem("jobId", id);
    this.router.navigateByUrl("/jobs/job-detail");
  }

  editJob(id) {
    localStorage.setItem("jobId", id);
    this.router.navigateByUrl("/jobs/edit-job");
  }

  deleteJob(id) {
    // console.log(id);
    this.jobServ
      .deleteJobs(id)
      .then((resp) => {
        this.updateTable();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });
  }

  async export() {
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const jobsToExcell = this.filteredData;

    // Sütun başlıklarını tanımlayın ve sıralı bir dizi kullanın
    const columnHeaders = [
      { key: "projectName", header: "Proje Adı" },
      { key: "code", header: "İş Kodu" },
      { key: "name", header: "İş Adı" },
      { key: "details", header: "Detay" },
      { key: "jobTypeName", header: "İş Tipi" },
      { key: "estimatedDay", header: "Toplam Efor" },
      { key: "billedDay", header: "Faturalanan Efor" },
      { key: "sprintName", header: "Sprint" },
      { key: "isBilled", header: "Fatura Durum" },
      { key: "testDate", header: "Test Tarihi" },
      { key: "prodDate", header: "Yayınlanma Tarihi" },
    ];

    // Gereksiz alanları kaldırarak güncellenmiş iş kayıtlarını oluşturun
    const flattenJob = (job) => {
      const updatedJob = {};
      // Verinin 'data' alanı varsa oradan alalım, yoksa job objesini kullanacağız
      const source = job.data || job;

      columnHeaders.forEach((column) => {
        const { key, header } = column;
        if (source[key] !== undefined && source[key] !== null) {
          // Alan varsa ve null değilse
          if (key === "isBilled") {
            updatedJob[header] = source[key]
              ? "Fatura Edildi"
              : "Fatura Edilmedi";
          } else {
            updatedJob[header] = source[key];
          }
        } else {
          updatedJob[header] = ""; // Eksik alanları boş bırak
        }
      });
      return updatedJob;
    };

    // Eğer child varsa onları da düzleştireceğiz
    const updatedJobsToExcell = [];
    jobsToExcell.forEach((job) => {
      updatedJobsToExcell.push(flattenJob(job)); // Ana işi ekle
      if (job.children && job.children.length > 0) {
        job.children.forEach((childJob) => {
          updatedJobsToExcell.push(flattenJob(childJob)); // Child işleri ekle
        });
      }
    });

    // Excel dosyası oluşturuluyor
    const workSheet = XLSX.utils.json_to_sheet(updatedJobsToExcell, {
      header: columnHeaders.map((column) => column.header),
    });

    XLSX.utils.book_append_sheet(workBook, workSheet, "data"); // add the worksheet to the book

    const fileName = "İşler.xlsx";
    XLSX.writeFile(workBook, fileName); // initiate a file download in browser
  }

  filters = {
    sprint: "default",
    jobStatusName: "default",
    jobTypeName: "default",
    projectName: "default",
    customerName: "default",
    assignedUserName: "default",
    isBilled: "default",
  };

  tempFilters: any = {
    sprint: "default",
    jobStatusName: "default",
    jobTypeName: "default",
    projectName: "default",
    customerName: "default",
    assignedUserName: "default",
    isBilled: "default",
  };

  bools = [
    { name: "Fatura Edildi", value: true },
    { name: "Fatura Edilmedi", value: false },
  ];
  setFilters() {
    localStorage.setItem("jobsFilters", JSON.stringify(this.filters));
  }

  filterData(data, condition: any) {
    //console.log(data, " ------- ", condition);
    //this.filters[condition] = data;

    if (
      String(this.tempFilters.customerName) !==
      String(this.filters.customerName)
    ) {
      this.filters.projectName = "default";
      this.tempFilters = { ...this.filters };
    }

    const tempData = Object.keys(this.filters).reduce((prev, cur, idx, arr) => {
      if (this.filters[cur] === "default") {
        return prev;
      }
      return prev.filter((d) => {
        return String(d[cur]) === this.filters[cur];
      });
    }, this.jobs);
    if (this.projects && this.projects.length > 0) {
      if (String(this.filters.customerName) !== "default") {
        this.filteredProjects = this.projects.filter((p) => {
          return String(p.customerName) === String(this.filters.customerName);
        });
      } else if (this.filters.customerName === "default") {
        this.filteredProjects = this.projects;
      }
    }
    if (this.sprints && this.sprints.length > 0) {
      if (String(this.filters.customerName) !== "default") {
        this.filteredSprints = this.sprints.filter((s) => {
          return String(s.customerName) === String(this.filters.customerName);
        });
      } else if (this.filters.customerName === "default") {
        this.filteredSprints = this.sprints;
      }
    }
    this.filteredData = this.convertToPrimeNgTreeData(tempData);
  }

  convertToPrimeNgTreeData(data) {
    return data.map((item) => {
      const node: any = {
        data: {
          id: item.id,
          code: item.code,
          projectName: item.projectName,
          jobStatusName: item.jobStatusName,
          jobTypeName: item.jobTypeName,
          name: item.name,
          details: item.details,
          assignedUserName: item.assignedUserName,
          sprintName: item.sprintName,
          customerName: item.customerName,
          estimatedDay: item.estimatedDay,
          billedDay: item.billedDay,
          isBilled: item.isBilled,
          testDate: item.testDate,
          prodDate: item.prodDate,
        },
        children: [],
      };

      if (item.child && item.child.length > 0) {
        node.children = this.convertToPrimeNgTreeData(item.child);
      }

      return node;
    });
  }

  closeResult: any;
  openModal(content, id) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
        centered: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.deleteJob(id);
          this.updateTable();
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}

export type JobType =
  | "jobTypeName"
  | "jobStatusName"
  | "sprint"
  | "projectName"
  | "assignedUserName"
  | "isBilled"
  | "reset";
