import { Component, OnInit, ViewChild } from "@angular/core";
import { AddJobApprovalTypeComponent } from "../add-job-approval-type/add-job-approval-type.component";
import { JobApprovalTypesService } from "src/app/shared/services/job-approval-types.service";
import { EditJobApprovalTypeComponent } from "../edit-job-approval-type/edit-job-approval-type.component";
import { DeleteJobApprovalTypeComponent } from "../delete-job-approval-type/delete-job-approval-type.component";

@Component({
  selector: "app-job-approval-type",
  templateUrl: "./job-approval-type.component.html",
  styleUrls: ["./job-approval-type.component.scss"],
})
export class JobApprovalTypeComponent implements OnInit {
  @ViewChild("addJobApprovalTypes") AddJobApprovalTypes: AddJobApprovalTypeComponent;
  @ViewChild("editJobApprovalTypes") EditJobApprovalTypes: EditJobApprovalTypeComponent;
  @ViewChild("deleteJobApprovalTypes") DeleteJobApprovalTypes: DeleteJobApprovalTypeComponent;

  public outputID: number;
  public jobApprovalTypes: any;
  public columns = [
    { prop: "id", name: "Numara" },
    { prop: "name", name: "İsim" },
  ];
  public dynamicGlobalFilterFields: any;

  constructor(private jobApprovalTypeServ: JobApprovalTypesService) {}

  async ngOnInit() {
    this.dynamicGlobalFilterFields = this.columns.map((col) => col.prop);

    await this.jobApprovalTypeServ.getAllJobApprovalTypes().then((resp) => {
      this.jobApprovalTypes = resp;
    });
  }

  async updateTable() {
    await this.jobApprovalTypeServ.getAllJobApprovalTypes().then((resp) => {
      this.jobApprovalTypes = resp;
    });
  }
}
