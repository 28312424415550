<div class="card-header">
  <h5>Planlama</h5>
  <div *ngIf="!isPlanEditable">
    <button
      *ngIf="user.data.id === jobObj.assignedUserId || user.data.claimId > 4"
      class="btn btn-info addButton"
      type="button"
      (click)="planningClick(true)"
    >
      Düzenle
    </button>
  </div>
  <div
    *ngIf="isPlanEditable"
    style="display: grid; grid-template-columns: auto auto auto"
  >
    <button
      *ngIf="user.data.id === jobObj.assignedUserId || user.data.claimId > 4"
      class="btn btn-success mr-2"
      (click)="planningClick(false); savePlan()"
    >
      Kaydet
    </button>
    <hr />
    <button
      class="btn btn-danger"
      (click)="planningClick(false)"
      *ngIf="user.data.id === jobObj.assignedUserId || user.data.claimId > 4"
    >
      İptal
    </button>
  </div>
</div>
<div class="card-body">
  <div class="card">
    <div class="card-body">
      <div class="row jobDetail">
        <div class="col-sm-7">
          <strong> Başlama Tarihi </strong>
          <br />
          {{ jobDto?.startDate }}
          <p *ngIf="jobDto.startDate == null" style="color: red">
            Henüz işe başlangıç tarihi girilmemiş
          </p>
        </div>
        <div
          class="col-sm-5 form-group text-right"
          *ngIf="
            (user.data.id === jobObj.assignedUserId || user.data.claimId > 4) &&
            jobDto.statusID === 7
          "
        >
          <button mat-flat-button color="accent" (click)="startJobHandler()">
            Başla
          </button>
        </div>
      </div>

      <div class="row jobDetail">
        <div class="col-sm-7">
          <strong> Bitiş Tarihi </strong>
          <br />
          {{ jobDto?.endDate }}
          <p *ngIf="jobDto.endDate == null" style="color: red">
            Henüz iş geliştirme aşamasında, işi bitirme tarihi girilmemiş
          </p>
        </div>
        <div
          class="col-sm-5 form-group text-right"
          *ngIf="
            (user.data.id === jobObj.assignedUserId || user.data.claimId > 4) &&
            jobDto.statusID === 8
          "
        >
          <button mat-flat-button color="accent" (click)="finishJobHandler()">
            Bitir
          </button>
        </div>
      </div>

      <div class="row jobDetail">
        <div class="col-sm-7">
          <strong> Test Tarihi </strong>
          <br />
          {{ jobDto?.testDate }}
          <p *ngIf="jobDto.testDate == null" style="color: red">
            Henüz test tarihi girilmemiş
          </p>
        </div>
        <div
          class="col-sm-5 form-group text-right"
          *ngIf="
            (user.data.id === jobObj.assignedUserId || user.data.claimId > 4) &&
            jobDto.statusID === 9
          "
        >
          <button
            mat-flat-button
            color="accent"
            [disabled]="!jobTestForm.valid"
            (click)="testJobHandler()"
          >
            Teste Gönder
          </button>
        </div>
        <div class="col-12">
          <h6>Test Detayları</h6>
          <form [formGroup]="jobTestForm">
            <div class="">
              <textarea
                *ngIf="
                  (user.data.id === jobObj.assignedUserId ||
                    user.data.claimId > 4) &&
                  jobDto.statusID === 9
                "
                formControlName="testDetail"
                type="text"
                class="form-control input-txt-bx"
                placeholder="Bir yorum yazın..."
                style="min-height: 4rem !important"
              ></textarea>
            </div>
          </form>

          <div class="mt-2">
            <h6 *ngIf="jobDto.statusID === 9">Önceki Test Detayları:</h6>
            <div style="white-space: pre-wrap">{{ jobDto?.testDetail }}</div>
          </div>
        </div>
      </div>

      <div class="row jobDetail">
        <div class="col-sm-7">
          <strong> Yayınlanma Tarihi </strong>
          <br />
          {{ jobDto?.prodDate }}
          <p *ngIf="jobDto.prodDate === null" style="color: red">
            Henüz yayınlanma tarihi girilmemiş
          </p>
        </div>
        <div
          class="col-sm-5 form-group text-right"
          *ngIf="
            (user.data.id === jobObj.assignedUserId || user.data.claimId > 4) &&
            jobDto.statusID === 11
          "
        >
          <button mat-flat-button color="accent" (click)="prodJobHandler()">
            Yayınla
          </button>
        </div>
      </div>

      <div class="row jobDetail">
        <div class="col-sm-7">
          <strong>Sprint </strong>
          <br />

          {{ sprint?.name }}
        </div>
        <div class="col-sm-5 form-group">
          <select
            class="form-control"
            [disabled]="!isPlanEditable"
            *ngIf="
              user.data.id === jobObj.assignedUserId || user.data.claimId > 4
            "
            [(ngModel)]="jobObj.sprint"
          >
            <option value="-1" default selected hidden>
              {{ sprint?.name }}
            </option>
            <option [ngValue]="nullValue">Sprint Seçiniz</option>
            <option [value]="item.id" *ngFor="let item of sprints">
              {{ item?.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row jobDetail">
        <div class="col-sm-7">
          <strong> Sprint Başlama - Bitiş Tarihi </strong>
          <br />
          {{ sprint?.startDate }}-{{ sprint?.endDate }}
        </div>
      </div>

      <div class="" *ngIf="user.data.claimId !== 4">
        <div class="row jobDetail">
          <div class="col-lg-4">
            <strong>Tahmini Gün</strong>
            <br />
            {{ jobDto?.estimatedDay }}
            <br />
            <input
              *ngIf="user.data.claimId > 4"
              type="number"
              class="form-control"
              [(ngModel)]="jobObj.estimatedDay"
              placeholder="Tahmini Gün"
              [disabled]="!isPlanEditable"
            />
          </div>
          <div class="col-lg-4">
            <strong>Gerçekleşen Gün</strong>
            <br />
            {{ jobDto?.actualDay }}
            <br />
            <input
              *ngIf="user.data.claimId > 4"
              type="number"
              class="form-control"
              [(ngModel)]="jobObj.actualDay"
              placeholder="Gerçekleşen Gün"
              [disabled]="!isPlanEditable"
            />
          </div>
          <div class="col-lg-4">
            <strong>Fatura Edilen Gün</strong>
            <br />
            {{ jobDto?.billedDay }}
            <br />
            <input
              *ngIf="user.data.claimId > 4"
              type="number"
              class="form-control"
              [(ngModel)]="jobObj.billedDay"
              placeholder="Fatura Edilen Gün"
              [disabled]="!isPlanEditable"
            />
          </div>
        </div>
        <div class="row jobDetail" *ngFor="let taskType of selectedTaskTypes">
          <div>
            <p class="text-black-50">
              <strong>{{ dayDetails[taskType] }} : </strong>
              {{ taskData[taskType] }} gün
            </p>
          </div>
        </div>
      </div>

      <!--
       #region Tahmin Edilen Gün Detayı
      -->

      <div class="form-group" *ngIf="user.data.claimId > 4">
        <mat-form-field>
          <mat-label>Tahmini Gün Türleri</mat-label>
          <mat-select
            [(ngModel)]="selectedTaskTypes"
            (selectionChange)="onTaskTypeChange()"
            multiple
            [disabled]="!isPlanEditable"
          >
            <mat-select-trigger>
              {{
                selectedTaskTypes.length > 0
                  ? selectedTaskTypes.length + " seçili"
                  : "Tarih Türü Seçin"
              }}
            </mat-select-trigger>
            <mat-option *ngFor="let taskType of taskTypes" [value]="taskType">{{
              dayDetails[taskType]
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngFor="let taskType of selectedTaskTypes">
          <mat-form-field>
            <mat-label>{{ dayDetails[taskType] }}</mat-label>
            <input
              matInput
              [(ngModel)]="taskData[taskType]"
              (input)="onTaskContentChange(taskType)"
              [disabled]="!isPlanEditable"
            />
          </mat-form-field>
        </div>

        <div *ngIf="isPlanEditable">
          <button
            *ngIf="
              user.data.id === jobObj.assignedUserId || user.data.claimId > 4
            "
            class="btn btn-success"
            (click)="saveEffortDetail()"
          >
            Günleri Kaydet
          </button>
        </div>
      </div>

      <!--
        #endregion
      -->
    </div>
  </div>
</div>
