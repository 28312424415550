<ng-template #editLogs let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Log Düzenle</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""
            (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="form-bookmark needs-validation" id="bookmark-form" novalidate="" [formGroup]="editLogForm">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="bm-id">ID</label>
                    <input class="form-control" formControlName="id" id="bm-id" type="text" required=""
                        autocomplete="off">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="bm-detail">Detaylar</label>
                    <textarea class="form-control" id="bm-detail" formControlName="detail" type="text" required=""
                        autocomplete="off"></textarea>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="bm-date">Tarih</label>
                    <input class="form-control" id="bm-date" formControlName="date" type="text" required=""
                        autocomplete="off">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="bm-audit">Log Tipi</label>
                    <input class="form-control" id="bm-audit" formControlName="audit" type="text" required=""
                        autocomplete="off">
                </div>
            </div>
            <input id="index_var" type="hidden" value="6">
            <button class="btn btn-success mr-1" id="Bookmark" [disabled]="this.editLogForm.invalid"
                (click)="saveLog()">Kaydet</button>
            <button class="btn btn-danger" type="button" data-dismiss="modal" (click)="closeModal()">
                İptal
            </button>
        </form>
    </div>
</ng-template>