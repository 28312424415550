import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LogsService } from "src/app/shared/services/logs.service";

@Component({
  selector: "app-edit-log",
  templateUrl: "./edit-log.component.html",
  styleUrls: ["./edit-log.component.scss"],
})
export class EditLogComponent implements OnInit {
  @ViewChild("editLogs", { static: false }) editLogs: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  public editLogForm: FormGroup;
  public logObj: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private logServ: LogsService
  ) {
    this.editLogForm = this.fb.group({
      id: [{ value: "", disabled: true }, [Validators.required]],
      detail: ["", [Validators.required]],
      date: ["", [Validators.required]],
      audit: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {}

  openModal(id: number) {
    if (isPlatformBrowser(this.platformId)) {
      this.logServ.getById(id).then((resp) => {
        this.logObj = resp;
        this.editLogForm.get("id").setValue(this.logObj.id);
        this.editLogForm.get("detail").setValue(this.logObj.detail);
        this.editLogForm.get("date").setValue(this.logObj.date);
        this.editLogForm.get("audit").setValue(this.logObj.audit);
      });
      // For SSR
      this.modalService
        .open(this.editLogs, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.editLogForm.reset();
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  saveLog() {
    this.logServ
      .updateLogs({
        id: this.logObj.id,
        detail: this.editLogForm.value.detail,
        date: this.editLogForm.value.date,
        audit: this.editLogForm.value.audit,
      })
      .then((a) => {
        this.editLogForm.reset();
        this.modalService.dismissAll();
        this.onSave.emit();
      });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
