import { Injectable } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {}

  horizontalPosition: MatSnackBarHorizontalPosition = "end";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  openSnackBar(bool: boolean) {
    let msg;
    if (bool === true) {
      msg = "İşlem başarıyla gerçekleşti!";
    } else if (bool === false) {
      msg = "İşlem başarısız!";
    }
    this._snackBar.open(msg, "x", {
      duration: 1500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ["snackbar-z-index"],
    });
  }

  openSnackBarWithMessageSuccess(msg: string) {
    this._snackBar.open(msg, "x", {
      duration: 1500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ["snackbar-z-index"],
    });
  }

  openSnackBarWithMessageError(msg: string) {
    this._snackBar.open(msg, "x", {
      duration: 1500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ["snackbar-z-index", "snackbar-error"],
    });
  }
}
