import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DeleteFileComponent } from "../delete-file/delete-file.component";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JobFilesService } from "src/app/shared/services/job-files.service";
import { ActivatedRoute } from "@angular/router";
import { HttpClientService } from "src/app/shared/services/http-client.service";

@Component({
  selector: "app-job-files",
  templateUrl: "./job-files.component.html",
  styleUrls: ["./job-files.component.css"],
})
export class JobFilesComponent implements OnInit {
  public jobFiles: any;
  @ViewChild("deleteFile") DeleteFile: DeleteFileComponent;

  @Input() user: any;
  @Input() jobID: number;

  constructor(
    private jobFilesService: JobFilesService,
    private snackBarService: SnackbarService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private http: HttpClientService
  ) {}

  async ngOnInit() {
    await this.jobFilesService.getListByDTOJobID(this.jobID).then((resp) => {
      this.jobFiles = resp;
    });
  }

  files: File[] = [];
  fileChanged(e) {
    const files = e.addedFiles;
    for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);
    }
  }

  async uploadDocument() {
    for (let i = 0; i < this.files.length; i++) {
      var formData: any = new FormData();

      formData.append("files", this.files[i]);

      await this.jobFilesService
        .addJobFileMultiPartForm(this.jobID, this.user.data.id, formData)
        .then((res) => {
          this.snackBarService.openSnackBar(true);
          this.jobFilesService.getListByDTOJobID(this.jobID).then((resp) => {
            this.jobFiles = resp;
          });
        })
        .catch((err) => {
          console.warn(err);
          this.snackBarService.openSnackBar(false);
          this.jobFilesService.getListByDTOJobID(this.jobID).then((resp) => {
            this.jobFiles = resp;
          });
        });
    }

    await this.jobFilesService.getListByDTOJobID(this.jobID).then((resp) => {
      this.jobFiles = resp;
    });
    this.onRemove();
  }

  async download(id) {
    const file = await this.jobFilesService.getByDTOId(id);
    //console.log(file);
    const res = await fetch(
      `${this.http.baseUrl}/JobFiles/DownloadFile?jobFileID=${id}`
    );
    //console.log(res);

    this.downloadFile(file[0].name, res);
  }

  downloadFile(fileName, stream) {
    // Get the Blob object from the ReadableStream
    console.log("stream", stream);

    stream.blob().then((blob) => {
      // Create a link element
      const link = document.createElement("a");

      // Set the link's href to the URL of the Blob object
      link.href = URL.createObjectURL(blob);

      // Set the link's download attribute to the desired file name
      link.download = fileName;

      // Click the link element to download the file
      link.click();
    });
  }

  async deleteFile(id) {
    await this.jobFilesService
      .deleteJobFiles(id)
      .then((resp) => {
        this.snackBarService.openSnackBar(true);
        this.jobFilesService.getListByDTOJobID(this.jobID).then((resp) => {
          this.jobFiles = resp;
        });
      })
      .catch((err) => {
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });
  }

  onRemove() {
    this.files = [];
  }

  closeResult: any;
  openModal(content, id) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
        centered: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;

          this.deleteFile(id);
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
