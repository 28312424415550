import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobApprovalTypesService } from 'src/app/shared/services/job-approval-types.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-delete-job-approval-type',
  templateUrl: './delete-job-approval-type.component.html',
  styleUrls: ['./delete-job-approval-type.component.css']
})
export class DeleteJobApprovalTypeComponent implements OnInit {

  @ViewChild("areyousure", { static: false })
  deleteJobApprovalTypeTemplate: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  public jobApprovalTypeObj: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private jobApprovalTypeServ: JobApprovalTypesService,
    private snackBarService: SnackbarService
  ) {}

  ngOnInit() {
  }

  openModal(id: number) {
    if (isPlatformBrowser(this.platformId)) {
      this.jobApprovalTypeServ.getById(id).then((resp) => {
        this.jobApprovalTypeObj = resp;
      });
      // For SSR
      this.modalService
        .open(this.deleteJobApprovalTypeTemplate, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  async deleteJobApprovalType() {
    await this.jobApprovalTypeServ
      .deleteJobApprovalTypes(this.jobApprovalTypeObj.id)
      .then((a) => {
        this.modalService.dismissAll();
        this.onSave.emit();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });
  }
  closeModal() {
    this.modalService.dismissAll();
  }

}
