import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { JobQAsService } from "src/app/shared/services/job-qas.service";
import { JobStatusesService } from "src/app/shared/services/job-statuses.service";
import { JobsService } from "src/app/shared/services/jobs.service";
import { JobApprovalTypesService } from "src/app/shared/services/job-approval-types.service";
import { JobApprovalCommentsService } from "src/app/shared/services/job-approval-comments.service";

import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-approves",
  templateUrl: "./approves.component.html",
  styleUrls: ["./approves.component.scss"],
})
export class ApprovesComponent implements OnInit {
  @Input() user: any;
  @Input() jobID: number;
  @Output() getEmit: EventEmitter<void> = new EventEmitter<void>();
  @Input() jobDto: any;
  @Input() jobObj: any; //This is for the update
  @Input() projectObj: any;

  public jobQAs: any;
  public jobStatuses: any;

  public isMessaggeBoxOpen: boolean = false;
  public isSendApprovement: boolean = false;

  public number: number;
  public status: number;

  public jobApprovalTypeId: number;

  public approveForm: FormGroup;

  public rejectionReasonList: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private jobServ: JobsService,
    private jobStatusService: JobStatusesService,
    private jobQAService: JobQAsService,
    private jobApprovalTypesService: JobApprovalTypesService,
    private jobApproveCommentsService: JobApprovalCommentsService,
    private snackBarService: SnackbarService
  ) {
    this.approveForm = this.fb.group({
      comment: ["", [Validators.required]],
    });
  }

  async ngOnInit() {
    console.log(this.jobDto);

    await this.jobQAService.getListByDTOJobID(this.jobID).then((resp) => {
      this.jobQAs = resp;
    });

    await this.jobStatusService.getAllJobStatuses().then((res) => {
      this.jobStatuses = res;
      console.log(res);
    });
    await this.jobApprovalTypesService.getAllJobApprovalTypes().then((res) => {
      console.log(res);
    });

    this.getRejectionListByJobId(this.jobID);

    this.approveForm = this.fb.group({
      comment: ["", [Validators.required]],
    });
  }

  async confirmationClick(number: number, status: number) {
    if (number === 0) this.isMessaggeBoxOpen = true;
    if (number === 1) this.isMessaggeBoxOpen = false;

    this.number = number;
    this.status = status;

    const idMap = {
      "0-2": 2, // İş Onayı - Red
      "0-4": 4, // Analiz Onayı - Red
      "0-6": 6, // Gün Onayı - Red
      "0-10": 8, // Test Onayı - Red
      "1-2": 1, // İş Onayı - Kabul
      "1-4": 3, // Analiz Onayı - Kabul
      "1-6": 5, // Gün Onayı - Kabul
      "1-10": 7, // Test Onayı - Kabul
    };

    this.jobApprovalTypeId = idMap[`${number}-${status}`];

    if (number === 1) {
      this.sendConfirmation();
    }
  }

  async sendRejection() {
    var isAnswered = true;
    await this.jobStatusService.getById(this.jobDto.statusID).then((resp) => {
      //console.log(resp);
      if (this.number === 1) {
        const clientDate = new Date();
        const timezoneOffsetInMinutes = clientDate.getTimezoneOffset();
        const clientDateWithTimezone = new Date(
          clientDate.getTime() - timezoneOffsetInMinutes * 60 * 1000
        ).toISOString();
        // clientDateWithTimezone'i back-end'e gönder

        this.jobObj = {
          ...this.jobObj,
          jobStatusId: resp.nextStatus,
        };
        if (this.status === 2) {
          this.jobObj = {
            ...this.jobObj,
            managerApproveDate: clientDateWithTimezone,
            managerUserId: this.user.data.id,
          };
        } else if (this.status === 4) {
          this.jobQAs.forEach((element) => {
            if (element.answer === null) {
              this.snackBarService.openSnackBarWithMessageError(
                "Hata, Lütfen tüm soruları cevaplayınız!"
              );
              this.jobObj = {
                ...this.jobObj,
                jobStatusId: 4,
              };
              isAnswered = false;
              return;
            }
          });
          this.jobObj = {
            ...this.jobObj,
            analysisApproveDate: clientDateWithTimezone,
            analysisApproverUserId: this.user.data.id,
          };
        } else if (this.status === 6) {
          this.jobObj = {
            ...this.jobObj,
            dayApproveDate: clientDateWithTimezone,
            dayApproverUserId: this.user.data.id,
          };
        }
      } else if (this.number === 0) {
        this.jobObj = {
          ...this.jobObj,
          jobStatusId: resp.previousStatus,
        };
        if (this.status === 2) {
          this.jobObj = {
            ...this.jobObj,
            managerApproveDate: null,
            managerUserId: null,
          };
        } else if (
          this.status === 4 &&
          (this.jobObj.analysisApproverUserId > 0 ||
            this.jobObj.analysisApproverUserId !== null)
        ) {
          this.jobObj = {
            ...this.jobObj,
            analysisApproveDate: null,
            analysisApproverUserId: null,
          };
        } else if (this.status === 6) {
          this.jobObj = {
            ...this.jobObj,
            dayApproveDate: null,
            estimatedDay: null,
            dayApproverUserId: null,
          };
        } else if (this.status === 10) {
          this.jobObj = {
            ...this.jobObj,
            endDate: null,
            testDate: null,
            prodDate: null,
          };
        }
      }
    });

    console.log(this.jobObj);

    if (isAnswered) {
      await this.jobServ
        .updateJobs(this.jobObj)
        .then((res) => {
          this.snackBarService.openSnackBar(true);

          const jobApproveCommentData = {
            jobId: this.jobID,
            userId: this.user.data.id,
            date: new Date(),
            comment: this.approveForm.value.comment,
            jobApprovalTypeId: this.jobApprovalTypeId,
          };

          this.jobApproveCommentsService
            .addJobApprovalComments(jobApproveCommentData)
            .then((res) => {
              this.isMessaggeBoxOpen = false;
            });

          this.getEmit.emit();
          this.updatePage();
        })
        .catch((err) => {
          this.snackBarService.openSnackBar(false);
        });
    }
  }

  async sendConfirmation() {
    var isAnswered = true;
    await this.jobStatusService.getById(this.jobDto.statusID).then((resp) => {
      //console.log(resp);
      if (this.number === 1) {
        const clientDate = new Date();
        const timezoneOffsetInMinutes = clientDate.getTimezoneOffset();
        const clientDateWithTimezone = new Date(
          clientDate.getTime() - timezoneOffsetInMinutes * 60 * 1000
        ).toISOString();
        // clientDateWithTimezone'i back-end'e gönder

        this.jobObj = {
          ...this.jobObj,
          jobStatusId: resp.nextStatus,
        };
        if (this.status === 2) {
          this.jobObj = {
            ...this.jobObj,
            managerApproveDate: clientDateWithTimezone,
            managerUserId: this.user.data.id,
          };
        } else if (this.status === 4) {
          this.jobQAs.forEach((element) => {
            if (element.answer === null) {
              this.snackBarService.openSnackBarWithMessageError(
                "Hata, Lütfen tüm soruları cevaplayınız!"
              );
              this.jobObj = {
                ...this.jobObj,
                jobStatusId: 4,
              };
              isAnswered = false;
              return;
            }
          });
          this.jobObj = {
            ...this.jobObj,
            analysisApproveDate: clientDateWithTimezone,
            analysisApproverUserId: this.user.data.id,
          };
        } else if (this.status === 6) {
          this.jobObj = {
            ...this.jobObj,
            dayApproveDate: clientDateWithTimezone,
            dayApproverUserId: this.user.data.id,
          };
        }
      } else if (this.number === 0) {
        this.jobObj = {
          ...this.jobObj,
          jobStatusId: resp.previousStatus,
        };
        if (this.status === 2) {
          this.jobObj = {
            ...this.jobObj,
            managerApproveDate: null,
            managerUserId: null,
          };
        } else if (
          this.status === 4 &&
          (this.jobObj.analysisApproverUserId > 0 ||
            this.jobObj.analysisApproverUserId !== null)
        ) {
          this.jobObj = {
            ...this.jobObj,
            analysisApproveDate: null,
            analysisApproverUserId: null,
          };
        } else if (this.status === 6) {
          this.jobObj = {
            ...this.jobObj,
            dayApproveDate: null,
            estimatedDay: null,
            dayApproverUserId: null,
          };
        } else if (this.status === 10) {
          this.jobObj = {
            ...this.jobObj,
            endDate: null,
            testDate: null,
            prodDate: null,
          };
        }
      }
    });

    console.log(this.jobObj);

    if (isAnswered) {
      await this.jobServ
        .updateJobs(this.jobObj)
        .then((res) => {
          this.snackBarService.openSnackBar(true);
          this.updatePage();
          this.getEmit.emit();
        })
        .catch((err) => {
          this.snackBarService.openSnackBar(false);
        });
    }
  }

  async getRejectionListByJobId(id: number) {
    await this.jobApproveCommentsService
      .getListByDTOJobID(id)
      .then((response) => {
        this.rejectionReasonList = response;
        if (this.user.data.claimId === 4) {
          this.rejectionReasonList = this.rejectionReasonList.filter(
            (item) => item.jobApprovalTypeId !== 6
          );
         
        }

      });
  }

  async updatePage() {
    await this.jobServ.getById(this.jobID).then((res) => {
      this.jobObj = res;
    });
    await this.jobServ.getByDTOId(this.jobID).then((res) => {
      this.jobDto = res;
    });

    await this.jobQAService.getListByDTOJobID(this.jobID).then((resp) => {
      this.jobQAs = resp;
    });
    this.getRejectionListByJobId(this.jobID);
  }

  async deleteJob() {
    await this.jobServ.deleteJobs(this.jobID).then((resp) => {
      this.router.navigate(["/projects/project-detail"]);
    });
  }
}
