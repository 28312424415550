import { Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";

@Injectable({
  providedIn: "root",
})
export class JobTasksService {
  constructor(private http: HttpClientService) {}

  async getById(jobTaskID: Number) {
    return await this.http
      .httpGet("/JobTasks/getbyid?jobTaskID=" + jobTaskID)
      .then((res) => {
        return res.data;
      });
  }

  async getByDTOId(jobTaskID: Number) {
    return await this.http
      .httpGet("/JobTasks/getbydtoid?jobTaskID=" + jobTaskID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobTasks/getlistbyjobid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByDTOJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobTasks/getlistbydtojobid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }

  async addJobTasks(data) {
    return await this.http.httpPost("/JobTasks/add", data);
  }
  async deleteJobTasks(data) {
    return await this.http.httpDelete("/JobTasks/delete", data);
  }
  async updateJobTasks(data) {
    return await this.http.httpPut("/JobTasks/update", data);
  }
}
