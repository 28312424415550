import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JobTasksService } from "src/app/shared/services/job-tasks.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

import * as CryptoJS from "crypto-js";

@Component({
  selector: "app-task-list",
  templateUrl: "./task-list.component.html",
  styleUrls: ["./task-list.component.css"],
})
export class TaskListComponent implements OnInit {
  public tasks: any = [];
  @Input() user: any;
  @Input() jobID: number;

  public taskColumns = [
    { prop: "assignedUserName", name: "Atanan Kullanıcı", colspan: 2 },
    { prop: "details", name: "Açıklama", colspan: 1 },
    { prop: "estimatedHour", name: "Tahmini Saat", colspan: 1 },
    { prop: "estimatedDay", name: "Tahmini Gün", colspan: 1 },
    // { prop: "actualHour", name: "Asıl Saat", colspan: 1 },
    // { prop: "actualDay", name: "Asıl Gün", colspan: 1 },
    { prop: "startDate", name: "Başlangıç Tarihi", colspan: 2 },
  ];
  public dynamicGlobalFilterFields: any;

  constructor(
    private taskServ: JobTasksService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private snackBarService: SnackbarService,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.dynamicGlobalFilterFields = this.taskColumns.map((col) => col.prop);

    await this.taskServ.getListByDTOJobID(this.jobID).then((resp) => {
      this.tasks = resp.map((task) => {
        return {
          ...task,
          startDate: this.datePipe.transform(task.startDate, "HH.mm"),
          endDate: this.datePipe.transform(task.endDate, "dd.MM.yyyy HH:mm"),
        };
      });
      console.log(this.tasks);
    });
  }

  async startTask(id) {
    const clientDate = new Date();
    const timezoneOffsetInMinutes = clientDate.getTimezoneOffset();
    const clientDateWithTimezone = new Date(
      clientDate.getTime() - timezoneOffsetInMinutes * 60 * 1000
    ).toISOString();
    // clientDateWithTimezone'i back-end'e gönder
    await this.taskServ.getById(id).then((res) => {
      const taskObj = res;
      taskObj.startDate = clientDateWithTimezone;

      this.taskServ
        .updateJobTasks(taskObj)
        .then((res) => {
          this.snackBarService.openSnackBar(true);
          this.taskServ.getListByDTOJobID(this.jobID).then((resp) => {
            this.tasks = resp.map((task) => {
              return {
                ...task,
                startDate: this.datePipe.transform(
                  task.startDate,
                  "dd.MM.yyyy hh:mm"
                ),
                endDate: this.datePipe.transform(
                  task.endDate,
                  "dd.MM.yyyy hh:mm"
                ),
              };
            });
          });
        })
        .catch((err) => {
          this.snackBarService.openSnackBar(false);
        });
    });
  }
  async finishTask(id) {
    const clientDate = new Date();
    const timezoneOffsetInMinutes = clientDate.getTimezoneOffset();
    const clientDateWithTimezone = new Date(
      clientDate.getTime() - timezoneOffsetInMinutes * 60 * 1000
    ).toISOString();
    // clientDateWithTimezone'i back-end'e gönder
    await this.taskServ.getById(id).then((res) => {
      const taskObj = res;
      taskObj.endDate = clientDateWithTimezone;

      // İki tarih arasındaki farkı alın
      const timeDifferenceInMilliseconds = taskObj.endDate - taskObj.startDate;
      const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000;
      const timeDifferenceInMinutes = timeDifferenceInSeconds / 60;
      const timeDifferenceInHours = timeDifferenceInMinutes / 60;
      const timeDifferenceInDays = timeDifferenceInHours / 24;

      // 8 saatlik zaman dilimini 1 gün olarak kabul ederek farkı hesaplayın
      const timeDifferenceInDaysWithOffset =
        timeDifferenceInHours / 24 - 8 / 24;

      // Sonuçları kullanarak farkı gün ve saat cinsinden gösterin
      const days = Math.floor(timeDifferenceInDaysWithOffset);
      const hours = (timeDifferenceInDaysWithOffset - days) * 24;

      taskObj.actualHour = hours;
      taskObj.actualDay = days;

      this.taskServ
        .updateJobTasks(taskObj)
        .then((res) => {
          this.snackBarService.openSnackBar(true);
          this.taskServ.getListByDTOJobID(this.jobID).then((resp) => {
            this.tasks = resp.map((task) => {
              return {
                ...task,
                startDate: this.datePipe.transform(
                  task.startDate,
                  "dd.MM.yyyy HH:mm"
                ),
                endDate: this.datePipe.transform(
                  task.endDate,
                  "dd.MM.yyyy HH:mm"
                ),
              };
            });
          });
        })
        .catch((err) => {
          this.snackBarService.openSnackBar(false);
        });
    });
  }

  async getJobTasks() {
    await this.taskServ.getListByDTOJobID(this.jobID).then((resp) => {
      this.tasks = resp.map((task) => {
        return {
          ...task,
          startDate: this.datePipe.transform(
            task.startDate,
            "dd.MM.yyyy hh:mm"
          ),
          endDate: this.datePipe.transform(task.endDate, "dd.MM.yyyy hh:mm"),
        };
      });
    });
  }
}
