<ng-template #addJobTypes let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">İş Tipi Ekle</h5>
    <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""
      (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <form class="form-bookmark needs-validation" id="bookmark-form" novalidate="" [formGroup]="addJobTypeForm">
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-name">İsim</label>
          <input class="form-control" id="bm-name" formControlName="name" type="text" required="" autocomplete="off">
        </div>
        <div class="form-group col-md-12">
          <label for="bm-desc">Detay</label>
          <textarea formControlName="details" class="form-control" id="bm-desc" required=""
            autocomplete="off"></textarea>
        </div>
      </div>
      <input id="index_var" type="hidden" value="6">
      <button class="btn btn-success mr-1" id="Bookmark" [disabled]="this.addJobTypeForm.invalid"
        (click)="saveJobType()">Kaydet</button>
      <button class="btn btn-danger" type="button" data-dismiss="modal" (click)="closeModal()">
        İptal
      </button>
    </form>
  </div>
</ng-template>