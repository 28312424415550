import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/shared/services/auth.service";
import { UserService } from "src/app/shared/services/user.service";
import * as CryptoJS from "crypto-js";
import { ClaimService } from "src/app/shared/services/claim.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  user: any;
  claimName: string;
  constructor(
    public userServ: UserService,
    private router: Router,
    private authService: AuthService,
    private claimService: ClaimService
  ) {}

  async ngOnInit() {
    //this.user = this.authService.decyptedUser;
    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);
    await this.claimService.getById(this.user.data.claimId).then((res) => {
      this.claimName = res.name;
    });
  }
  profileClick() {
    this.router.navigateByUrl("/profile");
  }
  logout() {
    sessionStorage.removeItem("user");
    this.userServ.user = null;
    this.router.navigateByUrl("/auth/login");

    localStorage.removeItem("projectDetailFilters");
    localStorage.removeItem("sprintDetailFilters");
    localStorage.removeItem("selectedSprint");
    localStorage.removeItem("selectedIsActive");
    localStorage.removeItem("selectedCustomer");
  }
}
