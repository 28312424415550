import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { JobsService } from "src/app/shared/services/jobs.service";

import { JobPrioritiesService } from "src/app/shared/services/job-priorities.service";
import { JobTypesService } from "src/app/shared/services/job-types.service";

import { JobFollowersService } from "src/app/shared/services/job-followers.service";
import { UserService } from "src/app/shared/services/user.service";

import * as CryptoJS from "crypto-js";

import { ProjectsService } from "src/app/shared/services/projects.service";

import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-job",
  templateUrl: "./job.component.html",
  styleUrls: ["./job.component.scss"],
})
export class JobComponent implements OnInit {
  public jobID: any = 0;
  public projectID: any = 0;
  public priority: string;

  public jobDto: any;
  public jobObj: any; //This is for the update
  public user: any;
  public projectObj: any;

  public jobFollowers: any;
  public jobFollowersObj: any;

  public users: any;

  public isFollow: boolean;

  public priorities: any;
  public jobTypes: any;

  constructor(
    private projectService: ProjectsService,
    private jobServ: JobsService,

    private router: Router,
    private route: ActivatedRoute,

    private priorityService: JobPrioritiesService,
    private jobTypeService: JobTypesService,

    private jobFollowersService: JobFollowersService,

    private userService: UserService,

    private snackBarService: SnackbarService
  ) {}

  async ngOnInit() {
    // child iş olup olmadığını anlamak için
    var routeParam = false;
    this.route.queryParams.subscribe((params) => {
      routeParam = params["child"]; // Belirli bir sorgu parametresini alır
    });

    if (routeParam) {
      this.jobID = localStorage.getItem("childJobId");
    } else {
      this.jobID = localStorage.getItem("jobId");
    }

    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);

    await this.jobServ.getById(this.jobID).then((res) => {
      this.jobObj = res;
      this.projectID = this.jobObj.projectId;

      // if (this.jobObj.effortDetail !== null) {
      //   this.taskData = JSON.parse(this.jobObj.effortDetail);
      //   console.log(this.taskData);

      //   this.selectedTaskTypes = Object.keys(this.taskData);
      // }

      localStorage.setItem("projectId", this.projectID.toString());

      this.projectService.getById(this.projectID).then((res) => {
        this.projectObj = res;

        this.userService
          .getByDTOCustomerId(this.projectObj.customerId)
          .then((res) => {
            this.users = res;
            if (this.projectObj.customerId != 1) {
              this.userService.getByDTOCustomerId(1).then((res) => {
                this.users = this.users.concat(res);
              });
            }
          });
      });
    });

    await this.jobTypeService.getAllJobTypes().then((resp) => {
      this.jobTypes = resp;
    });

    await this.jobFollowersService.getListByDTOJobID(this.jobID).then((res) => {
      this.jobFollowers = res;
    });
    await this.jobFollowersService.getListByJobID(this.jobID).then((res) => {
      this.jobFollowersObj = res;
      const filtered = this.jobFollowersObj.filter(
        (element) => element.userId == this.user.data.id
      );
      //console.log("filtered: ", filtered);

      if (filtered.length === 0) {
        this.isFollow = false;
      } else {
        this.isFollow = true;
      }
    });

    await this.priorityService.getAllJobPriorities().then((resp) => {
      this.priorities = resp;
    });

    await this.getEmit();
  }

  getEmit() {
    this.updatePage();
  }

  async updatePage() {
    await this.jobServ.getById(this.jobID).then((res) => {
      this.jobObj = res;
    });
    await this.jobServ.getByDTOId(this.jobID).then((res) => {
      this.jobDto = res;

      this.priority = this.jobDto.jobPriorityName;
    });
  }

  onClickEdit() {
    var routeParam = false;
    this.route.queryParams.subscribe((params) => {
      routeParam = params["child"]; // Belirli bir sorgu parametresini alır
    });

    if (routeParam) {
      this.router.navigateByUrl("/jobs/edit-job?child=true");
    } else {
      this.router.navigateByUrl("/jobs/edit-job");
    }
  }

  public addJobFollower: any;
  followSelect: any = -1;
  async followJob(id: number) {
    this.addJobFollower = {
      jobId: this.jobID,
      userId: id,
    };

    await this.jobFollowersService
      .addJobFollowers(this.addJobFollower)
      .then((resp) => {
        this.snackBarService.openSnackBar(true);

        this.jobFollowersService.getListByDTOJobID(this.jobID).then((res) => {
          this.jobFollowers = res;
        });
        this.jobFollowersService.getListByJobID(this.jobID).then((res) => {
          this.jobFollowersObj = res;
          const filtered = this.jobFollowersObj.filter(
            (element) => element.userId == this.user.data.id
          );
          //console.log("filtered: ", filtered);

          if (filtered.length === 0) {
            this.isFollow = false;
          } else {
            this.isFollow = true;
          }
        });
        this.followSelect = -1;
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }

  async unFollowJob(event) {
    await this.jobFollowersService
      .deleteJobFollowers(event)
      .then((res) => {
        this.snackBarService.openSnackBar(true);

        this.jobFollowersService.getListByDTOJobID(this.jobID).then((res) => {
          this.jobFollowers = res;
        });
        this.jobFollowersService.getListByJobID(this.jobID).then((res) => {
          this.jobFollowersObj = res;
          const filtered = this.jobFollowersObj.filter(
            (element) => element.userId == this.user.data.id
          );
          //console.log("filtered: ", filtered);

          if (filtered.length === 0) {
            this.isFollow = false;
          } else {
            this.isFollow = true;
          }
        });
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }

  async onChangeJobType(id) {
    this.jobObj = {
      ...this.jobObj,
      jobTypeId: Number(id),
    };

    await this.jobServ
      .updateJobs(this.jobObj)
      .then((resp) => {
        this.snackBarService.openSnackBar(true);
        this.getEmit();
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }
  async onChangeJobPriority(id) {
    this.jobObj = {
      ...this.jobObj,
      jobPriorityId: Number(id),
    };

    await this.jobServ
      .updateJobs(this.jobObj)
      .then((resp) => {
        this.snackBarService.openSnackBar(true);
        this.getEmit();
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }
}
