import { Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";

@Injectable({
  providedIn: "root",
})
export class ClaimService {
  constructor(private http: HttpClientService) {}

  async getAllClaims() {
    return await this.http.httpGet("/Claims/getall").then((res) => {
      return res.data;
    });
  }

  async getById(customerID: Number) {
    return await this.http
      .httpGet("/Claims/getbyid?claimId=" + customerID)
      .then((res) => {
        return res.data;
      });
  }
  async addClaims(data) {
    return await this.http.httpPost("/Claims/add", data);
  }
  async deleteClaims(data) {
    return await this.http.httpDelete("/Claims/delete", data);
  }
  async updateClaims(data) {
    return await this.http.httpPut("/Claims/update", data);
  }
}
