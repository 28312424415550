<div class="container-fluid p-0"> 
    <div class="row">
      <div class="col-12">     
        <div class="login-card">
          <div>
            <div>
                <a class="logo" routerLink='/auth/login'>
                    <img class="img-fluid for-light" src="https://monassist.com/img/Logo_Kirpilmis.png" alt="looginpage">
                    <img class="img-fluid for-dark" src="https://monassist.com/img/Logo_Kirpilmis.png" alt="looginpage">
                </a>
            </div>
            <div class="login-main"> 
              <form class="theme-form" [formGroup]="registerForm">
                <h4>Üye Ol</h4>
                <p>Hesap oluşturmak için kişisel bilgilerinizi giriniz</p>
                <div class="form-group">
                  <label class="col-form-label pt-0">İsim Soyisim</label>
                  <div class="form-row">
                    <div class="col-6">
                      <input class="form-control" formControlName="firstName" type="text" required="" placeholder="İsim" required>
                    </div>
                    
                    <div class="col-6">
                      <input class="form-control" formControlName="lastName" type="text" required="" placeholder="Soyisim" required>
                    </div>
                    <div *ngIf="registerForm.controls.firstName.touched && registerForm.controls.firstName.errors?.required"
                    class="text text-danger mt-1">
                    İsim girmek zorunludur.
                    </div>
                    <br>
                    <div *ngIf="registerForm.controls.lastName.touched && registerForm.controls.lastName.errors?.required"
                    class="text text-danger mt-1">
                    Soyisim girmek zorunludur.
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">E-mail Adresi</label>
                  <input class="form-control" formControlName="email" type="email" required="" placeholder="app@yourmail.com">
                    <div *ngIf="registerForm.controls.email.touched && registerForm.controls.email.errors?.required"
                    class="text text-danger mt-1">
                    Email girmek zorunludur.
                    </div>
                    <div *ngIf="registerForm.controls.email.touched && registerForm.controls.email.errors?.email"
                        class="text text-danger mt-1">
                        Lütfen geçerli bir email adresi giriniz.
                    </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label pt-0">Telefon</label>
                  <div class="form-row">
                    <div class="col-3">
                      <input class="form-control" id="areaCode" formControlName="areaCode" type="text" required="" placeholder="5**">
                    </div>
                    <div class="col-9">
                      <input class="form-control" formControlName="phone" type="text" required="" placeholder="***-**-**">
                      <div *ngIf="registerForm.controls.areaCode.touched && (registerForm.controls.areaCode.errors?.minlength || registerForm.controls.areaCode.errors?.maxlength)"
                      class="text text-danger mt-1">
                        Alan Kodu 3 karakterden oluşmalıdır.
                      </div>
                      <div *ngIf="registerForm.controls.phone.touched && (registerForm.controls.phone.errors?.minlength || registerForm.controls.phone.errors?.maxlength)"
                      class="text text-danger mt-1">
                        Lütfen girdiğiniz telefon numarasını kontrol ediniz.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Kullanıcı Adı</label>
                  <input class="form-control" formControlName="username" type="text" required="" placeholder="Kullanıcı Adınız">
                    <div *ngIf="registerForm.controls.username.touched && registerForm.controls.username.errors?.required"
                    class="text text-danger mt-1">
                    Kullanıcı adı girmek zorunludur.
                    </div>
                    <div *ngIf="registerForm.controls.username.touched && registerForm.controls.username.errors?.pattern"
                    class="text text-danger mt-1">
                      Lütfen sadece ingilizce karakterler kullanınız.
                    </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Firma</label>
                  <select class="form-control" formControlName="customerID" required>
                    <option value="" selected hidden>
                      Lütfen Firmanızı Seçiniz...
                    </option>
                    <option [value]="item.id" *ngFor="let item of this.customerList">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Şifre</label>
                  <input class="form-control" formControlName="password" [type]="show1 ? 'text' : 'password'" name="login[password]" required="" placeholder="*********">
                  <div class="show-hide" (click)="showPassword1()" *ngIf="!show1"><span class="show"></span></div>
                  <div class="show-hide" (click)="showPassword1()" *ngIf="show1"><span class="Hide"></span></div>
                  <div *ngIf="registerForm.controls.password.touched && registerForm.controls.password.errors?.required"
                  class="text text-danger mt-1">
                  Şifre girmek zorunludur.
                  </div>
                  <div *ngIf="registerForm.controls.password.touched && registerForm.controls.password.errors?.minlength"
                  class="text text-danger mt-1">
                  Şifreniz en az 8 karakterden oluşmalıdır.
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Tekrar Şifre</label>
                  <input class="form-control" formControlName="repassword" [type]="show2 ? 'text' : 'password'" name="login[password]" required="" placeholder="*********">
                  <div class="show-hide" (click)="showPassword2()" *ngIf="!show2"><span class="show"></span></div>
                  <div class="show-hide" (click)="showPassword2()" *ngIf="show2"><span class="Hide"></span></div>
                  <div *ngIf="registerForm.controls.repassword.touched && registerForm.controls.repassword.errors?.confirmedValidator"
                  class="text text-danger mt-1">
                  Girilen şifreler aynı olmalıdır.
                  </div>
                </div>

                <div class="form-group mb-0">
                  <div class="checkbox p-0">
                    <input id="checkbox1" formControlName="agreePrivacy" type="checkbox">
                    <label class="text-muted" for="checkbox1"><a class="ml-2" routerLink='/'>Gizlilik Sözleşmesini</a> Onaylıyorum</label>
                  </div>
                  <button (click)="register()" class="btn btn-primary btn-block" type="button" [disabled]="this.registerForm.invalid">Hesabı Oluştur</button>
                </div>
                <!-- <h6 class="text-muted mt-4 or">Ya da</h6>
                <div class="social mt-4">
                  <div class="btn-showcase" style="display: flex;flex-direction: column;justify-content: center;">
                    <a class="btn btn-light" style="margin-left:5px;" href="https://www.linkedin.com/login" target="_blank">
                        <app-feather-icons [icon]="'linkedin'" class="txt-linkedin"></app-feather-icons>Linkedin hesabınız ile kaydolun.
                       </a>
                    <a class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank">
                     <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>Twitter hesabınız ile kaydolun.
                    </a>
                    <a class="btn btn-light" href="https://www.facebook.com/" target="_blank">
                      <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>Facebook hesabınız ile kaydolun.
                    </a>
                  </div>
                </div> -->
                <p class="mt-4 mb-0">Halihazırda bir hesabınız var mı?<a class="ml-2" [routerLink]="'/auth/login'">Giriş Yapın</a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>


