import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class JobApprovalTypesService {

  constructor(private http: HttpClientService) {}

  async getAllJobApprovalTypes() {
    return await this.http.httpGet("/JobApprovalTypes/getall").then((res) => {
      return res.data;
    });
  }

  async getById(jobApprovalTypeID: Number) {
    return await this.http
      .httpGet("/JobApprovalTypes/getbyid?jobApprovalTypeID=" + jobApprovalTypeID)
      .then((res) => {
        return res.data;
      });
  }
  
  async addJobApprovalTypes(data) {
    return await this.http.httpPost("/JobApprovalTypes/add", data);
  }
  async deleteJobApprovalTypes(data) {
    return await this.http.httpDelete("/JobApprovalTypes/delete", data);
  }
  async updateJobApprovalTypes(data) {
    return await this.http.httpPut("/JobApprovalTypes/update", data);
  }

}
