import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ProjectUsersRoutingModule } from "./project-users-routing.module";
import { TableModule } from "primeng/table";
import { InputTextModule } from "primeng/inputtext";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ProjectUsersRoutingModule,
    TableModule,
    InputTextModule,
  ],
})
export class ProjectUsersModule {}
