import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class JobPrioritiesService {

  constructor(private http: HttpClientService) {}

  async getAllJobPriorities() {
    return await this.http.httpGet("/JobPriorities/getall").then((res) => {
      return res.data;
    });
  }

  async getById(jobPriorityID: Number) {
    return await this.http
      .httpGet("/JobPriorities/getbyid?jobPriorityID=" + jobPriorityID)
      .then((res) => {
        return res.data;
      });
  }
  
  async addJobPriorities(data) {
    return await this.http.httpPost("/JobPriorities/add", data);
  }
  async deleteJobPriorities(data) {
    return await this.http.httpDelete("/JobPriorities/delete", data);
  }
  async updateJobPriorities(data) {
    return await this.http.httpPut("/JobPriorities/update", data);
  }

}
