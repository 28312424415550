import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { JobsService } from "src/app/shared/services/jobs.service";
import { ProjectsService } from "src/app/shared/services/projects.service";
import { CreateJobComponent } from "../create-job/create-job.component";

@Component({
  selector: "app-jobs-list",
  templateUrl: "./job-list.component.html",
  styleUrls: ["./job-list.component.scss"],
})
export class JobListComponent implements OnInit {
  @ViewChild("addCreateJob") AddJobs: CreateJobComponent;
  projectID: any = 0;

  public outputID: number;
  public jobs: any;
  public projectObj: any;

  public myTaskColumns = [
    { prop: "projectName", name: "Proje Adı", colspan: 2 },
    { prop: "code", name: "Projenin Kodu", colspan: 2 },
    { prop: "jobStatusName", name: "İş Durumu", colspan: 1 },
    { prop: "jobPriorityName", name: "İş Önceliği", colspan: 1 },
    { prop: "jobTypeName", name: "İş Tipi", colspan: 1 },
    { prop: "endDate", name: "Bitiş Tarihi", colspan: 1 },
  ];

  public columns = [
    { prop: "id", name: "Numara" },
    { prop: "jobId", name: "jobId" },
    { prop: "userId", name: "userId" },
  ];

  constructor(
    private aRouter: ActivatedRoute,
    private jobServ: JobsService,
    private projectServ: ProjectsService,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.projectID = this.aRouter.snapshot.paramMap.get("projectID");
    this.projectServ.getById(this.projectID).then((resp) => {
      this.projectObj = resp;
    });
    this.jobServ.getJobsByDTOProjectID(this.projectID).then((resp) => {
      this.jobs = resp;
      console.log(this.jobs);
    });
  }

  onActivate2(event) {
    if (event.type === "click") {
      this.jobs.forEach((element) => {
        if (element.name === event.row.projectName) {
          this.projectServ.getByDTOId(element.id).then((resp) => {
            this.projectObj = resp;
            this.router.navigateByUrl(
              "/jobs/" + this.projectObj[0].code + "/" + event.row.id
            );
          });
          return;
        }
      });
    }
  }

  async updateTable() {
    await this.jobServ.getAllJobs().then((resp) => {
      this.jobs = resp;
    });
  }
  onClick(jobNum) {
    let jobObj = this.jobs[jobNum];
    this.router.navigateByUrl(
      "/jobs/" + this.projectObj.code + "/" + jobObj.id
    );
  }
}
