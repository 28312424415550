<ng-template #addJobStatuses let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">İş Durumu Ekle</h5>
    <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""
      (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <form class="form-bookmark needs-validation" id="bookmark-form" novalidate="" [formGroup]="addJobStatusForm">
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-name">İsim</label>
          <input class="form-control" id="bm-name" formControlName="name" type="text" required="" autocomplete="off">
        </div>
        <div class="form-group col-md-12">
          <label for="bm-previousStatus">Bir Önceki Durum</label>
          <select class="form-control col-12" formControlName="previousStatus">
            <option *ngFor="let item of jobStatuses" [value]="item.id">{{item.name}}</option>
          </select>
        </div>
        <div class="form-group col-md-12">
          <label for="bm-nextStatus">Bir Sonraki Durum</label>
          <select class="form-control col-12" formControlName="nextStatus">
            <option *ngFor="let item of jobStatuses" [value]="item.id">{{item.name}}</option>
          </select>
        </div>
        <div class="form-group col-md-12">
          <label for="fm-control bm-description">Açıklama</label>
          <input class="form-control" id="bm-description" formControlName="description" type="text" required=""
            autocomplete="off">
        </div>
      </div>
      <input id="index_var" type="hidden" value="6">
      <button class="btn btn-success mr-1" id="Bookmark" [disabled]="this.addJobStatusForm.invalid"
        (click)="saveJobStatus()">Kaydet</button>
      <button class="btn btn-danger" type="button" data-dismiss="modal" (click)="closeModal()">
        İptal
      </button>
    </form>
  </div>
</ng-template>