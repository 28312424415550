import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { JobCommentsService } from "src/app/shared/services/job-comments.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-job-comments",
  templateUrl: "./job-comments.component.html",
  styleUrls: ["./job-comments.component.css"],
})
export class JobCommentsComponent implements OnInit {
  @Input() user: any;
  @Input() jobID: number;
  @Output() getEmit: EventEmitter<void> = new EventEmitter<void>();
  @Input() jobDto: any;
  @Input() jobObj: any; //This is for the update

  public jobComments: any;
  public addJobCommentForm: any;

  constructor(
    private fb: FormBuilder,
    private jobCommServ: JobCommentsService,
    private snackBarService: SnackbarService
  ) {
    this.addJobCommentForm = this.fb.group({
      comment: ["", [Validators.required]],
    });
  }

  async ngOnInit() {
    await this.jobCommServ.getListByDTOJobID(this.jobID).then((resp) => {
      this.jobComments = resp;
    });
  }

  async sendComment() {
    const clientDate = new Date();
    const timezoneOffsetInMinutes = clientDate.getTimezoneOffset();
    const clientDateWithTimezone = new Date(
      clientDate.getTime() - timezoneOffsetInMinutes * 60 * 1000
    ).toISOString();
    // clientDateWithTimezone'i back-end'e gönder
    await this.jobCommServ
      .addJobComments({
        jobId: this.jobID,
        userId: this.user.data.id,
        date: clientDateWithTimezone,
        comment: this.addJobCommentForm.value.comment,
      })
      .then((a) => {
        this.snackBarService.openSnackBar(true);
        this.jobCommServ.getListByDTOJobID(this.jobID).then((resp) => {
          this.jobComments = resp;
        });

        this.addJobCommentForm.reset();
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }

  async deleteComment(id: any) {
    await this.jobCommServ
      .deleteJobComments(id)
      .then((res) => {
        this.snackBarService.openSnackBar(true);
        this.jobCommServ.getListByDTOJobID(this.jobID).then((resp) => {
          this.jobComments = resp;
        });
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }
}
