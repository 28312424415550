import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/shared/services/auth.service";
import { JobTasksService } from "src/app/shared/services/job-tasks.service";
import { JobTasksEmitService } from "src/app/shared/services/job-tasksEmit.service";
import { UserService } from "src/app/shared/services/user.service";
import * as CryptoJS from "crypto-js";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-task-detail",
  templateUrl: "./task-detail.component.html",
  styleUrls: ["./task-detail.component.scss"],
})
export class TaskDetailComponent implements OnInit {
  users: any;
  @Input() jobId: any = 0;
  @Input() taskId: any = 0;
  @Output() onSave = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  public addTaskForm: FormGroup;
  public userId: number = 0;
  public taskDataObj?: any;
  public taskDataDto?: any;
  user: any;
  public taskData: any;

  constructor(
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private taskService: JobTasksService,
    private emitJobTaskService: JobTasksEmitService,
    private userService: UserService,
    private snackBarService: SnackbarService,
    private authService: AuthService
  ) {}

  getEmit() {
    this.emitJobTaskService.getEmit.subscribe((data) => {
      this.onClickTaskDetail();
    });
  }

  async ngOnInit() {
    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);
    this.getEmit();
  }

  actualHourF() {
    return (this.taskDataObj.actualDay = this.taskDataObj.actualHour / 8);
  }

  estimatedDay() {
    return (this.taskDataObj.estimatedDay = this.taskDataObj.estimatedHour / 8);
  }

  saveTask() {
    this.estimatedDay();
    this.taskService
      .updateJobTasks(this.taskDataObj)
      .then((a) => {
        this.modalService.dismissAll();

        this.emitJobTaskService.setEmitServis(null);
        this.onSave.emit();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });
    this.emitJobTaskService.setEmitServis(null);
  }

  open(content) {
    this.onClickTaskDetail();

    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  onClickTaskDetail() {
    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);

    this.userService.getByCustomerId(1).then((res) => {
      this.users = res;
    });
    this.taskService.getByDTOId(this.taskId).then((res) => {
      this.taskData = res.map((task) => {
        return {
          ...task,
          startDate: this.datePipe.transform(
            task.startDate,
            "dd.MM.yyyy HH:mm"
          ),
          endDate: this.datePipe.transform(task.endDate, "dd.MM.yyyy HH:mm"),
        };
      });
    });
    this.taskService.getById(this.taskId).then((res) => {
      this.taskDataObj = res;
      console.log(this.taskDataObj);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
