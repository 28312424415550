import { Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";

@Injectable({
  providedIn: "root",
})
export class JobFilesService {
  constructor(private http: HttpClientService) {}

  async getById(jobFileID: Number) {
    return await this.http
      .httpGet("/JobFiles/getbyid?jobFileID=" + jobFileID)
      .then((res) => {
        return res.data;
      });
  }
  async getByDTOId(jobFileID: Number) {
    return await this.http
      .httpGet("/JobFiles/getbydtoid?jobFileID=" + jobFileID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobFiles/getlistbyjobid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }
  async getListByDTOJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobFiles/getlistbydtojobid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }

  async getFileByID(fileId: Number) {
    return await this.http
      .httpGetFile("/JobFiles/DownloadFile?jobFileID=" + fileId)
      .then((res) => {
        console.log(res);
        return res;
      });
  }

  addJobFileMultiPartForm(jobId, userId, data) {
    return this.http.httpPostFile(
      `/JobFiles/UploadFile?jobId=${jobId}&userId=${userId}`,
      data
    );
    // .then((res) => {
    //   return res.data;
    // })
    // .catch((err) => console.warn(err));
  }
  async addJobFiles(data) {
    return await this.http.httpPost("/JobFiles/add", data);
  }
  async deleteJobFiles(data) {
    return await this.http.httpDelete("/JobFiles/delete", data);
  }
  async updateJobFiles(data) {
    return await this.http.httpPut("/JobFiles/update", data);
  }
}
