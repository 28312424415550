import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JobsService } from "../../../shared/services/jobs.service";
import { JobEmitService } from "../../../shared/services/jobEmit.service";
import { isPlatformBrowser } from "@angular/common";
import { JobPrioritiesService } from "src/app/shared/services/job-priorities.service";
import { ProjectsService } from "src/app/shared/services/projects.service";
import { AuthService } from "src/app/shared/services/auth.service";
import * as CryptoJS from "crypto-js";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-create-job",
  templateUrl: "./create-job.component.html",
  styleUrls: ["./create-job.component.scss"],
})
export class CreateJobComponent implements OnInit {
  @ViewChild("addJobs", { static: false }) AddJobs: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();
  priorities: any;
  @Input() projectId: any = 0;

  public closeResult: string;
  public modalOpen: boolean = false;
  public addJobForm: FormGroup;
  public userId: number = 0;
  public user: any;
  public jobList: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private jobServ: JobsService,
    private projectServ: ProjectsService,
    private prioriServ: JobPrioritiesService,
    private emitJobService: JobEmitService,
    private snackBarService: SnackbarService,
    private authService: AuthService
  ) {
    this.addJobForm = this.fb.group({
      name: ["", [Validators.required]],
      desc: ["", [Validators.required]],
      priority: ["", [Validators.required]],
      parentJobId: [null, [Validators.required]],
    });
  }

  //Create user Id token'den çekilecek
  //Analysis approver user Id düzenlenecek

  jobData: any;
  projectID: number;

  async ngOnInit() {
    //this.user = this.authService.decyptedUser;
    //console.log(this.user);

    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);
    this.projectID = Number(localStorage.getItem("projectId"));

    this.userId = this.user.data.id;

    await this.prioriServ.getAllJobPriorities().then((res) => {
      this.priorities = res;
    });

    await this.jobServ.getJobsByDTOProjectID(this.projectId).then((res) => {
      this.jobList = res;
      this.jobList = [
        { id: null, code: "", name: "Üst İş Bulunmamakta -" },
        ...this.jobList,
      ];
    });

    await this.projectServ.getById(this.projectId).then((res) => {
      //console.log(res);
      this.jobData = {
        ...this.jobData,
        projectId: this.projectId,
        managerUserId: null,
        analysisApproverUserId: null,

        dayApproverUserId: null,
      };
    });

    this.addJobForm = this.fb.group({
      name: this.fb.control("", [Validators.required]),
      details: this.fb.control("", [Validators.required]),
      priority: this.fb.control("", [Validators.required]),
      parentJobId: this.fb.control(null, [Validators.required]),
    });

    this.jobData = {
      ...this.jobData,
      jobStatusId: 1,
      jobTypeId: null,
      jobPriorityId: 0,
      name: "",
      createDate: new Date(),
      createUserId: this.userId,
      code: "",
      topic: null,
      details: "",
      analysis: null,
      assignedUserId: null,
      sprint: null,
      estimatedDay: null,
      actualDay: null,
      billedDay: null,
      isBilled: false,

      managerApproveDate: null,

      customerITApproveDate: null,

      dayApproveDate: null,
      parentJobId: null,
      analysisApproveDate: null,
      startDate: null,
      endDate: null,
      testDate: null,
      testDetail: null,
      prodDate: null,
      isActive: true,
    };
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) {
      // For SSR

      this.modalService
        .open(this.AddJobs, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.addJobForm.reset();
          }
        );
    }
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  saveJob() {
    const parentJobIdValue = this.addJobForm.value.parentJobId
      ? parseInt(this.addJobForm.value.parentJobId, 10)
      : null;

    this.jobData = {
      ...this.jobData,
      name: this.addJobForm.value.name,
      jobPriorityId: this.addJobForm.value.priority,
      parentJobId: parentJobIdValue,
      details: this.addJobForm.value.details,
    };
    console.log(this.jobData);
    this.modalService.dismissAll();
    this.jobServ
      .addJobs({
        ...this.jobData,
      })
      .then((a) => {
        this.jobServ.getJobsByDTOProjectID(this.projectId).then((res) => {
          this.jobList = res;
          this.jobList = [
            { id: null, code: "", name: "Üst İş Bulunmamakta -" },
            ...this.jobList,
          ];
        });
        this.addJobForm.reset();
        this.modalService.dismissAll();
        this.onSave.emit();
        this.emitJobService.setEmitServis(null);
      });
    this.emitJobService.setEmitServis(null);
    this.onSave.emit();
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
