<ng-template #addProjects let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Proje Ekle</h5>
    <button
      class="close"
      type="button"
      data-dismiss="modal"
      aria-label="Close"
      data-original-title=""
      title=""
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      class="form-bookmark needs-validation"
      id="bookmark-form"
      novalidate=""
      [formGroup]="addProjectForm"
    >
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-name">İsim</label>
          <input
            class="form-control"
            id="bm-name"
            formControlName="name"
            type="text"
            required=""
            autocomplete="off"
          />
        </div>
        <div class="form-group col-md-12" *ngIf="user.data.claimId > 4">
          <label for="bm-customerId">Kurum</label>
          <select
            class="form-control col-12"
            formControlName="customerId"
            (change)="selectCustomer($event.target.value)"
          >
            <option *ngFor="let item of customers" [value]="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-12">
          <label for="bm-code">Kod</label>
          <input
            class="form-control"
            id="bm-code"
            formControlName="code"
            type="text"
            required=""
            autocomplete="off"
          />
        </div>
        <div
          style="
            font-size: 12px;
            margin-top: -10px;
            margin-bottom: 15px;
            margin-left: 10px;
          "
          *ngIf="
            addProjectForm.controls.code.touched &&
            (addProjectForm.controls.code.errors?.minlength ||
              addProjectForm.controls.code.errors?.maxlength)
          "
          class="text col-md-12 text-danger"
        >
          Kod en fazla 5 karakterden oluşmalıdır.
        </div>
        <div class="form-group col-md-12">
          <label for="bm-customerId">İş Onayı Verecek Kullanıcı</label>
          <select class="form-control col-12" formControlName="managerUserId">
            <option value="-1" default selected hidden>
              Kullanıcı Seçiniz
            </option>
            <option *ngFor="let item of users" [value]="item.id">
              {{ item.fullName }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-12">
          <label for="bm-customerId">Analiz Onayı Verecek Kullanıcı</label>
          <select
            class="form-control col-12"
            formControlName="analysisApproverUserId"
          >
            <option value="-1" default selected hidden>
              Kullanıcı Seçiniz
            </option>
            <option *ngFor="let item of users" [value]="item.id">
              {{ item.fullName }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-12">
          <label for="bm-customerId">Gün Onayı Veren Kullanıcı</label>
          <select
            class="form-control col-12"
            formControlName="dayApproverUserId"
          >
            <option value="-1" default selected hidden>
              Kullanıcı Seçiniz
            </option>
            <option *ngFor="let item of users" [value]="item.id">
              {{ item.fullName }}
            </option>
          </select>
        </div>
      </div>
      <input id="index_var" type="hidden" value="6" />
      <button
        class="btn btn-success mr-1"
        id="Bookmark"
        [disabled]="this.addProjectForm.invalid"
        (click)="saveProject()"
      >
        Kaydet
      </button>
      <button
        class="btn btn-danger"
        type="button"
        data-dismiss="modal"
        (click)="closeModal()"
      >
        İptal
      </button>
    </form>
  </div>
</ng-template>
