import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  public menuItems: any;
  public user: any;
  constructor(public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not

    //let user = this.authService.decyptedUser;
    //let user = JSON.parse(sessionStorage.getItem("user"));
    let user = sessionStorage.getItem("user");

    if (!user) {
      this.router.navigate(["/auth/login"]);
      return false;
    } else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(["/auth/login"]);
        return true;
      }
    }

    // if (
    //   typeof next.data.menuId !== "undefined" &&
    //   !this.menuItems.includes(next.data.menuId)
    // ) {
    //   this.router.navigateByUrl("/error-page/error-404");
    //   return false;
    // }
    // return true;
    return true;
  }
}
