import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JobPrioritiesService } from "../../../shared/services/job-priorities.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-edit-job-priority",
  templateUrl: "./edit-job-priority.component.html",
  styleUrls: ["./edit-job-priority.component.scss"],
})
export class EditJobPriorityComponent implements OnInit {
  @ViewChild("editJobPriorities", { static: false })
  editJobPriorities: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  public editJobPriorityForm: FormGroup;
  public jobPriorityObj: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private jobPriorityServ: JobPrioritiesService,
    private snackBarService: SnackbarService
  ) {
    this.editJobPriorityForm = this.fb.group({
      id: [{ value: "", disabled: true }, [Validators.required]],
      name: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {}

  openModal(id: number) {
    if (isPlatformBrowser(this.platformId)) {
      this.jobPriorityServ.getById(id).then((resp) => {
        this.jobPriorityObj = resp;
        this.editJobPriorityForm.get("id").setValue(this.jobPriorityObj.id);
        this.editJobPriorityForm.get("name").setValue(this.jobPriorityObj.name);
      });
      // For SSR
      this.modalService
        .open(this.editJobPriorities, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.editJobPriorityForm.reset();
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  saveJobPriority() {
    this.jobPriorityServ
      .updateJobPriorities({
        id: this.jobPriorityObj.id,
        name: this.editJobPriorityForm.value.name,
      })
      .then((a) => {
        this.editJobPriorityForm.reset();
        this.modalService.dismissAll();
        this.onSave.emit();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
