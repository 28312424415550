import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class JobStatusesService {
  constructor(private http: HttpClientService) {}

  async getAllJobStatuses() {
    return await this.http.httpGet("/JobStatuses/getall").then((res) => {
      return res.data;
    });
  }

  async getById(jobStatusID: Number) {
    return await this.http
      .httpGet("/JobStatuses/getbyid?jobStatusID=" + jobStatusID)
      .then((res) => {
        return res.data;
      });
  }
  async addJobStatuses(data) {
    return await this.http.httpPost("/JobStatuses/add", data);
  }
  async deleteJobStatuses(data) {
    return await this.http.httpDelete("/JobStatuses/delete", data);
  }
  async updateJobStatuses(data) {
    return await this.http.httpPut("/JobStatuses/update", data);
  }
}
