<ng-template #editProjectUsers let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      Proje-Kullanıcı İlişkisini Düzenle
    </h5>
    <button
      class="close"
      type="button"
      data-dismiss="modal"
      aria-label="Close"
      data-original-title=""
      title=""
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      class="form-bookmark needs-validation"
      id="bookmark-form"
      novalidate=""
      [formGroup]="editProjectUserForm"
    >
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-previousStatus">Proje</label>
          <select
            class="form-control col-12"
            formControlName="projectId"
            (change)="usersOfCustomer($event.target.value)"
          >
            <option *ngFor="let item of projects" [value]="item.id">
              {{ item.code }} - {{ item.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-12">
          <label for="bm-previousStatus">Kullanıcılar</label>
          <select class="form-control col-12" formControlName="userId">
            <option *ngFor="let item of users" [value]="item.id">
              {{ item.fullName }}
            </option>
          </select>
        </div>
      </div>
      <input id="index_var" type="hidden" value="6" />
      <button
        class="btn btn-success mr-1"
        id="Bookmark"
        [disabled]="this.editProjectUserForm.invalid"
        (click)="saveProjectUser()"
      >
        Kaydet
      </button>
      <button
        class="btn btn-danger"
        type="button"
        data-dismiss="modal"
        (click)="closeModal()"
      >
        İptal
      </button>
    </form>
  </div>
</ng-template>
