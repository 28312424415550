<app-breadcrumb
  [title]="'İş Detayı'"
  [items]="[jobDto?.projectName]"
  [active_item]="jobDto?.code"
>
</app-breadcrumb>

<div class="container-fluid">
  <div class="card" *ngIf="jobDto">
    <div class="row">
      <div class="col-md-12">
        <div class="card-header m-b-15">
          <div>
            <h4>{{ jobDto?.name }}</h4>
            <h6>{{ jobDto?.code }}</h6>
          </div>
          <div
            *ngIf="
              (user.data.claimId === 1 && jobObj.jobStatusId === 1) ||
              user.data.claimId === 5 ||
              user.data.claimId === 6
            "
          >
            <!-- <button class="btn btn-outline-success" (click)="onClickEdit()">
              Düzenle
            </button> -->
            <button
              (click)="onClickEdit()"
              class="btn btn-success"
              mat-mini-fab
              color="primary"
              style="margin-right: 10px"
              matTooltip="Düzenle"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9" style="border-right: 1px solid #ecf3fa">
        <div class="container-fluid">
          <ngb-tabset [justify]="'start'">
            <ngb-tab title="Ana Sayfa">
              <ng-template ngbTabContent>
                <div class="card-body">
                  <div class="card">
                    <div class="card-header">
                      <h5>İşin Konusu ve Açıklaması</h5>
                    </div>
                    <div class="card-body">
                      <h6>Konu:</h6>
                      <p>{{ jobDto.topic }}</p>
                      <br />
                      <h6>Açıklama:</h6>
                      <p>{{ jobDto.details }}</p>
                    </div>
                  </div>
                  <br />
                  <app-job-comments
                    [jobID]="jobID"
                    [user]="user"
                    [jobDto]="jobDto"
                    [jobObj]="jobObj"
                    [projectObj]="projectObj"
                    (getEmit)="getEmit()"
                  ></app-job-comments>
                </div>
              </ng-template>
            </ngb-tab>

            <!-- #region Onaylar kısmı  -->
            <ngb-tab title="Onaylar">
              <ng-template ngbTabContent>
                <app-approves
                  [jobID]="jobID"
                  [user]="user"
                  [jobDto]="jobDto"
                  [jobObj]="jobObj"
                  [projectObj]="projectObj"
                  (getEmit)="getEmit()"
                ></app-approves>
              </ng-template>
            </ngb-tab>
            <!-- #endregion -->

            <!-- #region Planlama kısmı -->
            <ngb-tab title="Planlama">
              <ng-template ngbTabContent>
                <app-planning
                  [jobID]="jobID"
                  [user]="user"
                  [jobDto]="jobDto"
                  [jobObj]="jobObj"
                  (getEmit)="getEmit()"
                ></app-planning>
              </ng-template>
            </ngb-tab>
            <!-- #endregion -->

            <!-- #region Analiz ve Soru/Cevap kısmı -->
            <ngb-tab title="Analiz ve Soru/Cevap">
              <ng-template ngbTabContent>
                <app-analysis-and-qa
                  [jobID]="jobID"
                  [user]="user"
                  [jobDto]="jobDto"
                  [jobObj]="jobObj"
                  (getEmit)="getEmit()"
                ></app-analysis-and-qa>
              </ng-template>
            </ngb-tab>
            <!-- #endregion -->

            <!-- #region Dosyalar kısmı -->
            <ngb-tab title="Dosyalar">
              <ng-template ngbTabContent>
                <app-job-files [jobID]="jobID" [user]="user"></app-job-files>
              </ng-template>
            </ngb-tab>
            <!-- #endregion -->

            <!-- #region Tasklar kısmı -->
            <ngb-tab title="Görevler" *ngIf="user.data.claimId > 3">
              <ng-template ngbTabContent>
                <app-task-list [jobID]="jobID" [user]="user"></app-task-list>
              </ng-template>
            </ngb-tab>
            <!-- #endregion -->

            <!-- #region Loglar kısmı -->
            <ngb-tab title="Loglar">
              <ng-template ngbTabContent>
                <app-job-logs [jobID]="jobID" [user]="user"></app-job-logs>
              </ng-template>
            </ngb-tab>
            <!-- #endregion -->

            <!-- #region Alt İşler kısmı -->
            <ngb-tab title="Alt İşler">
              <ng-template ngbTabContent>
                <app-child-jobs-table
                  [jobID]="jobID"
                  [user]="user"
                ></app-child-jobs-table>
              </ng-template>
            </ngb-tab>
            <!-- #endregion -->
          </ngb-tabset>
        </div>
        <div class="card-body"></div>
      </div>
      <div class="col-md-3">
        <div class="card-body">
          <div class="jobDetail">
            <strong> İş Durumu </strong>
            <br />
            {{ jobDto.jobStatusName }}
          </div>
          <div class="jobDetail">
            <strong> İş Önceliği </strong>
            <br />

            <select
              class="form-control"
              (change)="onChangeJobPriority($event.target.value)"
              [disabled]="user.data.claimId < 5"
            >
              <option value="-1" default selected hidden>
                {{ jobDto.jobPriorityName }}
              </option>
              <option [value]="item.id" *ngFor="let item of priorities">
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="jobDetail">
            <strong> İş Tipi </strong>
            <br />
            <select
              class="form-control"
              (change)="onChangeJobType($event.target.value)"
              [disabled]="user.data.claimId < 5"
            >
              <option value="-1" default selected hidden>
                {{ jobDto.jobTypeName }}
              </option>
              <option [value]="item.id" *ngFor="let item of jobTypes">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="jobDetail">
            <strong> Oluşturan Kullanıcı </strong>
            <br />
            {{ jobDto.createUserName }}
          </div>
          <div class="jobDetail">
            <strong> Oluşturulma Tarihi </strong>
            <br />
            {{ jobDto.createDate }}
          </div>

          <div class="jobDetail" *ngIf="jobDto.managerUserName !== null">
            <strong> İş Onayı Veren Kullanıcı </strong>
            <br />
            {{ jobDto.managerUserName }}
          </div>
          <div class="jobDetail" *ngIf="jobDto.managerUserName === null">
            <strong> İş Onayı Veren Kullanıcı </strong>
            <br />
            <p style="color: red">Henüz iş onayı verilmemiş</p>
          </div>
          <div class="jobDetail" *ngIf="jobDto.managerApproveDate !== null">
            <strong> İş Onayı Tarihi</strong>
            <br />
            {{ jobDto.managerApproveDate }}
          </div>
          <div class="jobDetail" *ngIf="jobDto.managerApproveDate === null">
            <strong> İş Onayı Tarihi </strong>
            <br />
            <p style="color: red">Henüz iş onayı verilmemiş</p>
          </div>

          <div
            class="jobDetail"
            *ngIf="jobDto.analysisApproverUserName !== null"
          >
            <strong> Analiz Onayı Veren Kullanıcı </strong>
            <br />
            {{ jobDto.analysisApproverUserName }}
          </div>
          <div
            class="jobDetail"
            *ngIf="jobDto.analysisApproverUserName === null"
          >
            <strong> Analiz Onayı Veren Kullanıcı </strong>
            <br />
            <p style="color: red">Henüz Analiz onayı verilmemiş</p>
          </div>
          <div class="jobDetail" *ngIf="jobDto.analysisApproveDate !== null">
            <strong> Analiz Onayı Tarihi </strong>
            <br />
            {{ jobDto.analysisApproveDate }}
          </div>
          <div class="jobDetail" *ngIf="jobDto.analysisApproveDate === null">
            <strong> Analiz Onayı Tarihi </strong>
            <br />
            <p style="color: red">Henüz Analiz onayı verilmemiş</p>
          </div>

          <div class="jobDetail" *ngIf="jobDto.dayApproverUserName !== null">
            <strong> Gün Onayı Veren Kullanıcı </strong>
            <br />
            {{ jobDto.dayApproverUserName }}
          </div>
          <div class="jobDetail" *ngIf="jobDto.dayApproverUserName === null">
            <strong> Gün Onayı Veren Kullanıcı </strong>
            <br />
            <p style="color: red">Henüz gün onayı verilmemiş</p>
          </div>
          <div class="jobDetail" *ngIf="jobDto.dayApproveDate !== null">
            <strong> Gün Onayı Tarihi </strong>
            <br />
            {{ jobDto.dayApproveDate }}
          </div>
          <div class="jobDetail" *ngIf="jobDto.dayApproveDate === null">
            <strong> Gün Onayı Tarihi </strong>
            <br />
            <p style="color: red">Henüz gün onayı verilmemiş</p>
          </div>

          <div class="jobDetail">
            <strong> Atanan Kullanıcı </strong>
            <br />
            {{ jobDto.assignedUserName }}
            <p
              style="color: red"
              *ngIf="
                jobDto.assignedUserName === null ||
                jobDto.assignedUserName === ''
              "
            >
              Henüz kimse atanmamış
            </p>
          </div>

          <div class="customers jobDetail">
            <div>
              <strong> İşi Takip Edenler </strong>
            </div>
            <div>
              <div *ngFor="let item of jobFollowers">
                <div class="followerBlock">
                  <div>
                    <p>{{ item.userName }}</p>
                  </div>
                  <div>
                    <!-- <button
                      mat-mini-fab
                      color="warn"
                      class="unfollowButton"
                      (click)="unFollowJob(item.id)"
                    >
                      <mat-icon>delete_outline</mat-icon>
                    </button> -->
                    <button
                      class="unfollowButton"
                      (click)="unFollowJob(item.id)"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <br />
              <div>
                <select
                  class="form-control"
                  (change)="followJob($event.target.value)"
                  [(ngModel)]="followSelect"
                >
                  <option value="-1" default selected hidden>
                    Kullanıcı Seçiniz
                  </option>
                  <option [value]="item.id" *ngFor="let item of users">
                    {{ item.fullName }}
                  </option>
                </select>
              </div>

              <br />
              <button
                *ngIf="!isFollow"
                class="btn btn-info"
                (click)="followJob(user.data.id)"
              >
                Takip Et
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
