import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProjectUsersService } from "src/app/shared/services/project-users.service";
import { ProjectsService } from "src/app/shared/services/projects.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-add-project-user",
  templateUrl: "./add-project-user.component.html",
  styleUrls: ["./add-project-user.component.scss"],
})
export class AddProjectUserComponent implements OnInit {
  @ViewChild("addProjectUsers", { static: false })
  addProjectUsers: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();
  public users: any = [];
  public projects: any;
  public projectUserObj: any = {
    projectId: -1,
    userId: -1,
  };
  buttonIsActive = false;

  public closeResult: string;
  public modalOpen: boolean = false;
  public addProjectUserForm: FormGroup;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private projectUserServ: ProjectUsersService,
    private projectService: ProjectsService,
    private userService: UserService,
    private snackBarService: SnackbarService
  ) {
    // this.addProjectUserForm = this.fb.group({
    //   projectId: ["", [Validators.required]],
    //   userId: ["", [Validators.required]],
    // });
  }

  async ngOnInit() {}

  async openModal() {
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      await this.projectService.getAllProjects().then((res) => {
        this.projects = res;
      });
      await this.userService.getByCustomerId(1).then((res) => {
        this.users = res;
      });
      this.modalService
        .open(this.addProjectUsers, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            //  this.addProjectUserForm.reset();
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  async usersOfCustomer(id) {
    this.users = [];
    await this.userService.getByCustomerId(1).then((res) => {
      this.users = res;
    });

    this.projectUserObj.projectId = id;

    await this.projectService.getById(id).then((res) => {
      const project = res;
      // console.log(project);

      this.userService.getByCustomerId(project.customerId).then((res) => {
        this.users = [...this.users, ...res];
      });
    });
  }

  async userId(id) {
    this.projectUserObj.userId = id;
    this.buttonIsActive = true;
  }

  async saveProjectUser() {
    // var projectUser = {
    //   projectId: Number(this.addProjectUserForm.value.projectId),
    //   userId: Number(this.addProjectUserForm.value.userId),
    //   project: null,
    // };
    var projectUser = {
      projectId: Number(this.projectUserObj.projectId),
      userId: Number(this.projectUserObj.userId),
    };

    this.projectUserServ
      .addProjectUsers(projectUser)
      .then((a) => {
        // this.addProjectUserForm.reset();
        this.modalService.dismissAll();
        this.onSave.emit();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.modalService.dismissAll();

        this.snackBarService.openSnackBarWithMessageError(err.error);
      });
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
