import { Component, OnInit, ViewChild } from "@angular/core";
import { AddJobPriorityComponent } from "./add-job-priority/add-job-priority.component";
import { DeleteJobPriorityComponent } from "./delete-job-priority/delete-job-priority.component";
import { EditJobPriorityComponent } from "./edit-job-priority/edit-job-priority.component";
import { JobPrioritiesService } from "../../shared/services/job-priorities.service";

@Component({
  selector: "app-job-priority",
  templateUrl: "./job-priority.component.html",
  styleUrls: ["./job-priority.component.scss"],
})
export class JobPriorityComponent implements OnInit {
  @ViewChild("addJobPriorities") AddJobPriorities: AddJobPriorityComponent;
  @ViewChild("editJobPriorities") EditJobPriority: EditJobPriorityComponent;
  @ViewChild("deleteJobPriorities")
  DeleteJobPriorities: DeleteJobPriorityComponent;
  public outputID: number;
  public jobPriorities = [{}];
  public columns = [
    { prop: "id", name: "Numara" },
    { prop: "name", name: "İsim" },
  ];
  public dynamicGlobalFilterFields: any;

  constructor(private jobPriorityServ: JobPrioritiesService) {}
  async ngOnInit() {
    this.dynamicGlobalFilterFields = this.columns.map((col) => col.prop);

    await this.jobPriorityServ.getAllJobPriorities().then((resp) => {
      this.jobPriorities = resp;
    });
  }
  async updateTable() {
    await this.jobPriorityServ.getAllJobPriorities().then((resp) => {
      this.jobPriorities = resp;
    });
  }
}
