<div class="card-body">
  <!-- Alt İşler kısmı -->
  <div class="mt-4">
    <div class="card">
      <div class="card-header m-b-15">
        <h6>Alt İşleri Görüntüle</h6>
      </div>
      <div class="card-body">
        <div>
          <div class="mat-elevation-z8">
            <p-table
              [value]="childJobList"
              [columns]="columnsChildJobs"
              [tableStyle]="{ 'min-width': '50rem' }"
              responsiveLayout="scroll"
              [rows]="10"
              [showCurrentPageReport]="true"
              [paginator]="true"
              [rowsPerPageOptions]="[10, 25, 50]"
              currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} gösteriliyor"
              #tt
              [globalFilterFields]="dynamicGlobalFilterFields"
              [scrollable]="true"
              scrollHeight="600px"
            >
              <ng-template pTemplate="caption">
                <div class="flex justify-content-end align-items-center">
                  <div class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input
                      type="text"
                      pInputText
                      placeholder="Ara"
                      (input)="
                        assignedJobs.filterGlobal(
                          $event.target.value,
                          'contains'
                        )
                      "
                    />
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.prop">
                    {{ col.name }}
                    <p-sortIcon [field]="col.prop"></p-sortIcon>
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td *ngFor="let col of columnsChildJobs">
                    {{ rowData[col.prop] }}
                  </td>
                  <td>
                    <div class="example-button-container">
                      <button
                        (click)="onClickChildJob(rowData.id)"
                        mat-mini-fab
                        color="primary"
                        style="margin-right: 10px"
                        matTooltip="Detay"
                      >
                        <mat-icon>arrow_forward</mat-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="100%">Gösterilecek veri bulunmamaktadır</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
