import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { JobApprovalTypeRoutingModule } from "./job-approval-type-routing.module";
import { AddJobApprovalTypeComponent } from "./add-job-approval-type/add-job-approval-type.component";
import { EditJobApprovalTypeComponent } from "./edit-job-approval-type/edit-job-approval-type.component";
import { DeleteJobApprovalTypeComponent } from "./delete-job-approval-type/delete-job-approval-type.component";

import { TableModule } from "primeng/table";
import { InputTextModule } from "primeng/inputtext";

@NgModule({
  declarations: [
    
    DeleteJobApprovalTypeComponent,
  ],
  imports: [
    CommonModule,
    JobApprovalTypeRoutingModule,
    TableModule,
    InputTextModule,
  ],
  exports: [

    DeleteJobApprovalTypeComponent,
  ],
})
export class JobApprovalTypeModule {}
