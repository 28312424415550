<ng-template #addJobs let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">İş Ekle</h5>
    <button
      class="close"
      type="button"
      data-dismiss="modal"
      aria-label="Close"
      data-original-title=""
      title=""
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      class="form-bookmark needs-validation"
      id="bookmark-form"
      novalidate=""
      [formGroup]="addJobForm"
    >
      <div class="form-row">
        <div class="form-group col-md-12">
          <label>Üst İş</label>
          <select
            class="form-control m-5"
            formControlName="parentJobId"
            required=""
          >
            <option [value]="null" selected hidden>
              Lütfen Üst İş Seçiniz...
            </option>
            <option [value]="item.id" *ngFor="let item of jobList">
              {{ item.code + " - " + item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-name">İş Adı</label>
          <input
            class="form-control"
            id="bm-name"
            formControlName="name"
            type="text"
            required=""
            autocomplete="off"
            placeholder="İş Adı"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label>İş Önceliği</label>
          <select class="form-control m-5" formControlName="priority" required>
            <option value="" selected hidden>Lütfen Öncelik Seçiniz...</option>
            <option [value]="item.id" *ngFor="let item of priorities">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-desc">İş Detayı</label>
          <textarea
            class="form-control"
            id="bm-desc"
            formControlName="details"
            type="text"
            required=""
            autocomplete="off"
            placeholder="İş Detayı"
          ></textarea>
        </div>
      </div>
      <input id="index_var" type="hidden" value="6" />
      <button
        class="btn btn-success mr-1"
        id="Bookmark"
        [disabled]="!addJobForm.valid"
        (click)="saveJob()"
      >
        Kaydet
      </button>
      <button
        class="btn btn-danger"
        type="button"
        data-dismiss="modal"
        (click)="closeModal()"
      >
        İptal
      </button>
    </form>
  </div>
</ng-template>
