<app-breadcrumb [title]="'İş Listesi'" [active_item]="'İşler'"></app-breadcrumb>

<div class="container-fluid jkanban-container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div
          style="
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 2rem;
          "
        >
          <!-- <div class="card-header" *ngIf="selectedIndex === 1">
            Kanban Görünümü
          </div>
          <div class="card-header" *ngIf="selectedIndex === 2">
            Liste Görünümü
          </div> -->
          <div>
            <div style="cursor: pointer; padding-right: 2rem">
              <!-- <button
                class="btn btn-info"
                (click)="onClickCreate()"
                *ngIf="user.data.claimId > 4"
              >
                Yeni İş Oluştur
              </button> -->
              <button
                class="btn btn-info addButton"
                type="button"
                (click)="onClickCreate()"
                *ngIf="user.data.claimId > 4"
              >
                <span
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <app-feather-icons
                    style="margin-right: 5px; display: flex"
                    [icon]="'plus'"
                  >
                  </app-feather-icons>
                  Yeni İş Oluştur
                </span>
              </button>
              <!-- <app-feather-icons
                style="margin-right: 5px"
                [icon]="'grid'"
                (click)="onClick(1)"
                *ngIf="selectedIndex === 1"
              ></app-feather-icons>
              <app-feather-icons
                *ngIf="selectedIndex === 1"
                style="margin-right: 5px"
                [icon]="'list'"
                (click)="onClick(2)"
              ></app-feather-icons> -->
            </div>
          </div>
        </div>

        <div>
          <div class="card-body">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <h6>Filtre</h6>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <select
                  class="form-control"
                  (change)="
                    filterData($event.target.value, 'customerName');
                    setFilters()
                  "
                  [(ngModel)]="filters.customerName"
                >
                  <option value="default" default selected>
                    Kurum Seçiniz
                  </option>
                  <option [value]="item.name" *ngFor="let item of customers">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <select
                  class="form-control"
                  (change)="
                    filterData($event.target.value, 'projectName'); setFilters()
                  "
                  [(ngModel)]="filters.projectName"
                >
                  <option value="default" default selected>
                    Proje Seçiniz
                  </option>
                  <option
                    [value]="item.name"
                    *ngFor="let item of filteredProjects"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <select
                  class="form-control"
                  (change)="
                    filterData($event.target.value, 'sprint'); setFilters()
                  "
                  [(ngModel)]="filters.sprint"
                >
                  <option value="default" default selected>
                    Sprint Seçiniz
                  </option>
                  <option
                    [value]="item.id"
                    *ngFor="let item of filteredSprints"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <select
                  class="form-control"
                  (change)="
                    filterData($event.target.value, 'assignedUserName');
                    setFilters()
                  "
                  [(ngModel)]="filters.assignedUserName"
                >
                  <option value="default" default selected>
                    Atanan Kullanıcı Seçiniz
                  </option>
                  <option [value]="item.fullName" *ngFor="let item of users">
                    {{ item.fullName }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <select
                  class="form-control"
                  (change)="
                    filterData($event.target.value, 'jobStatusName');
                    setFilters()
                  "
                  [(ngModel)]="filters.jobStatusName"
                >
                  <option value="default" default selected>
                    İş Durumu Seçiniz
                  </option>
                  <option [value]="item.name" *ngFor="let item of jobStatuses">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <select
                  class="form-control"
                  (change)="
                    filterData($event.target.value, 'jobTypeName'); setFilters()
                  "
                  [(ngModel)]="filters.jobTypeName"
                >
                  <option value="default" default selected>
                    İş Tipi Seçiniz
                  </option>
                  <option [value]="item.name" *ngFor="let item of jobTypes">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <select
                  class="form-control"
                  (change)="
                    filterData($event.target.value, 'isBilled'); setFilters()
                  "
                  [(ngModel)]="filters.isBilled"
                >
                  <option value="default" default selected>
                    Ödeme Durumu Seçiniz
                  </option>
                  <option [value]="item.value" *ngFor="let item of bools">
                    {{ item.name }}
                  </option>
                  <!-- <option [value]="true">Fatura Edildi</option>
                  <option [value]="false">Fatura Edilmedi</option> -->
                </select>
              </div>
            </div>
            <div
              class="form-row flex justify-content-start align-items-center gap-2"
            >
              <div>
                <h5>İş Listesi</h5>
              </div>
              <div class="excellDownloadBtn" (click)="export()">
                <app-feather-icons [icon]="'download'"></app-feather-icons>
              </div>
            </div>
          </div>
        </div>

        <!-- <div
          class="card-body scrolling-wrapper"
          style="background-color: #f8f8f8; margin: 20px; border-radius: 15px"
          *ngIf="selectedIndex === 1"
        >
          <div>
            <div
              class="kanban-container"
              style="
                width: 1290px;
                display: flex;
                justify-content: space-between;
                height: 100%;
              "
            >
              <div
                class="kanban-board drag-container"
                *ngFor="let status of statusList; let i = index"
                style="width: 500px; margin-left: 15px; margin-right: 15px"
                data-order="1"
                [id]="i + 1"
              >
                <header style="margin-bottom: 15px; width: 300px">
                  <div class="kanban-title-board">
                    {{ status.desc }}
                  </div>
                </header>
                <div class="divider"></div>
                <main
                  class="kanban-drag"
                  style="display: flex; flex-direction: column"
                  dragula="KANBAN"
                  [(dragulaModel)]="kanbanBoards"
                >
                  <div
                    class="card"
                    *ngFor="let item of jobData"
                    style="padding: 15px"
                  >
                    <a href="#" class="kanban-box">
                      <span class="date"> 23/7/20 </span>
                      <span class="badge badge-primary f-right"> önemli </span>
                      <h6>{{ item.title }}</h6>
                    </a>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div> -->

        <div *ngIf="selectedIndex === 2">
          <div class="card-body" style="padding-top: 0 !important">
            <div>
              <div class="mat-elevation-z8">
                <p-treeTable
                  [value]="filteredData"
                  [columns]="columns"
                  [rows]="10"
                  [showCurrentPageReport]="true"
                  [rowsPerPageOptions]="[10, 25, 50]"
                  [paginator]="true"
                  currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} gösteriliyor"
                  #tt
                  [globalFilterFields]="dynamicGlobalFilterFields"
                  [scrollable]="true"
                  scrollHeight="600px"
                >
                  <ng-template pTemplate="caption">
                    <div class="flex justify-content-end align-items-center">
                      <div class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input
                          type="text"
                          pInputText
                          placeholder="Ara"
                          (input)="
                            tt.filterGlobal($event.target.value, 'contains')
                          "
                        />
                      </div>
                    </div>
                  </ng-template>

                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th
                        *ngFor="let col of columns"
                        [ttSortableColumn]="col.prop"
                        [style.width]="col.width"
                      >
                        {{ col.name }}
                        <p-treeTableSortIcon
                          [field]="col.prop"
                        ></p-treeTableSortIcon>
                      </th>
                      <th style="width: 10%"></th>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="body"
                    let-rowNode
                    let-rowData="rowData"
                    let-columns="columns"
                  >
                    <tr
                      [ngClass]="{
                        'has-children':
                          rowNode.node && rowNode.node.children.length > 0,
                        'is-child': rowNode.parent
                      }"
                    >
                      <td
                        *ngFor="let col of columns; let i = index"
                        [style.width]="col.width"
                      >
                        <p-treeTableToggler
                          [rowNode]="rowNode"
                          *ngIf="i == 0"
                        ></p-treeTableToggler>
                        {{ rowData[col.prop] }}
                      </td>
                      <td style="width: 10%">
                        <div class="example-button-container d-flex">
                          <button
                            (click)="onClickDetail(rowData.id)"
                            mat-mini-fab
                            color="primary"
                            style="margin-right: 10px"
                            matTooltip="Detay"
                          >
                            <mat-icon>arrow_forward</mat-icon>
                          </button>

                          <button
                            (click)="editJob(rowData.id)"
                            class="btn btn-success"
                            mat-mini-fab
                            color="primary"
                            style="margin-right: 10px"
                            matTooltip="Düzenle"
                          >
                            <mat-icon>edit</mat-icon>
                          </button>

                          <button
                            (click)="openModal(jobDelete, rowData.id)"
                            mat-mini-fab
                            color="primary"
                            color="warn"
                            matTooltip="Sil"
                          >
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="100%">Gösterilecek veri bulunmamaktadır</td>
                    </tr>
                  </ng-template>
                </p-treeTable>
              </div>
            </div>

            <!-- <ngx-datatable
              *ngIf="filteredData"
              #projectDatatable
              class="bootstrap hover"
              [rows]="filteredData"
              [columns]="columns"
              [columnMode]="'force'"
              headerHeight="auto"
              rowHeight="auto"
              [footerHeight]="50"
              [limit]="10"
            >
              <ngx-datatable-column
                [width]="-100"
                *ngFor="let column of columns; let i = index"
                name="{{ column.name }}"
                prop="{{ column.prop }}"
              >
              </ngx-datatable-column>
              <ngx-datatable-column minWidth="350" [sortable]="false" name="">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  <div style="margin-top: -10px; display: flex">
                    <button
                      class="btn btn-primary"
                      style="margin-right: 10px"
                      (click)="onClickDetail(row.id)"
                    >
                      <span
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <app-feather-icons
                          style="margin-right: 5px"
                          [icon]="'edit'"
                        >
                        </app-feather-icons>
                        Detay
                      </span>
                    </button>
                    <button
                      class="btn btn-success"
                      style="margin-right: 10px"
                      (click)="editJob(row.id)"
                    >
                      <span
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <app-feather-icons
                          style="margin-right: 5px"
                          [icon]="'edit'"
                        >
                        </app-feather-icons>
                        Düzenle
                      </span>
                    </button>
                    <button
                      class="btn btn-danger"
                      (click)="openModal(jobDelete, row.id)"
                    >
                      <span
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <app-feather-icons
                          style="margin-right: 5px"
                          [icon]="'trash-2'"
                        >
                        </app-feather-icons>
                        Sil
                      </span>
                    </button>
                  </div>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable> -->

            <!-- <app-add-project
              [users]="users"
              [customers]="customers"
              #addProjects
              (onSave)="updateTable()"
            ></app-add-project>
            <app-edit-project
              [users]="users"
              [customers]="customers"
              #editProjects
              (onSave)="updateTable()"
            ></app-edit-project>
            -->
            <app-delete-job
              #deleteJobs
              (onSave)="updateTable()"
            ></app-delete-job>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card" hidden style="border-radius: 20px">
  <div class="card-header">
    <h5>Modal with default options</h5>
  </div>
  <div class="card-body">
    <ng-template #jobDelete let-modal>
      <div class="modal-header">
        <h4 class="modal-title text-danger" id="modal-basic-title">İşi Sil</h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5>İş Silinecek Emin misin?</h5>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          (click)="modal.close('Cross click')"
        >
          Sil
        </button>
        <button
          type="button"
          class="btn btn-success"
          (click)="modal.dismiss('close')"
        >
          Vazgeç
        </button>
      </div>
    </ng-template>
  </div>
</div>
