<ng-template #editUsers let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Kullanıcı Düzenle</h5>
    <button
      class="close"
      type="button"
      data-dismiss="modal"
      aria-label="Close"
      data-original-title=""
      title=""
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      class="form-bookmark needs-validation"
      id="bookmark-form"
      novalidate=""
      [formGroup]="editUserForm"
    >
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-userName">Kullanıcı Adı</label>
          <input
            class="form-control"
            id="bm-userName"
            formControlName="userName"
            type="text"
            required=""
            autocomplete="off"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-email">Email</label>
          <input
            class="form-control"
            id="bm-email"
            formControlName="email"
            type="text"
            required=""
            autocomplete="off"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-fullName">Tam isim</label>
          <input
            class="form-control"
            id="bm-fullName"
            formControlName="fullName"
            type="text"
            required=""
            autocomplete="off"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-phone">Telefon</label>
          <input
            class="form-control"
            id="bm-phone"
            formControlName="phone"
            type="text"
            required=""
            autocomplete="off"
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-customerId">Kurum</label>
          <select class="form-control" formControlName="customerId" required>
            <option [value]="item.id" *ngFor="let item of customers">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-claimId">Yetki</label>
          <select class="form-control" formControlName="claimId" required>
            <option [value]="item.id" *ngFor="let item of claims">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6 d-flex">
          <label for="bm-isActive">Aktif Çalışan</label>
          <input
            class="form-control"
            id="bm-isActive"
            formControlName="isActive"
            type="checkbox"
            autocomplete="off"
          />
        </div>
        <div class="form-group col-md-6 d-flex">
          <label for="bm-isLocked">Kilit</label>
          <input
            class="form-control"
            id="bm-isLocked"
            formControlName="isLocked"
            type="checkbox"
            autocomplete="off"
          />
        </div>
        <div class="form-group col-md-12">
          <div
            class="form-group col-md-12 d-flex justify-content-between"
            *ngIf="!isVisibleChangePassword"
          >
            <div>
              <label for="bm-newPassword">Yeni şifre</label>
              <input
                class="form-control"
                id="bm-newPassword"
                formControlName="newPassword"
                type="text"
              />
            </div>
            <div>
              <button
                class="btn btn-info mr-1"
                id="Bookmark"
                (click)="updatePassword()"
              >
                Şifreyi Kaydet
              </button>
            </div>
          </div>

          <button
            class="btn btn-info mr-1"
            id="Bookmark"
            *ngIf="isVisibleChangePassword"
            (click)="changePassword()"
          >
            Şifre değiştir
          </button>
        </div>
      </div>

      <button
        class="btn btn-success mr-1"
        id="Bookmark"
        [disabled]="this.editUserForm.invalid"
        (click)="saveUser()"
      >
        Kaydet
      </button>
      <button
        class="btn btn-danger"
        type="button"
        data-dismiss="modal"
        (click)="closeModal()"
      >
        İptal
      </button>
    </form>
  </div>
</ng-template>
