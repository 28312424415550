<ng-template #addUsers let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Kullanıcı Ekle</h5>
    <button
      class="close"
      type="button"
      data-dismiss="modal"
      aria-label="Close"
      data-original-title=""
      title=""
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      class="form-bookmark needs-validation"
      id="bookmark-form"
      novalidate=""
      [formGroup]="addUserForm"
    >
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-userName">Kullanıcı Adı</label>
          <input
            class="form-control"
            id="bm-userName"
            formControlName="userName"
            type="text"
            required
            autocomplete="off"
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-fullName">Tam isim</label>
          <input
            class="form-control"
            id="bm-fullName"
            formControlName="fullName"
            type="text"
            required
            autocomplete="off"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-email">Email</label>
          <input
            class="form-control"
            id="bm-email"
            formControlName="email"
            type="text"
            required
            autocomplete="off"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-email">Şifre</label>
          <input
            class="form-control"
            id="bm-email"
            formControlName="password"
            type="password"
            required
            autocomplete="off"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-phone">Telefon</label>
          <input
            class="form-control"
            id="bm-phone"
            formControlName="phone"
            type="text"
            required
            autocomplete="off"
            mask="(000) 000 00 00"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-customerId">Kurum</label>
          <select class="form-control" formControlName="customerId" required>
            <option [value]="item.id" *ngFor="let item of customers">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-claimId">Yetki</label>
          <select class="form-control" formControlName="claimId" required>
            <option [value]="item.id" *ngFor="let item of claims">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <input id="index_var" type="hidden" value="6" />
      <button
        class="btn btn-success mr-1"
        id="Bookmark"
        [disabled]="this.addUserForm.invalid"
        (click)="saveUser()"
      >
        Kaydet
      </button>
      <button
        class="btn btn-danger"
        type="button"
        data-dismiss="modal"
        (click)="closeModal()"
      >
        İptal
      </button>
    </form>
  </div>
</ng-template>
