import { BrowserModule } from "@angular/platform-browser";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

// for HttpClient import:
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
// for Router import:
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
// for Core import:
import { LoadingBarModule } from "@ngx-loading-bar/core";

import { AuthService } from "./shared/services/firebase/auth.service";
import { AdminGuard } from "./shared/guard/admin.guard";
import { SecureInnerPagesGuard } from "./shared/guard/SecureInnerPagesGuard.guard";
import { CookieService } from "ngx-cookie-service";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { environment } from "../environments/environment";
import { AngularFirestoreModule } from "@angular/fire/firestore";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";
import { KanbanBoardComponent } from "./components/kanban-board/kanban-board.component";
import { DragulaModule } from "ng2-dragula";
import { HttpClientService } from "./shared/services/http-client.service";
import { RegisterComponent } from "./auth/register/register.component";
import { JobTypesComponent } from "./tables/job-types/job-types.component";
import { JobTypesModule } from "./tables/job-types/job-types/job-types.module";

//services
import { JobTypesService } from "./shared/services/job-types.service";
import { JobStatusesService } from "./shared/services/job-statuses.service";

import { CustomerModule } from "./tables/customer/customer/customer.module";
import { AddJobTypesComponent } from "./tables/job-types/add-job-type/add-job-types.component";
import { EditJobTypeComponent } from "./tables/job-types/edit-job-type/edit-job-type.component";
import { AreYouSureComponent } from "./tables/job-types/are-you-sure/are-you-sure.component";
import { CustomerComponent } from "./tables/customer/customer.component";
import { AddCustomerComponent } from "./tables/customer/add-customer/add-customer.component";
import { DeleteCustomerComponent } from "./tables/customer/delete-customer/delete-customer.component";
import { EditCustomerComponent } from "./tables/customer/edit-customer/edit-customer.component";
import { JobPriorityModule } from "./tables/job-priority/job-priority/job-priority.module";
import { JobPriorityComponent } from "./tables/job-priority/job-priority.component";
import { AddJobPriorityComponent } from "./tables/job-priority/add-job-priority/add-job-priority.component";
import { EditJobPriorityComponent } from "./tables/job-priority/edit-job-priority/edit-job-priority.component";
import { DeleteJobPriorityComponent } from "./tables/job-priority/delete-job-priority/delete-job-priority.component";
import { JobStatusesComponent } from "./tables/job-statuses/job-statuses.component";
import { JobStatusesModule } from "./tables/job-statuses/job-statuses/job-statuses.module";
import { AddJobStatusComponent } from "./tables/job-statuses/add-job-status/add-job-status.component";
import { DeleteJobStatusComponent } from "./tables/job-statuses/delete-job-status/delete-job-status.component";
import { EditJobStatusComponent } from "./tables/job-statuses/edit-job-status/edit-job-status.component";
import { ProjectsTableComponent } from "./tables/projects-table/projects-table.component";
import { ProjectsTableModule } from "./tables/projects-table/projects-table/projects-table.module";
import { AddProjectComponent } from "./tables/projects-table/add-project/add-project.component";

import { DeleteProjectComponent } from "./tables/projects-table/delete-project/delete-project.component";
import { ProjectUsersComponent } from "./tables/project-users/project-users.component";
import { ProjectUsersModule } from "./tables/project-users/project-users/project-users.module";
import { AddProjectUserComponent } from "./tables/project-users/add-project-user/add-project-user.component";
import { EditProjectUserComponent } from "./tables/project-users/edit-project-user/edit-project-user.component";
import { DeleteProjectUserComponent } from "./tables/project-users/delete-project-user/delete-project-user.component";
import { JwtHelperService, JWT_OPTIONS } from "@auth0/angular-jwt";
import { JobComponent } from "./components/job/job.component";
import { JobModule } from "./components/job/job/job.module";
import { JobListComponent } from "./components/job/job-list/job-list.component";
import { LogsComponent } from "./tables/logs/logs.component";
import { LogsModule } from "./tables/logs/logs/logs.module";
import { AddLogComponent } from "./tables/logs/add-log/add-log.component";
import { EditLogComponent } from "./tables/logs/edit-log/edit-log.component";
import { DeleteLogComponent } from "./tables/logs/delete-log/delete-log.component";
import { UsersComponent } from "./tables/users/users.component";
import { UsersModule } from "./tables/users/users/users.module";
import { AddUserComponent } from "./tables/users/add-user/add-user.component";
import { DeleteUserComponent } from "./tables/users/delete-user/delete-user.component";
import { EditUserComponent } from "./tables/users/edit-user/edit-user.component";
import { ProjectModule } from "./components/apps/project/project.module";
import { CreateJobPageComponent } from "./components/job/create-job-page/create-job-page.component";
import { CreateTaskComponent } from "./components/job/create-task/create-task.component";
import { TaskDetailComponent } from "./components/job/task-detail/task-detail.component";
import { EditJobComponent } from "./components/job/edit-job/edit-job.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { SprintComponent } from "./tables/sprint/sprint.component";
import { AddSprintComponent } from "./tables/sprint/add-sprint/add-sprint.component";
import { DeleteSprintComponent } from "./tables/sprint/delete-sprint/delete-sprint.component";
import { EditSprintComponent } from "./tables/sprint/edit-sprint/edit-sprint.component";
import { NgxDropzoneModule } from "ngx-dropzone";
import { AngularEditorModule } from "@kolkov/angular-editor";

import { MatNativeDateModule } from "@angular/material/core";
import { DataTableComponent } from "./tables/data-table/data-table.component";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { ConfirmationsModule } from "./tables/confirmations/confirmations.module";
import { MatIconModule } from "@angular/material/icon";
import { A11yModule } from "@angular/cdk/a11y";
import { CdkAccordionModule } from "@angular/cdk/accordion";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PortalModule } from "@angular/cdk/portal";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";

import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";

import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { CustomPaginator } from "../environments/CustomPaginatorConfiguration";
import { DeleteFileComponent } from "./components/job/delete-file/delete-file.component";

import { NgxMaskModule, IConfig } from "ngx-mask";
import { TreeTableModule } from "primeng/treetable";
import { ToastModule } from "primeng/toast";
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { MultiSelectModule } from "primeng/multiselect";
import { InputTextModule } from "primeng/inputtext";
import { ContextMenuModule } from "primeng/contextmenu";
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

// Import PrimeNG modules
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { BadgeModule } from "primeng/badge";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { CalendarModule } from "primeng/calendar";
import { CarouselModule } from "primeng/carousel";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { CheckboxModule } from "primeng/checkbox";
import { ChipModule } from "primeng/chip";
import { ChipsModule } from "primeng/chips";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ColorPickerModule } from "primeng/colorpicker";
import { DataViewModule } from "primeng/dataview";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { DividerModule } from "primeng/divider";
import { DockModule } from "primeng/dock";
import { DropdownModule } from "primeng/dropdown";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { EditorModule } from "primeng/editor";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { GalleriaModule } from "primeng/galleria";
import { InplaceModule } from "primeng/inplace";
import { InputMaskModule } from "primeng/inputmask";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputNumberModule } from "primeng/inputnumber";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ImageModule } from "primeng/image";
import { KnobModule } from "primeng/knob";
import { ListboxModule } from "primeng/listbox";
import { MegaMenuModule } from "primeng/megamenu";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { OrderListModule } from "primeng/orderlist";
import { OrganizationChartModule } from "primeng/organizationchart";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { PasswordModule } from "primeng/password";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ScrollTopModule } from "primeng/scrolltop";
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from "primeng/sidebar";
import { SkeletonModule } from "primeng/skeleton";
import { SlideMenuModule } from "primeng/slidemenu";
import { SliderModule } from "primeng/slider";
import { SpeedDialModule } from "primeng/speeddial";
import { SpinnerModule } from "primeng/spinner";
import { SplitButtonModule } from "primeng/splitbutton";
import { SplitterModule } from "primeng/splitter";
import { StepsModule } from "primeng/steps";
import { TabMenuModule } from "primeng/tabmenu";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { TagModule } from "primeng/tag";
import { TerminalModule } from "primeng/terminal";
import { TieredMenuModule } from "primeng/tieredmenu";
import { TimelineModule } from "primeng/timeline";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TriStateCheckboxModule } from "primeng/tristatecheckbox";
import { TreeModule } from "primeng/tree";
import { TreeSelectModule } from "primeng/treeselect";

import { CardModule } from "primeng/card";
import { BlockUIModule } from "primeng/blockui";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TaskListComponent } from "./components/job/task-list/task-list.component";
import { JobFilesComponent } from "./components/job/job-files/job-files.component";
import { AnalysisAndQaComponent } from "./components/job/analysis-and-qa/analysis-and-qa.component";
import { PlanningComponent } from "./components/job/planning/planning.component";
import { ApprovesComponent } from "./components/job/approves/approves.component";
import { JobCommentsComponent } from "./components/job/job-comments/job-comments.component";
import { ChildJobsTableComponent } from "./components/job/child-jobs-table/child-jobs-table.component";
import { JobLogsComponent } from "./components/job/job-logs/job-logs.component";

import { JobApprovalTypeModule } from "./tables/job-approval-type/job-approval-type.module";
import { JobApprovalTypeComponent } from "./tables/job-approval-type/job-approval-type/job-approval-type.component";
import { AddJobApprovalTypeComponent } from "./tables/job-approval-type/add-job-approval-type/add-job-approval-type.component";
import { EditJobApprovalTypeComponent } from "./tables/job-approval-type/edit-job-approval-type/edit-job-approval-type.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    KanbanBoardComponent,
    RegisterComponent,
    JobTypesComponent,
    AddJobTypesComponent,
    EditJobTypeComponent,
    AreYouSureComponent,
    CustomerComponent,
    AddCustomerComponent,
    DeleteCustomerComponent,
    EditCustomerComponent,
    JobPriorityComponent,
    AddJobPriorityComponent,
    EditJobPriorityComponent,
    DeleteJobPriorityComponent,
    JobStatusesComponent,
    AddJobStatusComponent,
    DeleteJobStatusComponent,
    EditJobStatusComponent,
    ProjectsTableComponent,
    AddProjectComponent,

    JobApprovalTypeComponent,
    AddJobApprovalTypeComponent,
    EditJobApprovalTypeComponent,

    DeleteProjectComponent,
    ProjectUsersComponent,
    AddProjectUserComponent,
    EditProjectUserComponent,
    DeleteProjectUserComponent,
    JobComponent,
    JobListComponent,
    JobCommentsComponent,
    ChildJobsTableComponent,
    JobLogsComponent,

    LogsComponent,
    AddLogComponent,
    EditLogComponent,
    DeleteLogComponent,
    UsersComponent,
    AddUserComponent,
    DeleteUserComponent,
    EditUserComponent,
    CreateJobPageComponent,
    CreateTaskComponent,
    TaskListComponent,
    JobFilesComponent,
    PlanningComponent,
    AnalysisAndQaComponent,
    TaskDetailComponent,
    ApprovesComponent,
    EditJobComponent,
    ProfileComponent,
    SprintComponent,
    AddSprintComponent,
    DeleteSprintComponent,
    EditSprintComponent,
    DataTableComponent,
    DeleteFileComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    DragulaModule.forRoot(),
    JobTypesModule,
    NgxDatatableModule,
    CustomerModule,
    JobPriorityModule,
    JobStatusesModule,
    ProjectsTableModule,
    ProjectUsersModule,
    JobModule,
    LogsModule,
    UsersModule,
    ProjectModule,
    NgxDropzoneModule,
    AngularEditorModule,

    MatNativeDateModule,

    A11yModule,
    CdkAccordionModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,

    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    ConfirmationsModule,
    NgxMaskModule.forRoot(),

    TreeTableModule,
    ToastModule,
    DialogModule,
    ButtonModule,
    MultiSelectModule,
    InputTextModule,
    ContextMenuModule,

    AccordionModule,
    AutoCompleteModule,
    BadgeModule,
    BreadcrumbModule,
    BlockUIModule,
    ButtonModule,
    CalendarModule,
    CarouselModule,
    CascadeSelectModule,
    CheckboxModule,
    ChipsModule,
    ChipModule,
    ColorPickerModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ContextMenuModule,
    VirtualScrollerModule,
    DataViewModule,
    DialogModule,
    DividerModule,
    DockModule,
    DragDropModule,
    DropdownModule,
    DynamicDialogModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    GalleriaModule,
    InplaceModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    ImageModule,
    KnobModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrganizationChartModule,
    OrderListModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    SelectButtonModule,
    SidebarModule,
    ScrollPanelModule,
    ScrollTopModule,
    SkeletonModule,
    SlideMenuModule,
    SliderModule,
    SpeedDialModule,
    SpinnerModule,
    SplitterModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TagModule,
    TerminalModule,
    TieredMenuModule,
    TimelineModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TriStateCheckboxModule,
    TreeModule,
    TreeSelectModule,
    TreeTableModule,
    CardModule,

    JobApprovalTypeModule,
  ],
  providers: [
    AuthService,
    AdminGuard,
    SecureInnerPagesGuard,
    CookieService,
    HttpClientService,
    JobTypesService,
    JobStatusesService,
    JwtHelperService,
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    // { provide: LOCALE_ID, useValue: "tr-TR" },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
