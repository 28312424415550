<app-breadcrumb [title]="'Projeler'" [items]="['Projeler']"> </app-breadcrumb>
<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <h4>İş Listesi</h4>
    </div>
    <div class="card-body">
      <ngx-datatable
        #myTasksDataTable
        class="bootstrap hover"
        [rows]="jobs"
        [columns]="myTaskColumns"
        [columnMode]="'force'"
        headerHeight="auto"
        rowHeight="auto"
        [footerHeight]="undefined"
        [limit]="5"
        (activate)="onActivate2($event)"
      >
        <ngx-datatable-column
          [width]="30"
          *ngFor="let column of myTaskColumns; let i = index"
          name="{{ column.name }}"
          prop="{{ column.prop }}"
        >
        </ngx-datatable-column>
        <ngx-datatable-column minWidth="300" [sortable]="false" name="">
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template
          >
            <div style="max-width: 150px">
              <button class="btn btn-primary" (click)="onClick(row.id)">
                <span
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <app-feather-icons style="margin-right: 5px" [icon]="'edit'">
                  </app-feather-icons>
                  <span>Task'ları Görüntüle</span>
                </span>
              </button>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>

  <!-- Grid view-->
  <!-- <div class="row">
    <div class="col-md-12 project-list">
      <div class="card">
        <div class="row">
          <div class="col-sm-6">
            <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="top-home-tab"
                  data-toggle="tab"
                  href="javascript:void(0)"
                  role="tab"
                  aria-controls="top-home"
                  aria-selected="true"
                  ><i data-feather="target"></i>All</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="profile-top-tab"
                  data-toggle="tab"
                  href="javascript:void(0)"
                  role="tab"
                  aria-controls="top-profile"
                  aria-selected="false"
                  ><i data-feather="info"></i>Doing</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="contact-top-tab"
                  data-toggle="tab"
                  href="javascript:void(0)"
                  role="tab"
                  aria-controls="top-contact"
                  aria-selected="false"
                  ><i data-feather="check-circle"></i>Done</a
                >
              </li>
            </ul>
          </div>
          <div class="col-sm-6">
            <div class="text-right">
              <div class="form-group mb-0 mr-0"></div>
              <a class="btn btn-primary" (click)="AddJobs.openModal()">
                <i data-feather="plus-square"> </i>Yeni İş Oluştur</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header text-center" *ngIf="projectObj">
          <strong>{{ projectObj.name }}-{{ projectObj.code }}</strong>
        </div>
        <div class="card-body">
          <div class="tab-content" id="top-tabContent">
            <div
              class="tab-pane fade show active"
              id="top-home"
              role="tabpanel"
              aria-labelledby="top-home-tab"
            >
              <div class="row">
                <div
                  class="col-xl-4 col-lg-6"
                  *ngFor="let job of jobs; let i = index"
                  (click)="onClick(i)"
                >
                  <div class="job-box">
                    <div class="badge-box">
                      <span
                        [ngClass]="{
                          'badge-light text-dark':
                            job.jobStatusName === 'Test Aşamasında',
                          'badge-primary':
                            job.jobStatusName === 'Geliştiriliyor',
                          'badge-info': job.jobStatusName === 'İş Oluşturuldu',
                          'badge-warning text-dark':
                            job.jobStatusName === 'Beklemede',
                          'badge-success': job.jobStatusName === 'Tamamlandı',
                          'badge-danger': job.jobStatusName === 'İptal Edildi',
                          'badge-dark': job.jobStatusName === 'Test Bekliyor',
                          'badge-success': job.jobStatusName === 'Yayına hazır',
                          'badge-secondary': job.jobStatusName === 'deneme'
                        }"
                        class="badge"
                        >{{ job.jobStatusName }}</span
                      >
                      <span
                        [ngClass]="{
                          'badge-success': job.jobPriorityName === 'Az',
                          'badge-light text-dark':
                            job.jobPriorityName === 'Normal',
                          'badge-warning text-dark':
                            job.jobPriorityName === 'Önemli',
                          'badge-danger': job.jobPriorityName === 'Çok Önemli'
                        }"
                        class="badge"
                        >{{ job.jobPriorityName }}</span
                      >
                    </div>
                    <h5>{{ job.name }}</h5>
                    <h6>{{ job.topic }}</h6>
                    <div class="user-box">
                     
                      <div class="media">
                        <img
                          class="img-20 mr-1 rounded-circle"
                          src="assets/images/user/3.jpg"
                          alt=""
                          data-original-title=""
                          title=""
                        />
                      </div>
                      <span class="col-5">
                        {{ job.createUserName }}
                      </span>
                     
                      <span class="col-5 text-right">
                        {{ job.managerUserName }}
                      </span>
                    
                      <div class="media">
                        <img
                          class="img-20 mr-1 rounded-circle"
                          src="assets/images/user/3.jpg"
                          alt=""
                          data-original-title=""
                          title=""
                        />
                      </div>
                     
                    </div>
                    <p>{{ job.details }}</p>
                    <div class="row details">
                      <div class="col-8"><span>Öngörülen gün sayısı</span></div>
                      <div class="col-4 text-primary">
                        {{ job.estimatedDay }}
                      </div>
                      <div class="col-8"><span>Asıl gün sayısı</span></div>
                      <div class="col-4 text-primary">{{ job.actualDay }}</div>
                      <div class="col-8">
                        <span>Fatura edilen gün sayısı</span>
                      </div>
                      <div class="col-4 text-primary">{{ job.billedDay }}</div>
                    </div>
                    
                    <div class="customers">
                      <ul>
                        <li class="d-inline-block">
                          <img
                            class="img-30 rounded-circle"
                            src="assets/images/user/3.jpg"
                            alt=""
                            data-original-title=""
                            title=""
                          />
                        </li>
                        <li class="d-inline-block">
                          <img
                            class="img-30 rounded-circle"
                            src="assets/images/user/5.jpg"
                            alt=""
                            data-original-title=""
                            title=""
                          />
                        </li>
                        <li class="d-inline-block">
                          <img
                            class="img-30 rounded-circle"
                            src="assets/images/user/1.jpg"
                            alt=""
                            data-original-title=""
                            title=""
                          />
                        </li>
                        <li class="d-inline-block ml-2">
                          <p class="f-12">+10 More</p>
                        </li>
                      </ul>
                    </div>
                    
                    <div class="job-status mt-4">
                      <div class="media mb-0">
                        <p>80%</p>
                        <div class="media-body text-right">
                          <span>Done</span>
                        </div>
                      </div>
                      <div class="progress" style="height: 5px">
                        <div
                          class="progress-bar-animated bg-primary progress-bar-striped"
                          role="progressbar"
                          style="width: 70%"
                          aria-valuenow="10"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>
<!-- Container-fluid Ends-->

<!-- Container-fluid Ends-->
<app-create-job #addCreateJob (onSave)="updateTable()"></app-create-job>
