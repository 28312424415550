import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CustomerRoutingModule } from "./customer-routing.module";
import { TableModule } from "primeng/table";
import { InputTextModule } from "primeng/inputtext";

@NgModule({
  declarations: [],
  imports: [CommonModule, CustomerRoutingModule, TableModule, InputTextModule],
})
export class CustomerModule {}
