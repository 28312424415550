import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";

import { AuthService } from "./auth.service";
import * as CryptoJS from "crypto-js";

@Injectable()
export class HttpClientService {
  err: any;

  user: any;

  public baseUrl = "https://wfapi.monassist.com/api";
  //public baseUrl = "https://localhost:7195/api";

  constructor(
    public http: HttpClient,
    public authService: AuthService,
    private router: Router
  ) {
    //this.user = JSON.parse(sessionStorage.getItem('user'));
    //this.user = this.authService.decyptedUser;

    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);

    let subject = new BehaviorSubject(this.user);
  }

  protected baseUrlUpdate(url: string): string {
    return url.startsWith("/") ? this.baseUrl + url : url;
  }

  async httpLoginPost(
    endPoint,
    data,
    values?: boolean,
    auth?: boolean
  ): Promise<any> {
    if (auth) {
      return this.http
        .post(this.baseUrl + endPoint, data)
        .toPromise()
        .catch((err) => {
          throw err;
        });
    } else {
      //this.user = this.authService.decyptedUser;

      return this.http
        .post(this.baseUrl + endPoint, data, {
          headers: {
            Authorization: `bearer ${this.user.token}`,
          },
        })
        .toPromise();
    }
  }

  async httpGet(endPoint: string): Promise<any> {
    //let user = { token: "" };
    if (sessionStorage.getItem("user")) {
      var decrypted = CryptoJS.AES.decrypt(
        sessionStorage.getItem("user"),
        "2233**superkeyKANLIsuperkey2233*"
      ).toString(CryptoJS.enc.Utf8);

      this.user = JSON.parse(decrypted);
    } else {
      this.router.navigateByUrl("/auth/login");
    }

    var reqHeader = new HttpHeaders({
      "content-type": "application/json",
      Authorization: "Bearer " + this.user.token,
    });
    try {
      return await this.http
        .get(this.baseUrlUpdate(endPoint), { headers: reqHeader })
        .toPromise();
    } catch (err) {
      console.log(err);
      if (err.status === 401) {
        try {
          await this.refreshToken();
          // Yeniden dene
          return await this.httpGet(endPoint);
        } catch (refreshErr) {
          console.error("Token yenileme sonrası hata:", refreshErr);
          sessionStorage.removeItem("user");
          this.router.navigateByUrl("/auth/login");
          throw refreshErr;
        }
      } else if (err.status === 511) {
        console.log("err123: ", err);
        sessionStorage.removeItem("user");
        this.router.navigateByUrl("/auth/login");
      }
      throw err;
    }
  }

  async httpPost(endPoint: string, data: any): Promise<any> {
    //let user = this.authService.decyptedUser;

    //let user = JSON.parse(sessionStorage.getItem("user"));
    //this.user = this.authService.decyptedUser;

    if (sessionStorage.getItem("user")) {
      var decrypted = CryptoJS.AES.decrypt(
        sessionStorage.getItem("user"),
        "2233**superkeyKANLIsuperkey2233*"
      ).toString(CryptoJS.enc.Utf8);

      this.user = JSON.parse(decrypted);
    } else {
      this.router.navigateByUrl("/auth/login");
    }

    var reqHeader = new HttpHeaders({
      "content-type": "application/json",
      Authorization: "Bearer " + this.user.token,
    });
    try {
      return await this.http
        .post(this.baseUrlUpdate(endPoint), data, { headers: reqHeader })
        .toPromise();
    } catch (err) {
      console.log(err);
      if (err.status === 401) {
        try {
          await this.refreshToken();
          // Yeniden dene
          return await this.httpPost(endPoint, data);
        } catch (refreshErr) {
          console.error("Token yenileme sonrası hata:", refreshErr);
          sessionStorage.removeItem("user");
          this.router.navigateByUrl("/auth/login");
          throw refreshErr;
        }
      } else if (err.status === 511) {
        console.log("err123: ", err);
        sessionStorage.removeItem("user");
        this.router.navigateByUrl("/auth/login");
      }
      throw err;
    }
  }

  async httpGetFile(endPoint: string): Promise<any> {
    //let user = { token: "" };

    //this.user = this.authService.decyptedUser;

    if (sessionStorage.getItem("user")) {
      var decrypted = CryptoJS.AES.decrypt(
        sessionStorage.getItem("user"),
        "2233**superkeyKANLIsuperkey2233*"
      ).toString(CryptoJS.enc.Utf8);

      this.user = JSON.parse(decrypted);
    } else {
      this.router.navigateByUrl("/auth/login");
    }

    var reqHeader = new HttpHeaders({
      Authorization: "Bearer " + this.user.token,
    });
    try {
      return await this.http
        .get(this.baseUrlUpdate(endPoint), { headers: reqHeader })
        .toPromise();
    } catch (err) {
      console.log(err);
      if (err.status === 401) {
        try {
          await this.refreshToken();
          // Yeniden dene
          return await this.httpGetFile(endPoint);
        } catch (refreshErr) {
          console.error("Token yenileme sonrası hata:", refreshErr);
          sessionStorage.removeItem("user");
          this.router.navigateByUrl("/auth/login");
          throw refreshErr;
        }
      } else if (err.status === 511) {
        console.log("err123: ", err);
        sessionStorage.removeItem("user");
        this.router.navigateByUrl("/auth/login");
      }
      throw err;
    }
  }

  async httpPostFile(endPoint: string, data: any): Promise<any> {
    // let user = JSON.parse(sessionStorage.getItem("user"));
    // this.user = this.authService.decyptedUser;

    if (sessionStorage.getItem("user")) {
      var decrypted = CryptoJS.AES.decrypt(
        sessionStorage.getItem("user"),
        "2233**superkeyKANLIsuperkey2233*"
      ).toString(CryptoJS.enc.Utf8);

      this.user = JSON.parse(decrypted);
    } else {
      this.router.navigateByUrl("/auth/login");
    }

    var reqHeader = new HttpHeaders({
      Authorization: "Bearer " + this.user.token,
    });
    try {
      return await this.http
        .post(this.baseUrlUpdate(endPoint), data, {
          headers: reqHeader,
        })
        .toPromise();
    } catch (err) {
      console.log(err);
      if (err.status === 401) {
        try {
          await this.refreshToken();
          // Yeniden dene
          return await this.httpPostFile(endPoint, data);
        } catch (refreshErr) {
          console.error("Token yenileme sonrası hata:", refreshErr);
          sessionStorage.removeItem("user");
          this.router.navigateByUrl("/auth/login");
          throw refreshErr;
        }
      } else if (err.status === 511) {
        console.log("err123: ", err);
        sessionStorage.removeItem("user");
        this.router.navigateByUrl("/auth/login");
      }
      throw err;
    }
  }

  async httpPut(endPoint: string, data: any): Promise<any> {
    if (sessionStorage.getItem("user")) {
      var decrypted = CryptoJS.AES.decrypt(
        sessionStorage.getItem("user"),
        "2233**superkeyKANLIsuperkey2233*"
      ).toString(CryptoJS.enc.Utf8);

      this.user = JSON.parse(decrypted);
      console.log("user: ", this.user);
    } else {
      this.router.navigateByUrl("/auth/login");
      return;
    }

    var reqHeader = new HttpHeaders({
      "content-type": "application/json",
      Authorization: "Bearer " + this.user.token,
    });

    try {
      return await this.http
        .put(this.baseUrlUpdate(endPoint), data, { headers: reqHeader })
        .toPromise();
    } catch (err) {
      console.log("err: ", err);
      if (err.status === 401) {
        try {
          await this.refreshToken();

          // Yeniden dene
          return await this.httpPut(endPoint, data);
        } catch (refreshErr) {
          console.error("Token yenileme sonrası hata:", refreshErr);
          sessionStorage.removeItem("user");
          this.router.navigateByUrl("/auth/login");
          throw refreshErr;
        }
      } else if (err.status === 511) {
        console.log("err123: ", err);
        sessionStorage.removeItem("user");
        this.router.navigateByUrl("/auth/login");
      }
      throw err;
    }
  }

  async httpDelete(endPoint: string, data = {}): Promise<any> {
    //let user = JSON.parse(sessionStorage.getItem("user"));
    //this.user = this.authService.decyptedUser;

    if (sessionStorage.getItem("user")) {
      var decrypted = CryptoJS.AES.decrypt(
        sessionStorage.getItem("user"),
        "2233**superkeyKANLIsuperkey2233*"
      ).toString(CryptoJS.enc.Utf8);

      this.user = JSON.parse(decrypted);
    } else {
      this.router.navigateByUrl("/auth/login");
    }

    var reqHeader = new HttpHeaders({
      "content-type": "application/json",
      Authorization: "Bearer " + this.user.token,
    });
    try {
      return await this.http
        .delete(this.baseUrlUpdate(endPoint), {
          headers: reqHeader,
          body: data,
        })
        .toPromise();
    } catch (err) {
      console.log(err);
      if (err.status === 401) {
        try {
          await this.refreshToken();
          // Yeniden dene
          return await this.httpDelete(endPoint, data);
        } catch (refreshErr) {
          console.error("Token yenileme sonrası hata:", refreshErr);
          sessionStorage.removeItem("user");
          this.router.navigateByUrl("/auth/login");
          throw refreshErr;
        }
      } else if (err.status === 511) {
        console.log("err123: ", err);
        sessionStorage.removeItem("user");
        this.router.navigateByUrl("/auth/login");
      }
      throw err;
    }
  }

  async refreshToken() {
    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    var decrtpyedJson = JSON.parse(decrypted);

    return await this.http
      .post(
        this.baseUrl + "/Auth/refreshToken?userId=" + decrtpyedJson.data.id,
        null
      )
      .toPromise()
      .then((res: any) => {
        //console.log("refreshToken çalıştı: ", res);
        decrtpyedJson.token = res.token;
        decrtpyedJson.expiration = res.expiration;

        var encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(decrtpyedJson),
          "2233**superkeyKANLIsuperkey2233*"
        );

        sessionStorage.setItem("user", encrypted.toString());

        return res;
      })
      .catch((err) => {
        console.error("Token yenileme hatası:", err);
        sessionStorage.removeItem("user");
        this.router.navigateByUrl("/auth/login");
        throw err;
      });
  }
}
