import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { CreateJobComponent } from "src/app/components/job/create-job/create-job.component";
import { JobsService } from "src/app/shared/services/jobs.service";
import { ProjectEmitService } from "src/app/shared/services/projectEmit.service";
import { ProjectsService } from "src/app/shared/services/projects.service";
import { EditProjectComponent } from "../../edit-project/edit-project.component";

import * as XLSX from "xlsx";
import { JobPrioritiesService } from "src/app/shared/services/job-priorities.service";
import { JobStatusesService } from "src/app/shared/services/job-statuses.service";
import { JobTypesService } from "src/app/shared/services/job-types.service";
import * as CryptoJS from "crypto-js";

import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { SprintsService } from "src/app/shared/services/sprints.service";
import { ProjectUsersService } from "src/app/shared/services/project-users.service";

@Component({
  selector: "app-project-detail2",
  templateUrl: "./project-detail2.component.html",
  styleUrls: ["./project-detail2.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", visibility: "hidden" })
      ),
      state("expanded", style({ height: "*", visibility: "visible" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class ProjectDetail2Component implements OnInit {
  @ViewChild("addJobs") AddJobs: CreateJobComponent;
  @ViewChild("editProjects") EditProject: EditProjectComponent;
  @ViewChild("projectDatatable") datatable: DatatableComponent;

  user: any;
  projectID: any = 0;
  selectedIndex: number = 2;
  public customers: any;
  public users: any[] = [];
  public sprints: any[] = [];
  public projectDto: any;
  public projectObj: any;

  public jobs: any;
  public childProjects: any = [];

  public jobPriorities: any[] = [];
  public jobStatuses: any[] = [];
  public jobTypes: any[] = [];

  public filteredData: any;
  public downloadedJobs: any;

  public columns = [
    { prop: "code", name: "İş Kodu", colspan: 2, width: "15%" },
    { prop: "name", name: "İş Adı", colspan: 2, width: "15%" },
    { prop: "sprintName", name: "Sprint", colspan: 1, width: "10%" },
    { prop: "jobTypeName", name: "İş Tipi", colspan: 1, width: "10%" },
    { prop: "jobStatusName", name: "İş Durumu", colspan: 1, width: "12%" },
    {
      prop: "assignedUserName",
      name: "Atanan Kullanıcı",
      colspan: 1,
      width: "15%",
    },
  ];
  public dynamicGlobalFilterFields: any;

  constructor(
    private projectServ: ProjectsService,
    private jobServ: JobsService,

    private projectUserServ: ProjectUsersService,
    private router: Router,
    private emitProjectService: ProjectEmitService,

    private jobPrioritiesService: JobPrioritiesService,
    private sprintService: SprintsService,
    private jobStatusesService: JobStatusesService,
    private jobTypesService: JobTypesService,

    private modalService: NgbModal,
    private snackBarService: SnackbarService
  ) {}

  async ngOnInit() {
    this.dynamicGlobalFilterFields = this.columns.map((col) => col.prop);

    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);
    if (localStorage.getItem("projectDetailFilters")) {
      this.filters = JSON.parse(localStorage.getItem("projectDetailFilters"));
    }

    localStorage.removeItem("sprintDetailFilters");
    localStorage.removeItem("selectedSprint");
    localStorage.removeItem("selectedCustomer");
    localStorage.removeItem("selectedIsActive");
    localStorage.removeItem("jobsFilters");

    this.projectID = localStorage.getItem("projectId");
    //await this.getEmit();

    await this.jobServ
      .getProjectJobsWithChild(this.projectID)
      .then((resp) => {
        this.jobs = resp;

        this.jobs = resp.filter((job) => {
          const isParentActive = !(
            job.jobStatusName === "Yayınlandı" ||
            job.jobStatusName === "İptal edildi, Yapılmayacak"
          );

          // Child işlerde aktif olan var mı
          const hasActiveChild =
            job.child &&
            job.child.some((child) => {
              return !(
                child.jobStatusName === "Yayınlandı" ||
                child.jobStatusName === "İptal edildi, Yapılmayacak"
              );
            });

          // Parent iş aktifse veya aktif child işlere sahipse dahil et
          return isParentActive || hasActiveChild;
        });

        this.filteredData = this.convertToPrimeNgTreeData(this.jobs);

        if (this.filters) {
          //this.filteredData = this.applyFilters(this.filteredData, this.filters);
          if (
            this.filters.jobStatusName.includes("Yayınlandı") ||
            this.filters.jobStatusName.includes("İptal edildi, Yapılmayacak")
          ) {
            this.filterJobStatus(this.filters.jobStatusName);
          }

          this.filterData("", "");
        }
      })
      .catch((error) => console.log(error));
    await this.projectServ.getByDTOId(this.projectID).then((resp) => {
      this.projectDto = resp[0];
      //console.log(this.projectDto);
    });

    await this.projectServ.getById(this.projectID).then((resp) => {
      this.projectObj = resp;
      this.projectUserServ
        .getListByDTOProjectID(this.projectObj.id)
        .then((resp) => {
          this.users = resp;
        });
    });
    await this.sprintService
      .getByDTOCustomerId(this.projectObj.customerId, null)
      .then((resp) => {
        this.sprints = resp;
      });

    await this.jobPrioritiesService.getAllJobPriorities().then((resp) => {
      this.jobPriorities = resp;
    });

    await this.jobStatusesService.getAllJobStatuses().then((resp) => {
      this.jobStatuses = resp;
    });
    await this.jobTypesService.getAllJobTypes().then((resp) => {
      this.jobTypes = resp;
    });
  }

  async getEmit() {
    await this.emitProjectService.getEmit.subscribe((data) => {
      this.updatePage();
    });
  }

  async updatePage() {
    await this.jobServ
      .getProjectJobsWithChild(this.projectID)
      .then((resp) => {
        this.jobs = resp;
        this.jobs = resp.filter((job) => {
          const isParentActive = !(
            job.jobStatusName === "Yayınlandı" ||
            job.jobStatusName === "İptal edildi, Yapılmayacak"
          );

          // Child işlerde aktif olan var mı
          const hasActiveChild =
            job.child &&
            job.child.some((child) => {
              return !(
                child.jobStatusName === "Yayınlandı" ||
                child.jobStatusName === "İptal edildi, Yapılmayacak"
              );
            });

          // Parent iş aktifse veya aktif child işlere sahipse dahil et
          return isParentActive || hasActiveChild;
        });

        this.filteredData = this.convertToPrimeNgTreeData(this.jobs);

        if (this.filters) {
          //this.filteredData = this.applyFilters(this.filteredData, this.filters);
          if (
            this.filters.jobStatusName.includes("Yayınlandı") ||
            this.filters.jobStatusName.includes("İptal edildi, Yapılmayacak")
          ) {
            this.filterJobStatus(this.filters.jobStatusName);
          }

          this.filterData("", "");
        }
      })
      .catch((error) => console.log(error));
    await this.projectServ.getByDTOId(this.projectID).then((resp) => {
      this.projectDto = resp[0];
      // console.log(this.projectDto);
    });
  }

  convertToPrimeNgTreeData(data) {
    return data.map((item) => {
      const node: any = {
        data: {
          id: item.id,
          code: item.code,
          projectName: item.projectName,
          jobStatusName: item.jobStatusName,
          jobTypeName: item.jobTypeName,
          name: item.name,
          details: item.details,
          assignedUserName: item.assignedUserName,
          sprintName: item.sprintName,
          customerName: item.customerName,
          estimatedDay: item.estimatedDay,
          billedDay: item.billedDay,
          isBilled: item.isBilled,
          testDate: item.testDate,
          prodDate: item.prodDate,
        },
        children: [],
      };

      if (item.child && item.child.length > 0) {
        node.children = this.convertToPrimeNgTreeData(item.child);
      }

      return node;
    });
  }

  onClickView(index: number) {
    this.selectedIndex = index;
    if (this.selectedIndex === 1) {
    } else if (this.selectedIndex === 2) {
    }
  }

  onClick(id) {
    localStorage.setItem("jobId", id.toString());
    this.router.navigateByUrl("/jobs/job-detail");
    localStorage.setItem("location", "projects/project-detail");
  }

  async export() {
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const jobsToExcell = this.filteredData;

    // Sütun başlıklarını tanımlayın ve sıralı bir dizi kullanın
    const columnHeaders = [
      { key: "projectName", header: "Proje Adı" },
      { key: "code", header: "İş Kodu" },
      { key: "name", header: "İş Adı" },
      { key: "details", header: "Detay" },
      { key: "jobTypeName", header: "İş Tipi" },
      { key: "jobStatusName", header: "İş Durumu" },
      { key: "estimatedDay", header: "Toplam Efor" },
      { key: "billedDay", header: "Faturalanan Efor" },
      { key: "sprintName", header: "Sprint" },
      { key: "isBilled", header: "Fatura Durum" },
      { key: "testDate", header: "Test Tarihi" },
      { key: "prodDate", header: "Yayınlanma Tarihi" },
    ];

    // Gereksiz alanları kaldırarak güncellenmiş iş kayıtlarını oluşturun
    const flattenJob = (job) => {
      const updatedJob = {};
      // Verinin 'data' alanı varsa oradan alalım, yoksa job objesini kullanacağız
      const source = job.data || job;

      columnHeaders.forEach((column) => {
        const { key, header } = column;
        if (source[key] !== undefined && source[key] !== null) {
          // Alan varsa ve null değilse
          if (key === "isBilled") {
            updatedJob[header] = source[key]
              ? "Fatura Edildi"
              : "Fatura Edilmedi";
          } else {
            updatedJob[header] = source[key];
          }
        } else {
          updatedJob[header] = ""; // Eksik alanları boş bırak
        }
      });
      return updatedJob;
    };

    // Eğer child varsa onları da düzleştireceğiz
    const updatedJobsToExcell = [];
    jobsToExcell.forEach((job) => {
      updatedJobsToExcell.push(flattenJob(job)); // Ana işi ekle
      if (job.children && job.children.length > 0) {
        job.children.forEach((childJob) => {
          updatedJobsToExcell.push(flattenJob(childJob)); // Child işleri ekle
        });
      }
    });

    // Excel dosyası oluşturuluyor
    const workSheet = XLSX.utils.json_to_sheet(updatedJobsToExcell, {
      header: columnHeaders.map((column) => column.header),
    });

    XLSX.utils.book_append_sheet(workBook, workSheet, "data"); // add the worksheet to the book

    const fileName = this.projectDto.name + ".xlsx";
    XLSX.writeFile(workBook, fileName); // initiate a file download in browser
  }

  public filters = {
    sprintName: [],
    jobStatusName: [],
    jobTypeName: [],
    createUserName: [],
    assignedUserName: [],
    isBilled: [],
  };

  bools = [
    { name: "Fatura Edildi", value: true },
    { name: "Fatura Edilmedi", value: false },
  ];

  setFilters() {
    localStorage.setItem("projectDetailFilters", JSON.stringify(this.filters));
  }

  filterData(data, condition: any) {
    // this.filters[condition] = data;

    const tempData = Object.keys(this.filters).reduce((prev, cur) => {
      // Eğer filtre array'i boşsa, o filtreyi atla
      if (this.filters[cur].length === 0) {
        return prev;
      }

      return prev
        .map((d) => {
          // Parent işlerin filtrelenmesi
          const isParentMatching = 
            this.filters[cur].includes(d[cur]) ||
            this.filters[cur].includes(String(d[cur]));

          // Child işlerin filtrelenmesi
          const filteredChildren = d.child
            ? d.child.filter((child) => {
                return (
                  this.filters[cur].includes(String(child[cur])) ||
                  this.filters[cur].includes(child[cur])
                );
              })
            : [];

          // Eğer parent iş ya da herhangi bir child koşulu sağlıyorsa
          if (isParentMatching || filteredChildren.length > 0) {
            return {
              ...d,
              child: filteredChildren.length > 0 ? filteredChildren : d.child, // Filtrelenmiş ya da orijinal child listesi
            };
          } else {
            return null; // Koşulu sağlamayan işleri döndürme
          }
        })
        .filter((item) => item !== null); // Null olan parent'ları çıkar
    }, this.jobs);

    this.filteredData = this.convertToPrimeNgTreeData(tempData);
  }

  async filterJobStatus(name: any[]) {
    if (
      name.includes("Yayınlandı") ||
      name.includes("İptal edildi, Yapılmayacak")
    ) {
      await this.jobServ
        .getProjectJobsWithChild(this.projectID)
        .then((resp) => {
          this.jobs = resp;

          this.filterData(name, "jobStatusName");
        });
    } else {
      // console.log("else-if");
      await this.jobServ
        .getProjectJobsWithChild(this.projectID)
        .then((resp) => {
          this.jobs = resp;
          this.jobs = resp.filter((job) => {
            const isParentActive = !(
              job.jobStatusName === "Yayınlandı" ||
              job.jobStatusName === "İptal edildi, Yapılmayacak"
            );

            // Child işlerde aktif olan var mı
            const hasActiveChild =
              job.child &&
              job.child.filter((child) => {
                return !(
                  child.jobStatusName === "Yayınlandı" ||
                  child.jobStatusName === "İptal edildi, Yapılmayacak"
                );
              });

            // Parent iş aktifse veya aktif child işlere sahipse dahil et
            return isParentActive || hasActiveChild.length > 0;
          });

          this.filteredData = this.convertToPrimeNgTreeData(this.jobs);
        });
      this.filterData(name, "jobStatusName");
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.filteredData, event.previousIndex, event.currentIndex);
    console.log(event);

    this.filteredData[event.previousIndex].sprintOrder = event.currentIndex;
    console.log("sorted: ", this.filteredData);
  }

  deleteJob(id) {
    console.log(id);
    if (this.user.data.claimId > 4) {
      this.jobServ
        .deleteJobs(id)
        .then((resp) => {
          this.updatePage();
          this.snackBarService.openSnackBar(true);
        })
        .catch((err) => {
          this.modalService.dismissAll();
          this.snackBarService.openSnackBar(false);
        });
    } else {
      this.modalService.dismissAll();
      this.snackBarService.openSnackBar(false);
    }
  }

  closeResult: any;
  openModal(content, id) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
        centered: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.deleteJob(id);
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
export type JobType =
  | "jobTypeName"
  | "jobStatusName"
  | "sprint"
  | "createUserName"
  | "assignedUserName"
  | "isBilled"
  | "reset";
