<div class="media profile-media">
  <img
    class="b-r-10"
    src="assets/images/dashboard/profile.jpg"
    style="width: 40px !important"
    alt=""
  />
  <div class="media-body">
    <span>{{ user.data.fullName }}</span>
    <p class="mb-0 font-roboto">
      {{ claimName }} <i class="middle fa fa-angle-down"></i>
    </p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li>
    <div (click)="profileClick()">
      <app-feather-icons [icon]="'user'"></app-feather-icons><span>Hesap </span>
    </div>
  </li>
  <!-- <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li>
    <a (click)="logout()"
      ><app-feather-icons [icon]="'log-in'"></app-feather-icons
      ><span>Çıkış yap</span></a
    >
  </li>
</ul>
