import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProjectsService } from "src/app/shared/services/projects.service";
import { ProjectEmitService } from "src/app/shared/services/projectEmit.service";
import { isPlatformBrowser } from "@angular/common";
import { CustomerService } from "src/app/shared/services/customer.service";
import { UserService } from "src/app/shared/services/user.service";
import { AuthService } from "src/app/shared/services/auth.service";
import * as CryptoJS from "crypto-js";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { JobsService } from "src/app/shared/services/jobs.service";

@Component({
  selector: "app-edit-project",
  templateUrl: "./edit-project.component.html",
  styleUrls: ["./edit-project.component.scss"],
})
export class EditProjectComponent implements OnInit {
  @Input() id: any;
  public users: any;
  public customers: any;
  public closeResult: string;
  public modalOpen: boolean = false;

  public projectObj: any;
  public projectDto: any;

  public user: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private projectServ: ProjectsService,
    private emitProjectService: ProjectEmitService,
    private jobService: JobsService,
    private customerService: CustomerService,
    private userService: UserService,
    private authService: AuthService,
    private snackBarService: SnackbarService
  ) {}

  validationForm: FormGroup;

  async ngOnInit() {
    //this.user = this.authService.decyptedUser;

    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);

    await this.customerService.getAllCustomers().then((res) => {
      this.customers = res;
    });

    // if (this.user.data.claimId > 3) {
    //   await this.userService.getAllUsersDTO().then((res) => {
    //     this.users = res;
    //   });
    // } else {

    // }
  }

  validation() {}
  open(content) {
    this.getProjectData();
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  projectData = {
    code: "",
    customerId: 0,
    dayApproverUserId: 0,
    id: 0,
    analysisApproverUserId: 0,
    managerUserId: 0,
    parentProjectId: null,
    name: "",
    isActive: true,
  };
  async getProjectData() {
    //console.log(this.id);
    await this.projectServ.getById(this.id).then((resp) => {
      this.projectData = resp;
      this.userService
        .getByCustomerId(this.projectData.customerId)
        .then((res) => {
          this.users = res;
        });
    });

    await this.projectServ.getByDTOId(this.id).then((res) => {
      this.projectDto = res;
    });
  }

  isActiveChange() {
    let count = 0;
    this.jobService.getJobsByDTOProjectID(this.id).then((res) => {
      res.forEach((element) => {
        if (
          element.isActive == true &&
          element.jobStatusName != "Yayınlandı" &&
          element.jobStatusName != "İptal edildi, Yapılmayacak"
        ) {
          count++;
        }
      });

      if (count > 0) {
        this.snackBarService.openSnackBarWithMessageError(
          "Projenin altında aktif işler bulunmaktadır. Pasife alamazsınız."
        );
        this.projectData.isActive = true;
      }
    });
  }
  saveProject() {
    this.projectServ
      .updateProjects(this.projectData)
      .then((a) => {
        this.modalService.dismissAll();
        this.emitProjectService.setEmitServis(null);

        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
        this.modalService.dismissAll();
      });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
