import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/shared/services/auth.service";
import { CustomerService } from "src/app/shared/services/customer.service";
import { ClaimService } from "src/app/shared/services/claim.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  @ViewChild("addUsers", { static: false }) addUsers: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  public addUserForm: FormGroup;
  public customers: any;
  public claims: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private authServ: AuthService,
    private customerService: CustomerService,
    private claimService: ClaimService,
    private snackBarService: SnackbarService
  ) {
    this.addUserForm = this.fb.group({
      userName: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
      fullName: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      customerId: ["", [Validators.required]],
      claimId: ["", [Validators.required]],
    });
  }

  async ngOnInit() {
    await this.customerService.getAllCustomers().then((res) => {
      this.customers = res;
    });
    await this.claimService.getAllClaims().then((res) => {
      this.claims = res;
    });
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.addUsers, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.addUserForm.reset();
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  saveUser() {
    var registeredUser = {
      userName: this.addUserForm.value.userName,
      email: this.addUserForm.value.email,
      password: this.addUserForm.value.password,
      fullName: this.addUserForm.value.fullName,
      phone: this.addUserForm.value.phone,
      customerId: this.addUserForm.value.customerId,
      claimId: this.addUserForm.value.claimId,
    };

    this.authServ.register(registeredUser).subscribe((a) => {
      this.addUserForm.reset();
      this.modalService.dismissAll();

      this.onSave.emit();
      this.snackBarService.openSnackBar(true);
    });
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
