import { Component, OnInit, ViewChild } from "@angular/core";
import { SprintsService } from "src/app/shared/services/sprints.service";
import { AddSprintComponent } from "./add-sprint/add-sprint.component";
import { DeleteSprintComponent } from "./delete-sprint/delete-sprint.component";
import { EditSprintComponent } from "./edit-sprint/edit-sprint.component";

@Component({
  selector: "app-sprint",
  templateUrl: "./sprint.component.html",
  styleUrls: ["./sprint.component.scss"],
})
export class SprintComponent implements OnInit {
  @ViewChild("addSprints") AddSprint: AddSprintComponent;
  @ViewChild("editSprints") EditSprint: EditSprintComponent;
  @ViewChild("deleteSprint") DeleteSprint: DeleteSprintComponent;
  public outputID: number;
  public sprints: any = [{}];
  public columns = [
    { prop: "name", name: "İsim" },
    { prop: "customerName", name: "Kurum" },
    { prop: "startDate", name: "Başlangıç Tarihi" },
    { prop: "endDate", name: "Bitiş Tarihi" },
  ];
  public dynamicGlobalFilterFields: any;

  constructor(private sprintService: SprintsService) {}

  ngOnInit() {
    this.dynamicGlobalFilterFields = this.columns.map((col) => col.prop);

    this.sprintService.getDTOList().then((res) => {
      this.sprints = res;
    });
  }
  async updateTable() {
    this.sprintService.getDTOList().then((res) => {
      this.sprints = res;
    });
  }
}
