import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LogsService } from "src/app/shared/services/logs.service";

@Component({
  selector: "app-add-log",
  templateUrl: "./add-log.component.html",
  styleUrls: ["./add-log.component.scss"],
})
export class AddLogComponent implements OnInit {
  @ViewChild("addLogs", { static: false }) addLogs: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  public addLogForm: FormGroup;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private logServ: LogsService
  ) {
    this.addLogForm = this.fb.group({
      detail: ["", [Validators.required]],
      date: ["", [Validators.required]],
      audit: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {}

  openModal() {
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.addLogs, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.addLogForm.reset();
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  saveLog() {
    this.logServ
      .addLogs({
        name: this.addLogForm.value.name,
      })
      .then((a) => {
        this.addLogForm.reset();
        this.modalService.dismissAll();
        this.onSave.emit();
      });
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
