import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class JobCommentsService {

  constructor(private http: HttpClientService) { }


  async getById(jobCommentID: Number) {
    return await this.http
      .httpGet("/JobComments/getbyid?jobCommentID=" + jobCommentID)
      .then((res) => {
        return res.data;
      });
  }
  async getByDTOId(jobCommentID: Number) {
    return await this.http
      .httpGet("/JobComments/getbydtoid?jobCommentID=" + jobCommentID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobComments/getlistbyjobid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByDTOJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobComments/getlistbydtojobid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }

  async addJobComments(data) {
    return await this.http.httpPost("/JobComments/add", data);
  }
  async deleteJobComments(data) {
    return await this.http.httpDelete("/JobComments/delete", data);
  }
  async updateJobComments(data) {
    return await this.http.httpPut("/JobComments/update", data);
  }
}
