import { Component, OnInit, ViewChild } from "@angular/core";
import { LogsService } from "../../shared/services/logs.service";
import { AddLogComponent } from "./add-log/add-log.component";
import { EditLogComponent } from "./edit-log/edit-log.component";
import { DeleteLogComponent } from "./delete-log/delete-log.component";

@Component({
  selector: "app-logs",
  templateUrl: "./logs.component.html",
  styleUrls: ["./logs.component.scss"],
})
export class LogsComponent implements OnInit {
  @ViewChild("addLogs") AddLogs: AddLogComponent;
  @ViewChild("editLogs") EditLog: EditLogComponent;
  @ViewChild("areyousure") DeleteLogs: DeleteLogComponent;
  public outputID: number;
  public logs = [{}];
  public columns = [
    { prop: "id", name: "Numara" },
    { prop: "detail", name: "Detaylar" },
    { prop: "date", name: "Tarih" },
    { prop: "audit", name: "Log Tipi" },
  ];

  constructor(private logServ: LogsService) {}
  ngOnInit() {
    this.logServ.getAllLogs().then((resp) => {
      this.logs = resp;
    });
  }
  async updateTable() {
    await this.logServ.getAllLogs().then((resp) => {
      this.logs = resp;
    });
  }
}
