import { Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";

export interface IQuestion {
  email: string;
  password: string;
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  public user: any;
  public userData: any;
  constructor(private http: HttpClientService) {}

  async getUserById(id: number) {
    return await this.http
      .httpGet("/Auth/getuserbyid?userId=" + id)
      .then((resp) => {
        return resp.data;
      });
  }

  async getList(claimID = null, customerID = null, userName = null) {
    return await this.http
      .httpGet(
        "/Auth/getList?claimID=" +
          claimID +
          "&customerID=" +
          customerID +
          "&userName=" +
          userName
      )
      .then((resp) => {
        return resp.data;
      });
  }

  async authUser(user: any = null) {
    if (user) this.user = user;

    return this.user;
  }
  saveUser(user: any) {
    const userString = JSON.stringify(user);
    sessionStorage.setItem("user", userString);

    this.user = user;
  }

  async getAllUsers() {
    return await this.http.httpGet("/Users/getall").then((res) => {
      return res.data;
    });
  }

  async getAllUsersDTO() {
    return await this.http.httpGet("/Users/getdtoall").then((res) => {
      return res.data;
    });
  }

  async getByCustomerId(customerId: Number) {
    return await this.http
      .httpGet("/Users/Getbycustomerid?customerID=" + customerId)
      .then((res) => {
        return res.data;
      });
  }
  async getByDTOCustomerId(customerId: Number) {
    return await this.http
      .httpGet("/Users/Getbydtocustomerid?customerID=" + customerId)
      .then((res) => {
        return res.data;
      });
  }
  async getById(userID: Number) {
    return await this.http
      .httpGet("/Users/getbyid?userID=" + userID)
      .then((res) => {
        return res.data;
      });
  }

  async getByDTOId(userID: Number) {
    return await this.http
      .httpGet("/Users/getbydtoid?userID=" + userID)
      .then((res) => {
        return res.data;
      });
  }

  async addUsers(data) {
    return await this.http.httpPost("/Users/add", data);
  }
  async deleteUsers(data) {
    return await this.http.httpDelete("/Users/delete", data);
  }
  async updateUsers(data) {
    return await this.http.httpPut("/Users/update", data);
  }
  async updateUserPassword(data, password) {
    return await this.http.httpPut(
      `/Users/updatepassword?password=${password}`,
      data
    );
  }
}
