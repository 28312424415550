import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { JobStatusesService } from "src/app/shared/services/job-statuses.service";
import { JobsService } from "src/app/shared/services/jobs.service";
import { ProjectsService } from "src/app/shared/services/projects.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { SprintsService } from "src/app/shared/services/sprints.service";

@Component({
  selector: "app-planning",
  templateUrl: "./planning.component.html",
  styleUrls: ["./planning.component.css"],
})
export class PlanningComponent implements OnInit {
  @Input() user: any;
  @Input() jobID: number;
  @Output() getEmit: EventEmitter<void> = new EventEmitter<void>();
  @Input() jobDto: any;
  @Input() jobObj: any; //This is for the update

  public projectID: any = 0;
  public projectObj: any;
  public priority: string;

  public sprint: any;
  public sprints: any;
  public planningForm: FormGroup;

  public jobTestForm: FormGroup;

  public minEndDate: any;

  public dayDetails = {
    analysis: "Analiz",
    meetings: "Toplantı",
    infrastructure: "Altyapı",
    database: "Veritabanı",
    backend: "Back-End Geliştirme",
    frontend: "Front-End Geliştirme",
    test: "Testler",
    production: "Yayına Alma",
  };

  public taskTypes: string[] = Object.keys(this.dayDetails);
  public selectedTaskTypes: string[] = [];
  public taskData: { [key: string]: string } = {};

  public onTaskTypeChange() {
    const updatedTaskData: { [key: string]: string } = {};

    for (const taskType of this.selectedTaskTypes) {
      if (this.taskData.hasOwnProperty(taskType)) {
        updatedTaskData[taskType] = this.taskData[taskType];
      } else {
        updatedTaskData[taskType] = "";
      }
    }

    this.taskData = updatedTaskData;
  }

  public onTaskContentChange(taskType: string) {
    // Görev içeriğini JSON'a ekle
    if (this.selectedTaskTypes.includes(taskType)) {
      this.taskData[taskType] = this.taskData[taskType] || "";
    } else {
      delete this.taskData[taskType]; // Seçili olmayan görev türünü taskData'dan kaldır
      console.log(this.taskData);
    }
  }

  public saveEffortDetail() {
    console.log(this.taskData); // Görev verilerini konsola yazdır veya istediğiniz işlemi gerçekleştir

    // Boş değerlere sahip alanları kaldır
    for (const key in this.taskData) {
      if (this.taskData.hasOwnProperty(key) && this.taskData[key] === "") {
        delete this.taskData[key];
      }
    }

    const effortDetailStringfy = JSON.stringify(this.taskData);
    this.jobObj.effortDetail = effortDetailStringfy;
    console.log(this.jobObj);
    console.log(effortDetailStringfy);

    const total = Object.values(this.taskData)
      .map((value) => parseFloat(value))
      .filter((value) => !isNaN(value))
      .reduce((acc, value) => acc + value, null);

    if (this.taskData) {
      this.jobObj.estimatedDay = total;
      // this.jobDto.estimatedDay = total;
    }

    console.log("Toplam: " + total);

    this.savePlan();
  }

  public isPlanEditable: boolean = false;

  constructor(
    private projectService: ProjectsService,
    private sprintService: SprintsService,
    private jobServ: JobsService,
    private jobStatusService: JobStatusesService,
    private snackBarService: SnackbarService,
    private fb: FormBuilder
  ) {
    this.jobTestForm = this.fb.group({
      testDetail: ["", [Validators.required]],
    });
  }

  async ngOnInit() {
    this.projectID = this.jobObj.projectId;

    if (this.jobObj.effortDetail !== null) {
      this.taskData = JSON.parse(this.jobObj.effortDetail);
      console.log(this.taskData);

      this.selectedTaskTypes = Object.keys(this.taskData);
    }

    localStorage.setItem("projectId", this.projectID.toString());

    this.minEndDate = this.jobObj.startDate;
    console.log(this.minEndDate);

    await this.projectService.getById(this.projectID).then((res) => {
      this.projectObj = res;
    });

    await this.sprintService
      .getByDTOCustomerId(this.projectObj.customerId, false)
      .then((res) => {
        this.sprints = res;
      });
  }

  public planningData = {
    startDate: null,
    endDate: null,
    testDate: null,
    prodDate: null,

    sprint: null,

    estimatedDay: null,
    actualDay: null,
    billedDay: null,
  };

  planningClick(bool: boolean) {
    this.isPlanEditable = bool;
  }

  async savePlan() {
    if (this.jobObj.jobStatusId < 12) {
      if (
        this.jobObj.estimatedDay != null &&
        this.jobObj.estimatedDay >= 0 &&
        this.jobDto.estimatedDay != this.jobObj.estimatedDay
      ) {
        console.log(this.jobObj);
        this.jobObj.jobStatusId = 6;
      }

      if (this.jobObj.startDate == "" || this.jobObj.startDate == null) {
        this.jobObj.startDate = null;
        if (
          this.jobObj.estimatedDay != null &&
          this.jobObj.estimatedDay >= 0 &&
          this.jobDto.estimatedDay != this.jobObj.estimatedDay
        ) {
          this.jobObj.jobStatusId = 6;
        }
      }
      if (this.jobObj.startDate != null) {
        this.jobObj.jobStatusId = 8;
      }

      if (this.jobObj.endDate == "" || this.jobObj.endDate == null) {
        this.jobObj.endDate = null;
        if (this.jobObj.startDate != null) {
          this.jobObj.jobStatusId = 8;
        }
      }
      if (this.jobObj.endDate != null) {
        this.jobObj.jobStatusId = 9;
      }

      if (this.jobObj.testDate == "" || this.jobObj.testDate == null) {
        this.jobObj.testDate = null;
        if (this.jobObj.endDate != null) {
          this.jobObj.jobStatusId = 9;
        }
      }
      if (this.jobObj.testDate != null) {
        this.jobObj.jobStatusId = 10;
      }
    }

    if (this.jobObj.billedDay >= 0 && this.jobObj.billedDay != null) {
      this.jobObj.isBilled = true;
    } else if (this.jobObj.billedDay == null) {
      this.jobObj.isBilled = false;
    }

    await this.jobServ
      .updateJobs(this.jobObj)
      .then((res) => {
        this.snackBarService.openSnackBar(true);
        this.updatePage();
        this.getEmit.emit();
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }

  async startJobHandler() {
    const nextStatus = await this.jobStatusService.getById(
      this.jobObj.jobStatusId
    );
    this.jobObj.jobStatusId = nextStatus.nextStatus;
    this.jobObj.startDate = new Date();
    await this.jobServ
      .updateJobs(this.jobObj)
      .then((res) => {
        this.snackBarService.openSnackBar(true);
        this.updatePage();
        this.getEmit.emit();
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }
  async finishJobHandler() {
    const nextStatus = await this.jobStatusService.getById(
      this.jobObj.jobStatusId
    );
    this.jobObj.jobStatusId = nextStatus.nextStatus;
    this.jobObj.endDate = new Date();
    await this.jobServ
      .updateJobs(this.jobObj)
      .then((res) => {
        this.snackBarService.openSnackBar(true);
        this.updatePage();
        this.getEmit.emit();
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }
  async testJobHandler() {
    const nextStatus = await this.jobStatusService.getById(
      this.jobObj.jobStatusId
    );
    this.jobObj.jobStatusId = nextStatus.nextStatus;
    this.jobObj.testDetail = this.jobTestForm.value.testDetail;
    this.jobObj.testDate = new Date();
    await this.jobServ
      .updateJobs(this.jobObj)
      .then((res) => {
        this.snackBarService.openSnackBar(true);
        this.updatePage();
        this.getEmit.emit();
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }
  async prodJobHandler() {
    const nextStatus = await this.jobStatusService.getById(
      this.jobObj.jobStatusId
    );
    this.jobObj.jobStatusId = nextStatus.nextStatus;
    this.jobObj.prodDate = new Date();
    await this.jobServ
      .updateJobs(this.jobObj)
      .then((res) => {
        this.snackBarService.openSnackBar(true);
        this.updatePage();
        this.getEmit.emit();
      })
      .catch((err) => {
        this.snackBarService.openSnackBar(false);
      });
  }

  async updatePage() {
    await this.jobServ.getById(this.jobID).then((res) => {
      this.jobObj = res;
    });
    await this.jobServ.getByDTOId(this.jobID).then((res) => {
      this.jobDto = res;
    });
  }
}
