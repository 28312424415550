<div class="card-body">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <ngx-dropzone class="dropzone digits" (change)="fileChanged($event)">
          <ngx-dropzone-label
            >Dosyanızı sürükleyip bırakınız ya da
            yükleyiniz...</ngx-dropzone-label
          >

          <ngx-dropzone-preview
            *ngFor="let f of files"
            [removable]="true"
            (removed)="onRemove(f)"
          >
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>

      <button
        type="button"
        class="btn btn-success"
        (click)="uploadDocument()"
        [disabled]="files?.length === 0"
      >
        Yükle
      </button>
    </div>
  </div>
  <br />

  <div class="file-content">
    <div class="file-manager">
      <ul class="files">
        <li class="file-box" *ngFor="let jobFile of jobFiles">
          <div class="file-top" ngbDropdown>
            <div (click)="download(jobFile.id)" class="fileDownloadIcon">
              <img
                *ngIf="
                  jobFile.contentType ===
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                "
                src="https://img.icons8.com/color/48/null/microsoft-word-2019--v2.png"
              />
              <img
                *ngIf="
                  jobFile.contentType ===
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                "
                src="https://img.icons8.com/color/48/null/microsoft-excel-2019--v2.png"
              />
              <img
                *ngIf="jobFile.contentType === 'application/pdf'"
                src="https://img.icons8.com/office/48/null/pdf.png"
              />
              <img
                *ngIf="
                  jobFile.contentType === 'image/jpeg' ||
                  jobFile.contentType === 'image/svg+xml' ||
                  jobFile.contentType === 'image/png'
                "
                src="https://img.icons8.com/fluency/48/null/image.png"
              />
              <i
                *ngIf="
                 !( jobFile.contentType ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  jobFile.contentType ===
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                  jobFile.contentType === 'image/jpeg' ||
                  jobFile.contentType === 'image/svg+xml' ||
                  jobFile.contentType === 'image/png' ||
                  jobFile.contentType === 'application/pdf')
                "
                class="pi pi-file knl-icon"
              ></i>
            </div>
            <div class="deleteIcon">
              <button
                class="deleteButton"
                (click)="openModal(fileDelete, jobFile.id)"
              >
                <i class="fa fa-times"></i>
              </button>
            </div>
          </div>
          <div class="file-bottom">
            <h6>{{ jobFile.name }}</h6>
            <p class="mb-1">
              {{ jobFile.fileSize / 1024 / 1024 | number }}
              MB
            </p>
            <p><b>Yükleyen Kullanıcı: </b> {{ jobFile.userName }}</p>
            <p>
              <b>Yüklenme Tarihi: </b>
              {{ jobFile.date | date : "dd.MM.yyyy HH:mm" }}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <br />
</div>

<div class="card" hidden style="border-radius: 20px">
  <div class="card-header">
    <h5>Modal with default options</h5>
  </div>
  <div class="card-body">
    <ng-template #fileDelete let-modal>
      <div class="modal-header">
        <h4 class="modal-title text-danger" id="modal-basic-title">
          Dosyayı Sil
        </h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5>Dosya Silinecek Emin misin?</h5>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          (click)="modal.close('Cross click')"
        >
          Sil
        </button>
        <button
          type="button"
          class="btn btn-success"
          (click)="modal.dismiss('close')"
        >
          Vazgeç
        </button>
      </div>
    </ng-template>
  </div>
</div>

<app-delete-file #deleteFile></app-delete-file>
