import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { JobsService } from "src/app/shared/services/jobs.service";
import * as CryptoJS from "crypto-js";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { JobStatusesService } from "src/app/shared/services/job-statuses.service";
import { CustomerService } from "src/app/shared/services/customer.service";
import { ProjectsService } from "src/app/shared/services/projects.service";

import * as XLSX from "xlsx";

@Component({
  selector: "app-confirmations",
  templateUrl: "./confirmations.component.html",
  styleUrls: ["./confirmations.component.scss"],
})
export class ConfirmationsComponent implements OnInit {
  public user: any;
  public jobsDto: any;
  public filteredData: any;

  public customers: any;
  public projects: any;
  public selectedCustomerId: any;

  public jobStatuses: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  // displayedColumns: any = ["İş Kodu", "İş", "Proje", "İş Durumu"];
  public columns = [
    { prop: "code", name: "İş Kodu", colspan: 2 },
    { prop: "name", name: "İş Adı", colspan: 2 },
    { prop: "projectName", name: "Proje Adı", colspan: 2 },
    { prop: "sprintName", name: "Sprint", colspan: 2 },
    { prop: "jobStatusName", name: "İş Durumu", colspan: 2 },
  ];
  public dynamicGlobalFilterFields: any;
  displayedColumns: any = [
    "code",
    "name",
    "projectName",
    "sprintName",
    "jobStatusName",
    "button",
  ];
  constructor(
    private jobService: JobsService,
    private jobStatusesService: JobStatusesService,
    private _liveAnnouncer: LiveAnnouncer,
    private router: Router,
    private customerService: CustomerService,
    private projectService: ProjectsService
  ) {}

  async ngOnInit() {
    this.dynamicGlobalFilterFields = this.columns.map((col) => col.prop);

    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);

    localStorage.removeItem("projectDetailFilters");
    localStorage.removeItem("sprintDetailFilters");
    localStorage.removeItem("selectedSprint");
    localStorage.removeItem("selectedCustomer");
    localStorage.removeItem("selectedIsActive");
    localStorage.removeItem("jobsFilters");

    await this.jobStatusesService.getAllJobStatuses().then((res) => {
      this.jobStatuses = res;
    });

    await this.customerService.getAllCustomers().then((res) => {
      this.customers = res;
    });

    if (this.user.data.claimId < 4) {
      await this.projectService
        .getProjectByDTOUserId(this.user.data.id)
        .then((res) => {
          this.projects = res;
        });
    } else {
      await this.projectService.getAllProjects().then((res) => {
        this.projects = res;
      });
    }

    await this.jobService
      .getJobsByDTOUserID(this.user.data.id, null)
      .then((res) => {
        this.jobsDto = res;
        this.filteredData = res;
      });
  }

  filters = {
    jobStatusName: "default",
    projectName: "default",
    customerName: "default",
  };

  filterData(data, condition: any) {
    this.filters[condition] = data;

    this.filteredData = Object.keys(this.filters).reduce(
      (prev, cur, idx, arr) => {
        if (this.filters[cur] === "default") {
          return prev;
        }
        return prev.filter((d) => {
          return String(d[cur]) === this.filters[cur];
        });
      },
      this.jobsDto
    );
  }

  async filterCustomer(data) {


    if (data === "default") {
      await this.jobService
        .getJobsByDTOUserID(this.user.data.id, null)
        .then((res) => {
          this.jobsDto = res;
          this.filteredData = res;
        });
      await this.projectService.getAllProjects().then((res) => {
        this.projects = res;
      });
    } else {
      await this.projectService
        .getListByDTOCustomerId(data, true)
        .then((res) => {
          this.projects = res;
        });
      await this.jobService
        .getJobsByDTOUserID(this.user.data.id, data)
        .then((res) => {
          this.jobsDto = res;
          this.filteredData = res;
       
        });
    }

    this.filterData(this.filters.jobStatusName, "jobStatusName");
    // this.filterData(this.filters.projectName, "projectName");
  }

  async export() {
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const jobsToExcell = this.filteredData;

    // Sütun başlıklarını tanımlayın ve sıralı bir dizi kullanın
    const columnHeaders = [
      { key: "projectName", header: "Proje Adı" },
      { key: "code", header: "İş Kodu" },
      { key: "name", header: "İş Adı" },
      { key: "details", header: "Detay" },
      { key: "jobTypeName", header: "İş Tipi" },
      { key: "jobStatusName", header: "İş Durumu" },

      { key: "estimatedDay", header: "Toplam Efor" },
      { key: "billedDay", header: "Faturalanan Efor" },
      { key: "sprintName", header: "Sprint" },
      { key: "isBilled", header: "Fatura Durum" },
      { key: "testDate", header: "Test Tarihi" },
      { key: "prodDate", header: "Yayınlanma Tarihi" },
    ];

    // Gereksiz alanları kaldırarak güncellenmiş iş kayıtlarını oluşturun
    const updatedJobsToExcell = jobsToExcell.map(function (job) {
      const updatedJob = {};
      columnHeaders.forEach(function (column) {
        const { key, header } = column;
        if (job.hasOwnProperty(key)) {
          if (key === "isBilled") {
            updatedJob[header] = job[key] ? "Fatura Edildi" : "Fatura Edilmedi";
          } else {
            updatedJob[header] = job[key];
          }
        }
      });
      return updatedJob;
    });

    const workSheet = XLSX.utils.json_to_sheet(updatedJobsToExcell, {
      header: columnHeaders.map((column) => column.header),
    });

    XLSX.utils.book_append_sheet(workBook, workSheet, "data"); // add the worksheet to the book

    const fileName = "Bekleyen Onaylar" + ".xlsx";
    XLSX.writeFile(workBook, fileName); // initiate a file download in browser
  }

  onClickDetail(id) {
    localStorage.setItem("jobId", id);
    this.router.navigateByUrl("/jobs/job-detail");
    localStorage.setItem("location", "confirmations");
  }
}

export type JobType = "jobStatusName" | "reset";
