<div>
  <button class="btn btn-info addButton" type="button" (click)="open(addTasks)">
    <span style="display: flex; justify-content: center; align-items: center">
      <app-feather-icons
        style="margin-right: 5px; display: flex"
        [icon]="'plus'"
      >
      </app-feather-icons>
      Yeni Görev Oluştur
    </span>
  </button>
</div>

<ng-template #addTasks let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Görev Ekle</h5>
    <button
      class="close"
      type="button"
      data-dismiss="modal"
      aria-label="Close"
      data-original-title=""
      title=""
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      class="form-bookmark needs-validation"
      id="bookmark-form"
      novalidate=""
      [formGroup]="addTaskForm"
    >
      <div class="form-row">
        <div class="form-group col-md-12">
          <label>Atanan Kullanıcı</label>
          <select
            class="form-control m-5"
            formControlName="assignedUser"
            required
          >
            <option value="" selected hidden>
              Lütfen Atanan Kullanıcıyı Seçiniz...
            </option>
            <option [value]="item.id" *ngFor="let item of users">
              {{ item.fullName }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-name">Tahmini Saat</label>
          <input
            class="form-control"
            id="bm-name"
            formControlName="estimatedHour"
            type="number"
            autocomplete="off"
          />
        </div>
        <!-- <div class="form-group col-md-6">
          <label for="bm-name">Tahmini Gün</label>
          <input
            class="form-control"
            id="bm-name"
            formControlName="estimatedDay"
            type="number"
            autocomplete="off"
          />
        </div> -->
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="bm-desc">Görev Detayı</label>
          <textarea
            class="form-control"
            id="bm-desc"
            formControlName="details"
            type="text"
            required
            autocomplete="off"
            placeholder="Görev Detayı"
          ></textarea>
        </div>
      </div>
      <input id="index_var" type="hidden" value="6" />
      <button class="btn btn-success mr-1" id="Bookmark" (click)="saveTask()">
        Kaydet
      </button>
      <button
        class="btn btn-danger"
        type="button"
        data-dismiss="modal"
        (click)="closeModal()"
      >
        İptal
      </button>
    </form>
  </div>
</ng-template>
