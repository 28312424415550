import { MatPaginatorIntl } from "@angular/material/paginator";

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = "Her sayfada listelenecek öge sayısı";
  customPaginatorIntl.nextPageLabel = "Sonraki sayfa";
  customPaginatorIntl.previousPageLabel = "Önceki sayfa";
  customPaginatorIntl.firstPageLabel = "İlk Sayfa";
  customPaginatorIntl.lastPageLabel = "Son Sayfa";

  return customPaginatorIntl;
}
