<div class="card-body">
  <h6 class="mt-4">Onaylar</h6>

  <div class="card">
    <div class="card-body">
      <div *ngIf="user.data.claimId !== 4">
        <div class="row">
          <p>
            Mevcut Durum:
            <strong>
              {{ jobDto?.jobStatusName }}
            </strong>
          </p>
        </div>
        <!-- <div class="row" *ngIf="jobDto.statusID === 6">
          <p>
            Mevcut Durum:
            <strong> Efor girilmiş </strong>
          </p>
        </div> -->
        <div
          class="row"
          *ngIf="
            jobDto.statusID === 1 &&
            (user.data.id == jobObj.createUserId ||
              user.data.id === projectObj.managerUserId ||
              user.data.claimId === 5 ||
              user.data.claimId === 6)
          "
        >
          <div class="col">
            <button class="btn btn-success" (click)="confirmationClick(1)">
              İş Onayına Gönder
            </button>
          </div>
          <div class="col">
            <button class="btn btn-danger" (click)="deleteJob()">
              İşi Sil
            </button>
          </div>
        </div>
        <div
          class="row"
          *ngIf="
            jobDto.statusID === 2 &&
            (user.data.id === projectObj.managerUserId ||
              user.data.claimId === 3 ||
              user.data.claimId === 5 ||
              user.data.claimId === 6)
          "
        >
          <div class="col">
            <button class="btn btn-success" (click)="confirmationClick(1, 2)">
              İş Onayı Ver
            </button>
          </div>
          <div class="col">
            <button class="btn btn-danger" (click)="confirmationClick(0, 2)">
              Reddet
            </button>
          </div>
        </div>

        <div
          class="row"
          *ngIf="
            jobDto.statusID === 4 &&
            (user.data.claimId === 5 ||
              user.data.id === projectObj.analysisApproverUserId ||
              user.data.claimId === 3 ||
              user.data.claimId === 6)
          "
        >
          <div class="col">
            <button class="btn btn-success" (click)="confirmationClick(1, 4)">
              Analiz Onayı Ver
            </button>
          </div>
          <div class="col">
            <button class="btn btn-danger" (click)="confirmationClick(0, 4)">
              Reddet
            </button>
          </div>
        </div>

        <div class="row" *ngIf="user.data.claimId !== 4">
          <div class="col-12">
            <div *ngIf="jobDto.statusID === 6 && jobDto.estimatedDay !== null">
              <strong>Tahmini Gün: </strong>{{ " " + jobDto.estimatedDay }}
              <br />
              <div
                class="row jobDetail"
                *ngFor="let taskType of selectedTaskTypes"
              >
                <div>
                  <p class="text-black-50">
                    <strong>{{ dayDetails[taskType] }} : </strong>
                    {{ taskData[taskType] }} gün
                  </p>
                </div>
              </div>
            </div>
            <div *ngIf="jobDto.statusID === 5 && jobDto.estimatedDay === 0">
              <p>Henüz efor girilmemiş</p>
              <br />
            </div>
          </div>

          <div
            class="col"
            *ngIf="
              jobDto.statusID === 6 &&
              (user.data.id === projectObj.dayApproverUserId ||
                user.data.claimId === 3 ||
                user.data.claimId === 5 ||
                user.data.claimId === 6) &&
              jobDto.estimatedDay !== null
            "
          >
            <button class="btn btn-success" (click)="confirmationClick(1, 6)">
              Gün Onayı Ver
            </button>
          </div>
          <div
            class="col"
            *ngIf="
              jobDto.statusID === 6 &&
              (user.data.id === projectObj.dayApproverUserId ||
                user.data.claimId === 3 ||
                user.data.claimId === 5 ||
                user.data.claimId === 6) &&
              jobDto.estimatedDay !== null
            "
          >
            <button class="btn btn-danger" (click)="confirmationClick(0, 6)">
              Reddet
            </button>
          </div>
        </div>

        <div
          class="row"
          *ngIf="jobDto.statusID === 10 && user.data.claimId !== 4"
        >
          <div class="col">
            <button class="btn btn-success" (click)="confirmationClick(1, 10)">
              Testi Onayla
            </button>
          </div>
          <div class="col">
            <button class="btn btn-danger" (click)="confirmationClick(0, 10)">
              Reddet
            </button>
          </div>
        </div>
      </div>
      <br />
      <div class="chat" *ngIf="rejectionReasonList?.length > 0">
        <h5>Açıklama:</h5>
        <div class="custom-scrollbar">
          <ul>
            <li *ngFor="let rejectionReason of rejectionReasonList">
              <div class="job-comment-box">
                <div class="row">
                  <div class="col-md-12">
                    <div
                      class="job-comment-header d-flex justify-content-between pb-3"
                    >
                      <div>
                        <p class="mb-1">
                          <strong class="message-data-time">
                            {{ rejectionReason?.userName }}
                          </strong>
                        </p>
                        <div class="job-comment-time text-right">
                          <span class="message-data-time">{{
                            rejectionReason?.date | date : "dd.MM.yyyy HH:mm"
                          }}</span>
                        </div>
                      </div>
                      <div>
                        <p>
                          <strong>
                            {{ rejectionReason?.jobApprovalTypeName }}
                          </strong>
                        </p>
                        <!-- <button
                          pButton
                          class="p-button-text p-button-rounded p-button-outlined p-button-danger"
                          icon="pi pi-trash"
                          [rounded]="true"
                          severity="danger"
                          [outlined]="true"
                          (click)="deleteComment(rejectionReason.id)"
                          *ngIf="rejectionReason.userId === user.data.id"
                        ></button> -->
                      </div>
                    </div>
                    <div class="job-comment-body pt-3">
                      <div
                        class="job-comment-content"
                        style="white-space: pre-wrap"
                      >
                        <p>
                          {{ rejectionReason?.comment }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="" *ngIf="isMessaggeBoxOpen">
        <p-dialog
          header="Açıklama"
          [modal]="true"
          [(visible)]="isMessaggeBoxOpen"
          [style]="{ width: '25rem' }"
        >
          <form
            class="form-bookmark needs-validation"
            id="bookmark-form"
            novalidate=""
            [formGroup]="approveForm"
          >
            <div class="row mb-2 mt-2">
              <textarea
                rows="5"
                cols="30"
                class="w-100"
                pInputTextarea
                [autoResize]="true"
                formControlName="comment"
                required
              >
              </textarea>
            </div>

            <div class="flex justify-content-end gap-2">
              <p-button
                label="İptal"
                severity="secondary"
                pRipple
                (onClick)="isMessaggeBoxOpen = false"
              ></p-button>
              <p-button
                label="Gönder"
                (onClick)="sendRejection()"
                pRipple
                [disabled]="!approveForm.valid"
              ></p-button>
            </div>
          </form>
        </p-dialog>
      </div>
    </div>
  </div>
</div>
