import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";

import { AuthService } from "../../shared/services/firebase/auth.service";
import { AuthService as AS } from "src/app/shared/services/auth.service";
import { Location } from "@angular/common";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  turkishCharsRegex = /[^a-zA-Z0-9@._-]+/;
  englishCharsRegex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/;
 

  constructor(
    public aServ: AS,
    public authService: AuthService,
    private fb: FormBuilder,
    private snackBarService: SnackbarService
  ) {
    this.loginForm = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern(this.englishCharsRegex),
         
        ],
      ],
      password: ["", [Validators.required, Validators.minLength(5)]],
    });
  }

  ngOnInit() {}

  checkLastFourChars(email: string) {
    if (email.slice(-4) === ".com") {
      return true;
    } else {
      return false;
    }
  }

  showPassword() {
    this.show = !this.show;
  }

  // Login With Google
  loginGoogle() {
    this.authService.GoogleAuth();
  }

  // Login With Twitter
  loginTwitter(): void {
    this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
    this.authService.signInFacebok();
  }

  // Simple Login
  login() {
    // this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
    this.aServ
      .loginWithEmailAndPassword(
        this.loginForm.value["email"],
        this.loginForm.value["password"]
      )
      .then((res) => {
        console.log("res:", res);

        // this.snackBarService.openSnackBarWithMessageSuccess("Giriş Başarılı");
      })
      .catch((err) => {
        console.log("err:", err);
        //  this.snackBarService.openSnackBarWithMessageError(err.message);
      });
  }
}
