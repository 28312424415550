<app-breadcrumb
  [title]="'İş Oluştur'"
  [items]="['İşler']"
  [active_item]="'İşler'"
>
</app-breadcrumb>

<div class="container-fluid">
  <div class="card">
    <form
      class="form-bookmark needs-validation"
      id="bookmark-form"
      novalidate=""
      [formGroup]="addJobForm"
      *ngIf="addJobForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="card-body">
        <div class="row" style="align-items: flex-start; column-gap: 4rem">
          <div class="col-5">
            <div class="form-group">
              <label for="bm-name"
                >İş Adı <label style="color: red">*</label></label
              >
              <input
                class="form-control"
                id="bm-name"
                formControlName="name"
                type="text"
                required
                autocomplete="off"
                placeholder="İş Adı"
              />
            </div>
            <div class="form-group">
              <label for="bm-name">Konu </label>
              <input
                class="form-control"
                id="bm-name"
                formControlName="topic"
                type="text"
                required
                autocomplete="off"
                placeholder="İşin Konusu"
              />
            </div>
            <br />
            <div class="form-group-textarea">
              <label for="bm-name"
                >Açıklama <label style="color: red">*</label></label
              >
              <textarea
                class="form-control"
                style="height: 119px !important"
                id="bm-name"
                formControlName="details"
                type="text"
                required
                autocomplete="off"
                placeholder="Açıklama"
              ></textarea>
            </div>
            <br />
            <div class="form-group">
              <label>Proje <label style="color: red">*</label></label>
              <select
                class="form-control m-5"
                formControlName="projectId"
                required
              >
                <option value="" selected hidden>
                  Lütfen Proje Seçiniz...
                </option>
                <option [value]="item.id" *ngFor="let item of projects">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <br />
            <div class="form-group">
              <label>İş Önceliği <label style="color: red">*</label></label>
              <select
                class="form-control m-5"
                required
                formControlName="jobPriorityId"
              >
                <option value="" selected hidden>
                  Lütfen Öncelik Seçiniz...
                </option>
                <option [value]="item.id" *ngFor="let item of priorities">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <br />
            <!-- <div class="form-group">
              <label>İş Durumu <label style="color: red">*</label></label>
              <select class="form-control m-5" formControlName="jobStatusId">
                <option value="1" selected hidden>
                  {{ jobStatuses[0]?.name }}
                </option>
                <option [value]="item.id" *ngFor="let item of jobStatuses">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <br /> -->
            <div class="form-group">
              <label>İş Tipi</label>
              <select class="form-control m-5" formControlName="jobTypeId">
                <option value="" selected hidden>
                  Lütfen İş Tipini Seçiniz...
                </option>
                <option [value]="item.id" *ngFor="let item of jobTypes">
                  {{ item.name }}
                </option>
              </select>
            </div>

            <br />
            <div class="form-group">
              <label>Atanan Kullanıcı</label>
              <select class="form-control m-5" formControlName="assignedUserId">
                <option value="" selected hidden>
                  Lütfen Atanacak Kişiyi Seçiniz...
                </option>
                <option [value]="item.id" *ngFor="let item of users">
                  {{ item.fullName }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-5">
            <div class="form-group">
              <label>Sprint</label>
              <select class="form-control m-5" formControlName="sprint">
                <option value="" selected hidden>
                  Lütfen Sprint Seçiniz...
                </option>
                <option [value]="item.id" *ngFor="let item of sprints">
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="bm-name">Tahmini Gün</label>
              <input
                class="form-control"
                id="bm-name"
                formControlName="estimatedDay"
                type="number"
                autocomplete="off"
                placeholder="Tahmini Gün"
              />
            </div>
            <br />
            <div class="form-group">
              <label for="bm-name">Gerçekleşen Gün</label>
              <input
                class="form-control"
                id="bm-name"
                formControlName="actualDay"
                type="number"
                autocomplete="off"
                placeholder="Tahmini Gün"
              />
            </div>
            <br />
            <div class="form-group">
              <label for="bm-name">Fatura Edilen Gün</label>
              <input
                class="form-control"
                id="bm-name"
                formControlName="billedDay"
                type="number"
                autocomplete="off"
                placeholder="Tahmini Gün"
              />
            </div>
            <br />
            <div class="form-group">
              <label for="bm-name">Başlama Tarihi</label>
              <input
                class="form-control"
                id="bm-name"
                formControlName="startDate"
                type="date"
                autocomplete="off"
                placeholder="İşe Başlama Tarihi"
              />
            </div>
            <br />
            <div class="form-group">
              <label for="bm-name">Bitiş Tarihi</label>
              <input
                class="form-control"
                id="bm-name"
                formControlName="endDate"
                type="date"
                autocomplete="off"
                placeholder="İşi Bitirme Tarihi"
              />
            </div>
            <br />
            <div class="form-group">
              <label for="bm-name">Test Tarihi</label>
              <input
                class="form-control"
                id="bm-name"
                formControlName="testDate"
                type="date"
                autocomplete="off"
                placeholder="Teste Çıkma Tarihi"
              />
            </div>
            <br />
            <div class="form-group">
              <label for="bm-name">Yayınlanma Tarihi</label>
              <input
                class="form-control"
                id="bm-name"
                formControlName="prodDate"
                type="date"
                autocomplete="off"
                placeholder="Yayınlanma Tarihi"
              />
            </div>
          </div>

          <div class="col-10">
            <button
              class="btn btn-info"
              type="submit"
              [disabled]="addJobForm.invalid"
            >
              Kaydet
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
