import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class JobFollowersService {

  constructor(private http : HttpClientService) { }

  async getById(jobFollowerID: Number) {
    return await this.http
      .httpGet("/JobFollowers/getbyid?jobFollowerID=" + jobFollowerID)
      .then((res) => {
        return res.data;
      });
  }

  async getByDTOId(jobFollowerID: Number) {
    return await this.http
      .httpGet("/JobFollowers/getbydtoid?jobFollowerID=" + jobFollowerID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobFollowers/getlistbyjobid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByDTOJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobFollowers/getlistbydtojobid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }

  async addJobFollowers(data) {
    return await this.http.httpPost("/JobFollowers/add", data);
  }
  async deleteJobFollowers(data) {
    return await this.http.httpDelete("/JobFollowers/delete", data);
  }
  async updateJobFollowers(data) {
    return await this.http.httpPut("/JobFollowers/update", data);
  }
}
