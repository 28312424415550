import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class JobApprovalCommentsService {

  constructor(private http: HttpClientService) { }


  async getById(jobCommentID: Number) {
    return await this.http
      .httpGet("/JobApprovalComments/getbyid?jobCommentID=" + jobCommentID)
      .then((res) => {
        return res.data;
      });
  }
  async getByDTOId(jobCommentID: Number) {
    return await this.http
      .httpGet("/JobApprovalComments/getbydtoid?jobCommentID=" + jobCommentID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobApprovalComments/getlistbyjobid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }

  async getListByDTOJobID(jobID: Number) {
    return await this.http
      .httpGet("/JobApprovalComments/getlistbydtojobid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }

  async addJobApprovalComments(data) {
    return await this.http.httpPost("/JobApprovalComments/add", data);
  }
  async deleteJobApprovalComments(data) {
    return await this.http.httpDelete("/JobApprovalComments/delete", data);
  }
  async updateJobApprovalComments(data) {
    return await this.http.httpPut("/JobApprovalComments/update", data);
  }
}
