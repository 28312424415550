import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClientService) {}

  async getAllCustomers() {
    return await this.http.httpGet("/Customers/getall").then((res) => {
      return res.data;
    });
  }

  async getById(customerID: Number) {
    return await this.http
      .httpGet("/Customers/getbyid?customerID=" + customerID)
      .then((res) => {
        return res.data;
      });
  }
  async addCustomers(data) {
    return await this.http.httpPost("/Customers/add", data);
  }
  async deleteCustomers(data) {
    return await this.http.httpDelete("/Customers/delete", data);
  }
  async updateCustomers(data) {
    return await this.http.httpPut("/Customers/update", data);
  }
}
