<app-breadcrumb [title]="'Kullanıcılar'" [active_item]="'Kullanıcılar'">
</app-breadcrumb>
<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <!-- Individual column searching (text inputs) Starts-->
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Kullanıcı Tanım</h5>
          <span
            >Bu tabloda kullanıcılar listenmiştir, mevcut tanımları düzenleyip
            silebilir, yeni firma ekleyebilirsiniz.
          </span>
        </div>
        <div
          style="
            margin-top: 20px;
            display: flex;
            justify-content: right;
            width: 100%;
            padding-right: 2rem;
          "
        >
          <button
            class="btn btn-info addButton"
            type="button"
            (click)="AddUsers.openModal()"
          >
            <span
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <app-feather-icons
                style="margin-right: 5px; display: flex"
                [icon]="'plus'"
              >
              </app-feather-icons>
              Yeni Kullanıcı
            </span>
          </button>
        </div>
        <div class="card-body">
          <div class="">
            <div>
              <div>
                <div>
                  <div class="mat-elevation-z8">
                    <p-table
                      [value]="users"
                      [columns]="columns"
                      [tableStyle]="{ 'min-width': '50rem' }"
                      responsiveLayout="scroll"
                      [rows]="10"
                      [showCurrentPageReport]="true"
                      [paginator]="true"
                      [rowsPerPageOptions]="[10, 25, 50]"
                      currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} gösteriliyor"
                      #tt
                      [globalFilterFields]="dynamicGlobalFilterFields"
                      [scrollable]="true"
                      scrollHeight="600px"
                    >
                      <ng-template pTemplate="caption">
                        <div
                          class="flex justify-content-end align-items-center"
                        >
                          <div class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input
                              type="text"
                              pInputText
                              placeholder="Ara"
                              (input)="
                                tt.filterGlobal($event.target.value, 'contains')
                              "
                            />
                          </div>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="header" let-columns>
                        <tr>
                          <th
                            *ngFor="let col of columns"
                            [pSortableColumn]="col.prop"
                          >
                            {{ col.name }}
                            <p-sortIcon [field]="col.prop"></p-sortIcon>
                          </th>
                          <th></th>
                        </tr>
                      </ng-template>
                      <ng-template
                        pTemplate="body"
                        let-rowData
                        let-columns="columns"
                      >
                        <tr>
                          <td *ngFor="let col of columns">
                            {{ rowData[col.prop] }}
                          </td>
                          <td>
                            <div class="example-button-container">
                              <button
                                (click)="EditUser.openModal(rowData.id)"
                                class="btn btn-success"
                                mat-mini-fab
                                color="primary"
                                style="margin-right: 10px"
                                matTooltip="Düzenle"
                              >
                                <mat-icon>edit</mat-icon>
                              </button>

                              <button
                                (click)="DeleteUsers.openModal(rowData.id)"
                                mat-mini-fab
                                color="primary"
                                color="warn"
                                matTooltip="Sil"
                              >
                                <mat-icon>delete</mat-icon>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </ng-template>

                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td colspan="100%">
                            Gösterilecek veri bulunmamaktadır
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Individual column searching (text inputs) Ends-->
  </div>
</div>

<app-add-user #addUsers (onSave)="updateTable()"></app-add-user>
<app-edit-user #editUsers (onSave)="updateTable()"></app-edit-user>
<app-delete-user #deleteUsers (onSave)="updateTable()"></app-delete-user>

<!-- Container-fluid Ends-->
