<ng-template #deleteFile let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="exampleModalLabel">
      Silmek istediğinizden emin misiniz?
    </h4>
    <button
      class="close"
      type="button"
      data-dismiss="modal"
      aria-label="Close"
      data-original-title=""
      title=""
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <h5>Bu dosyayı silmek istediğinizden emin misiniz?</h5>
    </div>
    <div style="margin-top: 20px; justify-content: flex-end; display: flex">
      <button
        class="btn btn-success mr-1"
        type="button"
        data-dismiss="modal"
        (click)="deleteFile()"
      >
        Evet
      </button>
      <button
        class="btn btn-danger"
        type="button"
        data-dismiss="modal"
        (click)="closeModal()"
      >
        Hayır
      </button>
    </div>
  </div>
</ng-template>
