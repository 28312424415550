<app-breadcrumb
  [title]="'Proje Detayları'"
  [items]="['Projeler']"
  [active_item]="'Proje Detayları'"
>
</app-breadcrumb>

<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="row">
          <div class="col-md-12">
            <div class="card-header m-b-15">
              <div>
                <h4>{{ projectDto?.name }}</h4>
                <h6>{{ projectDto?.code }}</h6>
              </div>

              <div style="display: grid; grid-template-columns: auto auto auto">
                <div
                  *ngIf="
                    user.data.claimId != 4
                  "
                >
                  <button
                    class="btn btn-info addButton"
                    type="button"
                    (click)="AddJobs.openModal()"
                  >
                    <span
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <app-feather-icons
                        style="margin-right: 5px; display: flex"
                        [icon]="'plus'"
                      >
                      </app-feather-icons>
                      Yeni İş Oluştur
                    </span>
                  </button>
                </div>

                <div style="width: 10px"></div>
                <div
                  *ngIf="
                    user.data.claimId == 3 ||
                    user.data.claimId == 5 ||
                    user.data.claimId == 6
                  "
                >
                  <app-edit-project [id]="projectID"></app-edit-project>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div>
            <div class="card-body">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <h6>Filtre</h6>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <select
                    class="form-control"
                    (change)="
                      filterData($event.target.value, 'sprint'); setFilters()
                    "
                    [(ngModel)]="filters.sprint"
                  >
                    <option value="default" default selected>
                      Sprint Seçiniz
                    </option>
                    <option [value]="item.id" *ngFor="let item of sprints">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <select
                    class="form-control"
                    (change)="
                      setFilters();
                      filterJobStatus($event.target.value);
                      filterData($event.target.value, 'jobStatusName')
                    "
                    [(ngModel)]="filters.jobStatusName"
                  >
                    <option value="default" default selected>
                      İş Durumu Seçiniz
                    </option>
                    <option
                      [value]="item.name"
                      *ngFor="let item of jobStatuses"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <select
                    class="form-control"
                    (change)="
                      filterData($event.target.value, 'jobTypeName');
                      setFilters()
                    "
                    [(ngModel)]="filters.jobTypeName"
                  >
                    <option value="default" default selected>
                      İş Tipi Seçiniz
                    </option>
                    <option [value]="item.name" *ngFor="let item of jobTypes">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <select
                    class="form-control"
                    (change)="
                      filterData($event.target.value, 'createUserName');
                      setFilters()
                    "
                    [(ngModel)]="filters.createUserName"
                  >
                    <option value="default" default selected>
                      Oluşturan Kullanıcı Seçiniz
                    </option>
                    <option [value]="item.userName" *ngFor="let item of users">
                      {{ item.userName }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <select
                    class="form-control"
                    (change)="
                      filterData($event.target.value, 'assignedUserName');
                      setFilters()
                    "
                    [(ngModel)]="filters.assignedUserName"
                  >
                    <option value="default" default selected>
                      Atanan Kullanıcı Seçiniz
                    </option>
                    <option [value]="item.userName" *ngFor="let item of users">
                      {{ item.userName }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <select
                    class="form-control"
                    (change)="
                      filterData($event.target.value, 'isBilled'); setFilters()
                    "
                    [(ngModel)]="filters.isBilled"
                  >
                    <option value="default" default selected>
                      Ödeme Durumu Seçiniz
                    </option>
                    <option [value]="item.value" *ngFor="let item of bools">
                      {{ item.name }}
                    </option>
                    <!-- <option [value]="true">Fatura Edildi</option>
                    <option [value]="false">Fatura Edilmedi</option> -->
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div class="col-md-10">
                <div class="row card-body">
                  <div class="col-11">
                    <div
                      style="
                        display: grid;
                        grid-template-columns: 8rem 1.5rem;
                        row-gap: 0rem;
                        align-items: center;
                      "
                    >
                      <div>
                        <h5>İş Listesi</h5>
                      </div>
                      <div class="excellDownloadBtn" (click)="export()">
                        <app-feather-icons
                          [icon]="'download'"
                        ></app-feather-icons>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-1 text-right"
                    style="
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                    "
                  >
                    <app-feather-icons
                      style="margin-right: 5px"
                      [icon]="'grid'"
                      (click)="onClickView(1)"
                    ></app-feather-icons>
                    <app-feather-icons
                      style="margin-right: 5px"
                      [icon]="'list'"
                      (click)="onClickView(2)"
                    ></app-feather-icons>
                  </div>
                </div>
                <div class="col">
                  <div
                    class="tab-content"
                    id="top-tabContent"
                    *ngIf="selectedIndex === 1"
                  >
                    <div
                      class="tab-pane fade show active"
                      id="top-home"
                      role="tabpanel"
                      aria-labelledby="top-home-tab"
                    >
                      <div class="row">
                        <div class="col-9">
                          <div class="row">
                            <div
                              class="col-xl-4 col-lg-6"
                              *ngFor="let job of filteredData; let i = index"
                              (click)="onClick(job.id)"
                            >
                              <div class="job-box">
                                <div class="badge-box">
                                  <span
                                    [ngClass]="{
                                      'badge-light text-dark':
                                        job.jobStatusName === 'Test Aşamasında',
                                      'badge-primary':
                                        job.jobStatusName === 'Geliştiriliyor',
                                      'badge-info':
                                        job.jobStatusName === 'İş Oluşturuldu',
                                      'badge-warning text-dark':
                                        job.jobStatusName === 'Beklemede',
                                      'badge-success':
                                        job.jobStatusName === 'Tamamlandı',
                                      'badge-danger':
                                        job.jobStatusName === 'İptal Edildi',
                                      'badge-dark':
                                        job.jobStatusName === 'Test Bekliyor',
                                      'badge-success':
                                        job.jobStatusName === 'Yayına hazır',
                                      'badge-secondary':
                                        job.jobStatusName === 'deneme'
                                    }"
                                    class="badge"
                                    >{{ job.jobStatusName }}</span
                                  >
                                  <span
                                    [ngClass]="{
                                      'badge-success':
                                        job.jobPriorityName === 'Az',
                                      'badge-light text-dark':
                                        job.jobPriorityName === 'Normal',
                                      'badge-warning text-dark':
                                        job.jobPriorityName === 'Önemli',
                                      'badge-danger':
                                        job.jobPriorityName === 'Çok Önemli'
                                    }"
                                    class="badge"
                                    >{{ job?.jobPriorityName }}</span
                                  >
                                </div>
                                <h5>{{ job?.name }}</h5>
                                <h6>{{ job?.topic }}</h6>

                                <p>{{ job.details }}</p>
                                <div class="row details">
                                  <div class="col-8">
                                    <span>Öngörülen gün sayısı</span>
                                  </div>
                                  <div class="col-4 text-primary">
                                    {{ job.estimatedDay }}
                                  </div>
                                  <div class="col-8">
                                    <span>Asıl gün sayısı</span>
                                  </div>
                                  <div class="col-4 text-primary">
                                    {{ job.actualDay }}
                                  </div>
                                  <div class="col-8">
                                    <span>Fatura edilen gün sayısı</span>
                                  </div>
                                  <div class="col-4 text-primary">
                                    {{ job.billedDay }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col" *ngIf="selectedIndex === 2">
                  <div class="card-body">
                    <div>
                      <mat-form-field appearance="standard">
                        <mat-label>Ara</mat-label>
                        <input matInput (keyup)="applyFilter($event)" #input />
                      </mat-form-field>

                      <div class="mat-elevation-z8">
                        <table
                          mat-table
                          [dataSource]="dataSource"
                          matSort
                          (matSortChange)="announceSortChange($event)"
                        >
                          <div *ngFor="let item of columns">
                            <ng-container [matColumnDef]="item?.prop">
                              <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                              >
                                {{ item?.name }}
                              </th>
                              <td mat-cell *matCellDef="let element">
                                {{ element[item?.prop] }}
                              </td>
                            </ng-container>
                          </div>

                          <ng-container matColumnDef="button">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                              <div class="example-button-container d-flex">
                                <button
                                  (click)="onClick(element.id)"
                                  mat-mini-fab
                                  color="primary"
                                  style="margin-right: 10px"
                                  matTooltip="Detay"
                                >
                                  <mat-icon>arrow_forward</mat-icon>
                                </button>

                                <button
                                  (click)="openModal(jobDelete, element.id)"
                                  mat-mini-fab
                                  color="primary"
                                  color="warn"
                                  *ngIf="user.data.claimId > 4"
                                  matTooltip="Sil"
                                >
                                  <mat-icon>delete</mat-icon>
                                </button>
                              </div>
                            </td>
                          </ng-container>

                          <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumns"
                          ></tr>
                          <tr
                            mat-row
                            *matRowDef="let row; columns: displayedColumns"
                          ></tr>
                        </table>

                        <mat-paginator
                          [pageSizeOptions]="[20, 50, 100]"
                          showFirstLastButtons
                        >
                        </mat-paginator>
                      </div>
                    </div>

                    <!-- <ngx-datatable
                      #myTasksDataTable
                      class="bootstrap"
                      [rows]="
                        filteredData == null || filteredData == undefined
                          ? []
                          : filteredData
                      "
                      [columns]="taskColumns"
                      [width]="20"
                      [columnMode]="'force'"
                      headerHeight="auto"
                      rowHeight="auto"
                      [footerHeight]="50"
                      [limit]="20"
                      (activate)="onActivate($event)"
                    >
                      <ngx-datatable-column
                        [width]="50"
                        *ngFor="let column of taskColumns; let i = index"
                        name="{{ column.name }}"
                        prop="{{ column.prop }}"
                      >
                      </ngx-datatable-column>
                      <ngx-datatable-column
                        [width]="200"
                        [sortable]="false"
                        name=""
                      >
                        <ng-template
                          let-row="row"
                          let-value="value"
                          ngx-datatable-cell-template
                        >
                          <div style="display: flex; padding-right: 30px">
                            <button
                              class="btn btn-primary"
                              (click)="onClick(row.id)"
                              style="margin-right: 10px"
                            >
                              <span
                                style="
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                "
                              >
                                <app-feather-icons
                                  style="margin-right: 5px"
                                  [icon]="'edit'"
                                >
                                </app-feather-icons>
                                <span>Detay</span>
                              </span>
                            </button>
                            <button
                              class="btn btn-danger"
                              (click)="openModal(jobDelete, row.id)"
                            >
                              <span
                                style="
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                "
                              >
                                <app-feather-icons
                                  style="margin-right: 5px"
                                  [icon]="'trash-2'"
                                >
                                </app-feather-icons>
                                Sil
                              </span>
                            </button>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable> -->
                  </div>
                </div>
                <br />
                <br />
                <!-- <div class="col" *ngIf="childProjects.length !== 0">
                  <div class="card-body">
                    <h5>Alt Projeler</h5>
                    <ngx-datatable
                      #myTasksDataTable
                      class="bootstrap"
                      [rows]="childProjects"
                      [columns]="taskColumns"
                      [columnMode]="'force'"
                      headerHeight="auto"
                      rowHeight="auto"
                      [footerHeight]="50"
                      [limit]="10"
                      (activate)="onActivate($event)"
                    >
                      <ngx-datatable-column
                        [width]="20"
                        *ngFor="let column of taskColumns; let i = index"
                        name="{{ column.name }}"
                        prop="{{ column.prop }}"
                      >
                      </ngx-datatable-column>
                      <ngx-datatable-column
                        minWidth="300"
                        [sortable]="false"
                        name=""
                      >
                        <div>
                          <button
                            class="btn btn-primary"
                            (click)="onClick(row.id)"
                          >
                            <span
                              style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <app-feather-icons
                                style="margin-right: 5px"
                                [icon]="'edit'"
                              >
                              </app-feather-icons>
                              <span>Detay</span>
                            </span>
                          </button>
                        </div>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                </div> -->
              </div>

              <div class="col-md-2 projectDetails">
                <div class="card-body">
                  <p class="headerRight">
                    <strong> Proje Detayları </strong>
                  </p>
                  <p class="jobDetail">
                    <strong> Kurum </strong>
                    <br />
                    {{ projectDto?.customerName }}
                  </p>
                  <p class="jobDetail">
                    <strong> İş Onaylayan Kullanıcı </strong>
                    <br />
                    {{ projectDto?.managerUserName }}
                  </p>
                  <p class="jobDetail">
                    <strong> Analiz Onaylayan Kullanıcı </strong>
                    <br />
                    {{ projectDto?.analysisApproverUserName }}
                  </p>
                  <p class="jobDetail">
                    <strong> Gün Onaylayan Kullanıcı </strong>
                    <br />
                    {{ projectDto?.dayApproverUserName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-create-job
  #addJobs
  [projectId]="projectID"
  (onSave)="getEmit()"
></app-create-job>
<!-- <app-edit-project #editProject (onSave)="updateTable()"></app-edit-project> -->

<!-- Container-fluid Ends-->

<!-- Container-fluid Ends-->
<!-- <app-create-job #addCreateJob (onSave)="updateTable()"></app-create-job> -->

<div class="card" hidden style="border-radius: 20px">
  <div class="card-header">
    <h5>Modal with default options</h5>
  </div>
  <div class="card-body">
    <ng-template #jobDelete let-modal>
      <div class="modal-header">
        <h4 class="modal-title text-danger" id="modal-basic-title">İşi Sil</h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5>İş Silinecek Emin misin?</h5>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          (click)="modal.close('Cross click')"
        >
          Sil
        </button>
        <button
          type="button"
          class="btn btn-success"
          (click)="modal.dismiss('close')"
        >
          Vazgeç
        </button>
      </div>
    </ng-template>
  </div>
</div>
