<div class="card-body">
  <!-- Logs kısmı -->
  <div class="mt-4">
    <div class="card">
      <div class="card-header m-b-15">
        <h6 class="mt-4">Loglar</h6>
      </div>
      <div class="card-body">
        <div>
          <div class="mat-elevation-z8">
            <p-table
              [value]="jobLogs"
              [columns]="logsColumn"
              [tableStyle]="{ 'min-width': '50rem' }"
              responsiveLayout="scroll"
              [rows]="10"
              [showCurrentPageReport]="true"
              [paginator]="true"
              [rowsPerPageOptions]="[10, 25, 50]"
              currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} gösteriliyor"
              [sortable]="true"
              #tt
              [scrollable]="true"
              scrollHeight="600px"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.prop">
                    {{ col.name }}
                    <p-sortIcon [field]="col.prop"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td *ngFor="let col of logsColumn">
                    {{ rowData[col.prop] }}
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="100%">Gösterilecek veri bulunmamaktadır</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
