import { Component, OnInit, ViewChild } from "@angular/core";
import { UserService } from "src/app/shared/services/user.service";
import { AddUserComponent } from "./add-user/add-user.component";
import { DeleteUserComponent } from "./delete-user/delete-user.component";
import { EditUserComponent } from "./edit-user/edit-user.component";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  @ViewChild("addUsers") AddUsers: AddUserComponent;
  @ViewChild("editUsers") EditUser: EditUserComponent;
  @ViewChild("deleteUsers") DeleteUsers: DeleteUserComponent;
  public outputID: number;
  public users = [{}];
  public columns = [
    { prop: "id", name: "Numara" },
    { prop: "fullName", name: "Tam İsim" },
    { prop: "userName", name: "Kullanıcı Adı" },
    { prop: "email", name: "Email" },
    { prop: "phone", name: "Telefon" },
    { prop: "customerName", name: "Kurum" },
    { prop: "claimName", name: "Yetki" },
    { prop: "isActive", name: "Aktif" },
    { prop: "isLocked", name: "Kilitli" },
  ];
  public dynamicGlobalFilterFields: any;

  constructor(private userServ: UserService) {}

  ngOnInit() {
    this.dynamicGlobalFilterFields = this.columns.map((col) => col.prop);

    this.userServ.getAllUsersDTO().then((resp) => {
      this.users = resp;
    });
  }
  async updateTable() {
    await this.userServ.getAllUsersDTO().then((resp) => {
      this.users = resp;
    });
  }
}
