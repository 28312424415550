import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";

@Injectable({
  providedIn: "root",
})
export class JobsService {
  constructor(private http: HttpClientService, private datePipe: DatePipe) {}

  async getAllJobs() {
    return await this.http.httpGet("/Jobs/getall").then((res) => {
      return res.data;
    });
  }

  async getAllJobsDTO() {
    return await this.http.httpGet("/Jobs/getalldto").then((res) => {
      res = res.data.map((job) => {
        return {
          ...job,
          endDate: this.datePipe.transform(job.endDate, "dd.MM.yyyy"),
          analysisApproveDate: this.datePipe.transform(
            job.analysisApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
          startDate: this.datePipe.transform(job.startDate, "dd.MM.yyyy"),
          testDate: this.datePipe.transform(job.testDate, "dd.MM.yyyy"),
          prodDate: this.datePipe.transform(job.prodDate, "dd.MM.yyyy"),
          managerApproveDate: this.datePipe.transform(
            job.managerApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
          customerITApproveDate: this.datePipe.transform(
            job.customerITApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
          createDate: this.datePipe.transform(
            job.createDate,
            "dd.MM.yyyy HH:mm"
          ),
          dayApproveDate: this.datePipe.transform(
            job.dayApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
        };
      });

      return res;
    });
  }

  async getAllJobsWithChild() {
    return await this.http.httpGet("/Jobs/getallwithchild").then((res) => {
      res = res.data.map((job) => {
        return {
          ...job,
          endDate: this.datePipe.transform(job.endDate, "dd.MM.yyyy"),
          analysisApproveDate: this.datePipe.transform(
            job.analysisApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
          startDate: this.datePipe.transform(job.startDate, "dd.MM.yyyy"),
          testDate: this.datePipe.transform(job.testDate, "dd.MM.yyyy"),
          prodDate: this.datePipe.transform(job.prodDate, "dd.MM.yyyy"),
          managerApproveDate: this.datePipe.transform(
            job.managerApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
          customerITApproveDate: this.datePipe.transform(
            job.customerITApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
          createDate: this.datePipe.transform(
            job.createDate,
            "dd.MM.yyyy HH:mm"
          ),
          dayApproveDate: this.datePipe.transform(
            job.dayApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
        };
      });

      return res;
    });
  }

  async getProjectJobsWithChild(projectdId: any) {
    return await this.http
      .httpGet("/Jobs/getprojectjobswithchild?projectID=" + projectdId)
      .then((res) => {
        res = res.data.map((job) => {
          return {
            ...job,
            endDate: this.datePipe.transform(job.endDate, "dd.MM.yyyy"),
            analysisApproveDate: this.datePipe.transform(
              job.analysisApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            startDate: this.datePipe.transform(job.startDate, "dd.MM.yyyy"),
            testDate: this.datePipe.transform(job.testDate, "dd.MM.yyyy"),
            prodDate: this.datePipe.transform(job.prodDate, "dd.MM.yyyy"),
            managerApproveDate: this.datePipe.transform(
              job.managerApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            customerITApproveDate: this.datePipe.transform(
              job.customerITApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            createDate: this.datePipe.transform(
              job.createDate,
              "dd.MM.yyyy HH:mm"
            ),
            dayApproveDate: this.datePipe.transform(
              job.dayApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
          };
        });

        return res;
      });
  }

  async getById(jobID: Number) {
    return await this.http
      .httpGet("/Jobs/getbyid?jobID=" + jobID)
      .then((res) => {
        return res.data;
      });
  }

  async getByDTOId(jobID: Number) {
    return await this.http
      .httpGet("/Jobs/getbydtoid?jobID=" + jobID)
      .then((res) => {
        res = {
          ...res.data,
          endDate: this.datePipe.transform(res.data.endDate, "dd.MM.yyyy"),
          analysisApproveDate: this.datePipe.transform(
            res.data.analysisApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
          startDate: this.datePipe.transform(res.data.startDate, "dd.MM.yyyy"),
          testDate: this.datePipe.transform(res.data.testDate, "dd.MM.yyyy"),
          prodDate: this.datePipe.transform(res.data.prodDate, "dd.MM.yyyy"),
          managerApproveDate: this.datePipe.transform(
            res.data.managerApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
          customerITApproveDate: this.datePipe.transform(
            res.data.customerITApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
          createDate: this.datePipe.transform(
            res.data.createDate,
            "dd.MM.yyyy HH:mm"
          ),
          dayApproveDate: this.datePipe.transform(
            res.data.dayApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
        };

        return res;
      });
  }

  async getJobsByProjectID(projectID: Number) {
    return await this.http
      .httpGet("/Jobs/getjobsbyprojectid?projectID=" + projectID)
      .then((res) => {
        res = res.data.map((job) => {
          return {
            ...job,
            endDate: this.datePipe.transform(job.endDate, "dd.MM.yyyy"),
            analysisApproveDate: this.datePipe.transform(
              job.analysisApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            startDate: this.datePipe.transform(job.startDate, "dd.MM.yyyy"),
            testDate: this.datePipe.transform(job.testDate, "dd.MM.yyyy"),
            prodDate: this.datePipe.transform(job.prodDate, "dd.MM.yyyy"),
            managerApproveDate: this.datePipe.transform(
              job.managerApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            customerITApproveDate: this.datePipe.transform(
              job.customerITApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            createDate: this.datePipe.transform(
              job.createDate,
              "dd.MM.yyyy HH:mm"
            ),
            dayApproveDate: this.datePipe.transform(
              job.dayApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
          };
        });

        return res;
      });
  }

  async getJobsByDTOProjectID(projectID: Number) {
    return await this.http
      .httpGet("/Jobs/getjobsbydtoprojectid?projectID=" + projectID)
      .then((res) => {
        res = res.data.map((job) => {
          return {
            ...job,
            endDate: this.datePipe.transform(job.endDate, "dd.MM.yyyy"),
            analysisApproveDate: this.datePipe.transform(
              job.analysisApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            startDate: this.datePipe.transform(job.startDate, "dd.MM.yyyy"),
            testDate: this.datePipe.transform(job.testDate, "dd.MM.yyyy"),
            prodDate: this.datePipe.transform(job.prodDate, "dd.MM.yyyy"),
            managerApproveDate: this.datePipe.transform(
              job.managerApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            customerITApproveDate: this.datePipe.transform(
              job.customerITApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            createDate: this.datePipe.transform(
              job.createDate,
              "dd.MM.yyyy HH:mm"
            ),
            dayApproveDate: this.datePipe.transform(
              job.dayApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
          };
        });

        return res;
      });
  }

  async getJobsByDTOCustomerID(customerId: Number) {
    return await this.http
      .httpGet("/Jobs/getjobbydtocustomerid?customerID=" + customerId)
      .then((res) => {
        res = res.data.map((job) => {
          return {
            ...job,
            endDate: this.datePipe.transform(job.endDate, "dd.MM.yyyy"),
            analysisApproveDate: this.datePipe.transform(
              job.analysisApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            startDate: this.datePipe.transform(job.startDate, "dd.MM.yyyy"),
            testDate: this.datePipe.transform(job.testDate, "dd.MM.yyyy"),
            prodDate: this.datePipe.transform(job.prodDate, "dd.MM.yyyy"),
            managerApproveDate: this.datePipe.transform(
              job.managerApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            customerITApproveDate: this.datePipe.transform(
              job.customerITApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            createDate: this.datePipe.transform(
              job.createDate,
              "dd.MM.yyyy HH:mm"
            ),
            dayApproveDate: this.datePipe.transform(
              job.dayApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
          };
        });

        return res;
      });
  }
  async getJobsByDTOCustomerIDWithChild(customerId: Number) {
    return await this.http
      .httpGet("/Jobs/getjobbydtocustomeridwithchild?customerID=" + customerId)
      .then((res) => {
        const transformedData = res.data.map((job) => {
          return this.transformJobDates(job);
        });
        return transformedData;
      });
  }
  async getJobsByDTOUserIDWithChild(userId: Number) {
    return await this.http
      .httpGet("/Jobs/getjobbydtouseridwithchild?userID=" + userId)
      .then((res) => {
        const transformedData = res.data.map((job) => {
          return this.transformJobDates(job);
        });
        return transformedData;
      });
  }
  async getJobsByCustomerID(customerId: Number) {
    return await this.http
      .httpGet("/Jobs/getjobbycustomerid?customerID=" + customerId)
      .then((res) => {
        return res.data;
      });
  }

  async getJobsByAssignedUserID(assignedUserID: Number) {
    return await this.http
      .httpGet("/Jobs/getjobsbyassigneduserid?assignedUserID=" + assignedUserID)
      .then((res) => {
        res = res.data.map((job) => {
          return {
            ...job,
            endDate: this.datePipe.transform(job.endDate, "dd.MM.yyyy"),
            analysisApproveDate: this.datePipe.transform(
              job.analysisApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            startDate: this.datePipe.transform(job.startDate, "dd.MM.yyyy"),
            testDate: this.datePipe.transform(job.testDate, "dd.MM.yyyy"),
            prodDate: this.datePipe.transform(job.prodDate, "dd.MM.yyyy"),
            managerApproveDate: this.datePipe.transform(
              job.managerApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            customerITApproveDate: this.datePipe.transform(
              job.customerITApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            createDate: this.datePipe.transform(
              job.createDate,
              "dd.MM.yyyy HH:mm"
            ),
            dayApproveDate: this.datePipe.transform(
              job.dayApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
          };
        });

        return res;
      });
  }

  async getJobsByDTOAssignedUserID(assignedUserID: Number) {
    return await this.http
      .httpGet(
        "/Jobs/getjobsbydtoassigneduserid?assignedUserID=" + assignedUserID
      )
      .then((res) => {
        res = res.data.map((job) => {
          return {
            ...job,
            endDate: this.datePipe.transform(job.endDate, "dd.MM.yyyy"),
            analysisApproveDate: this.datePipe.transform(
              job.analysisApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            startDate: this.datePipe.transform(job.startDate, "dd.MM.yyyy"),
            testDate: this.datePipe.transform(job.testDate, "dd.MM.yyyy"),
            prodDate: this.datePipe.transform(job.prodDate, "dd.MM.yyyy"),
            managerApproveDate: this.datePipe.transform(
              job.managerApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            customerITApproveDate: this.datePipe.transform(
              job.customerITApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            createDate: this.datePipe.transform(
              job.createDate,
              "dd.MM.yyyy HH:mm"
            ),
            dayApproveDate: this.datePipe.transform(
              job.dayApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
          };
        });

        return res;
      });
  }

  async getJobsByDTOUserID(userID: Number, customerID: Number) {
    var query = "/Jobs/getjobsbydtouserid?userID=" + userID;
    if (customerID != null) {
      query += "&customerID=" + customerID;
    }
    return await this.http.httpGet(query).then((res) => {
      res = res.data.map((job) => {
        return {
          ...job,
          endDate: this.datePipe.transform(job.endDate, "dd.MM.yyyy"),
          analysisApproveDate: this.datePipe.transform(
            job.analysisApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
          startDate: this.datePipe.transform(job.startDate, "dd.MM.yyyy"),
          testDate: this.datePipe.transform(job.testDate, "dd.MM.yyyy"),
          prodDate: this.datePipe.transform(job.prodDate, "dd.MM.yyyy"),
          managerApproveDate: this.datePipe.transform(
            job.managerApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
          customerITApproveDate: this.datePipe.transform(
            job.customerITApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
          createDate: this.datePipe.transform(
            job.createDate,
            "dd.MM.yyyy HH:mm"
          ),
          dayApproveDate: this.datePipe.transform(
            job.dayApproveDate,
            "dd.MM.yyyy HH:mm"
          ),
        };
      });

      return res;
    });
  }

  async getJobsBySprint(sprintID: Number) {
    return await this.http
      .httpGet("/Jobs/getjobsbysprint?sprintID=" + sprintID)
      .then((res) => {
        // res = res.data.map((job) => {
        //   return {
        //     ...res.data,
        //     endDate: this.datePipe.transform(res.data.endDate, "dd.MM.yyyy HH:mm"),
        //     analysisApproveDate: this.datePipe.transform(
        //       res.data.analysisApproveDate,
        //       "dd.MM.yyyy HH:mm"
        //     ),
        //     startDate: this.datePipe.transform(
        //       res.data.startDate,
        //       "dd.MM.yyyy HH:mm"
        //     ),
        //     testDate: this.datePipe.transform(res.data.testDate, "dd.MM.yyyy HH:mm"),
        //     prodDate: this.datePipe.transform(res.data.prodDate, "dd.MM.yyyy HH:mm"),
        //     managerApproveDate: this.datePipe.transform(
        //       res.data.managerApproveDate,
        //       "dd.MM.yyyy HH:mm"
        //     ),
        //     customerITApproveDate: this.datePipe.transform(
        //       res.data.customerITApproveDate,
        //       "dd.MM.yyyy HH:mm"
        //     ),
        //     createDate: this.datePipe.transform(
        //       res.data.createDate,
        //       "dd.MM.yyyy HH:mm"
        //     ),
        //     dayApproveDate: this.datePipe.transform(
        //       res.data.dayApproveDate,
        //       "dd.MM.yyyy HH:mm"
        //     ),
        //   };
        // });

        return res.data;
      });
  }

  async getJobsByDTOSprint(sprintID: Number) {
    return await this.http
      .httpGet("/Jobs/getjobsbydtosprint?sprintID=" + sprintID)
      .then((res) => {
        res = res.data.map((job) => {
          return {
            ...job,
            endDate: this.datePipe.transform(job.endDate, "dd.MM.yyyy"),
            analysisApproveDate: this.datePipe.transform(
              job.analysisApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            startDate: this.datePipe.transform(job.startDate, "dd.MM.yyyy"),
            testDate: this.datePipe.transform(job.testDate, "dd.MM.yyyy"),
            prodDate: this.datePipe.transform(job.prodDate, "dd.MM.yyyy"),
            managerApproveDate: this.datePipe.transform(
              job.managerApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            customerITApproveDate: this.datePipe.transform(
              job.customerITApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
            createDate: this.datePipe.transform(
              job.createDate,
              "dd.MM.yyyy HH:mm"
            ),
            dayApproveDate: this.datePipe.transform(
              job.dayApproveDate,
              "dd.MM.yyyy HH:mm"
            ),
          };
        });

        return res;
      });
  }

  async getJobsByDTOParentJobId(parentID: Number) {
    return await this.http
      .httpGet("/Jobs/getjobsbydtoparentjobid?parentJobId=" + parentID)
      .then((res) => {
        return res.data;
      });
  }

  async getJobsByParentJobId(parentID: Number) {
    return await this.http
      .httpGet("/Jobs/getjobsbyparentjobid?parentJobId=" + parentID)
      .then((res) => {
        return res.data;
      });
  }

  async addJobs(data) {
    return await this.http.httpPost("/Jobs/add", data);
  }
  async deleteJobs(data) {
    return await this.http.httpDelete("/Jobs/delete", data);
  }
  async updateJobs(data) {
    return await this.http.httpPut("/Jobs/update", data);
  }

  private transformJobDates(job: any): any {
    const transformedJob = {
      ...job,
      endDate: this.datePipe.transform(job.endDate, "dd.MM.yyyy"),
      analysisApproveDate: this.datePipe.transform(
        job.analysisApproveDate,
        "dd.MM.yyyy HH:mm"
      ),
      startDate: this.datePipe.transform(job.startDate, "dd.MM.yyyy"),
      testDate: this.datePipe.transform(job.testDate, "dd.MM.yyyy"),
      prodDate: this.datePipe.transform(job.prodDate, "dd.MM.yyyy"),
      managerApproveDate: this.datePipe.transform(
        job.managerApproveDate,
        "dd.MM.yyyy HH:mm"
      ),
      customerITApproveDate: this.datePipe.transform(
        job.customerITApproveDate,
        "dd.MM.yyyy HH:mm"
      ),
      createDate: this.datePipe.transform(job.createDate, "dd.MM.yyyy HH:mm"),
      dayApproveDate: this.datePipe.transform(
        job.dayApproveDate,
        "dd.MM.yyyy HH:mm"
      ),
      // Diğer tarih alanlarınızı buraya ekleyebilirsiniz
    };

    // Eğer child işleri varsa, onları da dönüştürelim
    if (job.child && Array.isArray(job.child)) {
      transformedJob.child = job.child.map((childJob) =>
        this.transformJobDates(childJob)
      );
    }

    return transformedJob;
  }
}
