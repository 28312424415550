import { Component, OnInit, ViewChild } from "@angular/core";
import { CustomerService } from "src/app/shared/services/customer.service";
import { AddCustomerComponent } from "./add-customer/add-customer.component";
import { DeleteCustomerComponent } from "./delete-customer/delete-customer.component";
import { EditCustomerComponent } from "./edit-customer/edit-customer.component";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
})
export class CustomerComponent implements OnInit {
  @ViewChild("addCustomers") AddCustomers: AddCustomerComponent;
  @ViewChild("editCustomers") EditCustomer: EditCustomerComponent;
  @ViewChild("deleteCustomers") DeleteCustomers: DeleteCustomerComponent;
  public outputID: number;
  public customers = [{}];
  public columns = [
    { prop: "id", name: "Numara" },
    { prop: "name", name: "İsim" },
  ];
  public dynamicGlobalFilterFields: any;


  constructor(private customerServ: CustomerService) {}

  ngOnInit() {
    this.dynamicGlobalFilterFields = this.columns.map((col) => col.prop);

    this.customerServ.getAllCustomers().then((resp) => {
      this.customers = resp;
    });
  }
  async updateTable() {
    await this.customerServ.getAllCustomers().then((resp) => {
      this.customers = resp;
    });
  }
}
