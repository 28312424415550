import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JobTypesRoutingModule } from './job-types-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    JobTypesRoutingModule
  ]
})
export class JobTypesModule { }
