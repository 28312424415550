import { Component, OnInit, ViewChild } from "@angular/core";
import { JobStatusesService } from "src/app/shared/services/job-statuses.service";
import { AddJobStatusComponent } from "./add-job-status/add-job-status.component";
import { DeleteJobStatusComponent } from "./delete-job-status/delete-job-status.component";
import { EditJobStatusComponent } from "./edit-job-status/edit-job-status.component";
import { LiveAnnouncer } from "@angular/cdk/a11y";

@Component({
  selector: "app-job-statuses",
  templateUrl: "./job-statuses.component.html",
  styleUrls: ["./job-statuses.component.scss"],
})
export class JobStatusesComponent implements OnInit {
  @ViewChild("addJobStatuses") AddJobStatuses: AddJobStatusComponent;
  @ViewChild("editJobStatuses") EditJobStatus: EditJobStatusComponent;
  @ViewChild("deleteJobStatuses") DeleteJobStatuses: DeleteJobStatusComponent;
  public outputID: number;
  public jobStatuses = [];
  public columns = [
    { prop: "id", name: "Numara" },
    { prop: "name", name: "İsim" },
    { prop: "previousStatus", name: "Önceki Durum" },
    { prop: "nextStatus", name: "Sonraki Durum" },
    { prop: "description", name: "Açıklama" },
  ];
  public dynamicGlobalFilterFields: any;

  constructor(
    private jobStatusServ: JobStatusesService,
    private _liveAnnouncer: LiveAnnouncer
  ) {}
  async ngOnInit() {
    this.dynamicGlobalFilterFields = this.columns.map((col) => col.prop);

    await this.jobStatusServ.getAllJobStatuses().then((resp) => {
      this.jobStatuses = resp;
    });
  }
  async updateTable() {
    await this.jobStatusServ.getAllJobStatuses().then((resp) => {
      this.jobStatuses = resp;
    });
  }
}
