<app-breadcrumb
  [title]="'Proje Detayları'"
  [items]="['Projeler']"
  [active_item]="'Proje Detayları'"
>
</app-breadcrumb>

<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="row">
          <div class="col-md-12">
            <div class="card-header m-b-15">
              <div>
                <h4>{{ projectDto?.name }}</h4>
                <h6>{{ projectDto?.code }}</h6>
              </div>

              <div style="display: grid; grid-template-columns: auto auto auto">
                <div *ngIf="user.data.claimId != 4">
                  <button
                    class="btn btn-info addButton"
                    type="button"
                    (click)="AddJobs.openModal()"
                  >
                    <span
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <app-feather-icons
                        style="margin-right: 5px; display: flex"
                        [icon]="'plus'"
                      >
                      </app-feather-icons>
                      Yeni İş Oluştur
                    </span>
                  </button>
                </div>

                <div style="width: 10px"></div>
                <div
                  *ngIf="
                    user.data.claimId == 3 ||
                    user.data.claimId == 5 ||
                    user.data.claimId == 6
                  "
                >
                  <app-edit-project [id]="projectID"></app-edit-project>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div>
            <div class="card-body">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <h6>Filtre</h6>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <p-multiSelect
                    [options]="sprints"
                    [(ngModel)]="filters.sprintName"
                    defaultLabel="Sprint Seçiniz"
                    optionLabel="name"
                    optionValue="name"
                    display="chip"
                    (onChange)="
                      filterData($event.value, 'sprintName'); setFilters()
                    "
                  ></p-multiSelect>
                </div>
                <div class="form-group col-md-4">
                  <p-multiSelect
                    [options]="jobStatuses"
                    [(ngModel)]="filters.jobStatusName"
                    defaultLabel="İş Durumu Seçiniz"
                    optionLabel="name"
                    optionValue="name"
                    display="chip"
                    (onChange)="
                      setFilters();
                      filterJobStatus($event.value);
                      filterData($event.value, 'jobStatusName')
                    "
                  ></p-multiSelect>
                </div>
                <div class="form-group col-md-4">
                  <p-multiSelect
                    [options]="jobTypes"
                    [(ngModel)]="filters.jobTypeName"
                    defaultLabel="İş Tipi Seçiniz"
                    optionLabel="name"
                    optionValue="name"
                    display="chip"
                    (onChange)="
                      filterData($event.value, 'jobTypeName'); setFilters()
                    "
                  ></p-multiSelect>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <p-multiSelect
                    [options]="users"
                    [(ngModel)]="filters.createUserName"
                    defaultLabel="Oluşturan Kullanıcı Seçiniz"
                    optionLabel="userName"
                    optionValue="userName"
                    display="chip"
                    (onChange)="
                      filterData($event.value, 'createUserName'); setFilters()
                    "
                  ></p-multiSelect>
                </div>
                <div class="form-group col-md-4">
                  <p-multiSelect
                    [options]="users"
                    [(ngModel)]="filters.assignedUserName"
                    defaultLabel="Atanan Kullanıcı Seçiniz"
                    optionLabel="userName"
                    optionValue="userName"
                    display="chip"
                    (onChange)="
                      filterData($event.value, 'assignedUserName'); setFilters()
                    "
                  ></p-multiSelect>
                </div>
                <div class="form-group col-md-4">
                  <p-multiSelect
                    [options]="bools"
                    [(ngModel)]="filters.isBilled"
                    defaultLabel="Ödeme Durumu Seçiniz"
                    optionLabel="name"
                    optionValue="value"
                    display="chip"
                    (onChange)="
                      filterData($event.value, 'isBilled'); setFilters()
                    "
                  ></p-multiSelect>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div class="col-md-10">
                <div class="row card-body">
                  <div class="col-11">
                    <div
                      style="
                        display: grid;
                        grid-template-columns: 8rem 1.5rem;
                        row-gap: 0rem;
                        align-items: center;
                      "
                    >
                      <div>
                        <h5>İş Listesi</h5>
                      </div>
                      <div class="excellDownloadBtn" (click)="export()">
                        <app-feather-icons
                          [icon]="'download'"
                        ></app-feather-icons>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-1 text-right"
                    style="
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                    "
                  >
                    <app-feather-icons
                      style="margin-right: 5px"
                      [icon]="'grid'"
                      (click)="onClickView(1)"
                    ></app-feather-icons>
                    <app-feather-icons
                      style="margin-right: 5px"
                      [icon]="'list'"
                      (click)="onClickView(2)"
                    ></app-feather-icons>
                  </div>
                </div>
                <div class="col">
                  <div
                    class="tab-content"
                    id="top-tabContent"
                    *ngIf="selectedIndex === 1"
                  >
                    <div
                      class="tab-pane fade show active"
                      id="top-home"
                      role="tabpanel"
                      aria-labelledby="top-home-tab"
                    >
                      <div class="row">
                        <div class="col-9">
                          <div class="row">
                            <div
                              class="col-xl-4 col-lg-6"
                              *ngFor="let job of filteredData; let i = index"
                              (click)="onClick(job.id)"
                            >
                              <div class="job-box">
                                <div class="badge-box">
                                  <span
                                    [ngClass]="{
                                      'badge-light text-dark':
                                        job.jobStatusName === 'Test Aşamasında',
                                      'badge-primary':
                                        job.jobStatusName === 'Geliştiriliyor',
                                      'badge-info':
                                        job.jobStatusName === 'İş Oluşturuldu',
                                      'badge-warning text-dark':
                                        job.jobStatusName === 'Beklemede',
                                      'badge-success':
                                        job.jobStatusName === 'Tamamlandı',
                                      'badge-danger':
                                        job.jobStatusName === 'İptal Edildi',
                                      'badge-dark':
                                        job.jobStatusName === 'Test Bekliyor',
                                      'badge-success':
                                        job.jobStatusName === 'Yayına hazır',
                                      'badge-secondary':
                                        job.jobStatusName === 'deneme'
                                    }"
                                    class="badge"
                                    >{{ job.jobStatusName }}</span
                                  >
                                  <span
                                    [ngClass]="{
                                      'badge-success':
                                        job.jobPriorityName === 'Az',
                                      'badge-light text-dark':
                                        job.jobPriorityName === 'Normal',
                                      'badge-warning text-dark':
                                        job.jobPriorityName === 'Önemli',
                                      'badge-danger':
                                        job.jobPriorityName === 'Çok Önemli'
                                    }"
                                    class="badge"
                                    >{{ job?.jobPriorityName }}</span
                                  >
                                </div>
                                <h5>{{ job?.name }}</h5>
                                <h6>{{ job?.topic }}</h6>

                                <p>{{ job.details }}</p>
                                <div class="row details">
                                  <div class="col-8">
                                    <span>Öngörülen gün sayısı</span>
                                  </div>
                                  <div class="col-4 text-primary">
                                    {{ job.estimatedDay }}
                                  </div>
                                  <div class="col-8">
                                    <span>Asıl gün sayısı</span>
                                  </div>
                                  <div class="col-4 text-primary">
                                    {{ job.actualDay }}
                                  </div>
                                  <div class="col-8">
                                    <span>Fatura edilen gün sayısı</span>
                                  </div>
                                  <div class="col-4 text-primary">
                                    {{ job.billedDay }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col" *ngIf="selectedIndex === 2">
                  <div class="card-body">
                    <div class="treetable-container">
                      <div class="mat-elevation-z8">
                        <p-treeTable
                          [value]="filteredData"
                          [columns]="columns"
                          [rows]="10"
                          [showCurrentPageReport]="true"
                          [rowsPerPageOptions]="[10, 25, 50]"
                          [paginator]="true"
                          currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} gösteriliyor"
                          #tt
                          [globalFilterFields]="dynamicGlobalFilterFields"
                          [scrollable]="true"
                          scrollHeight="600px"
                        >
                          <ng-template pTemplate="caption">
                            <div
                              class="flex justify-content-end align-items-center"
                            >
                              <div class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input
                                  type="text"
                                  pInputText
                                  placeholder="Ara"
                                  (input)="
                                    tt.filterGlobal(
                                      $event.target.value,
                                      'contains'
                                    )
                                  "
                                />
                              </div>
                            </div>
                          </ng-template>

                          <ng-template pTemplate="header" let-columns>
                            <tr>
                              <th
                                *ngFor="let col of columns"
                                [ttSortableColumn]="col.prop"
                                [style.width]="col.width"
                              >
                                <div
                                  class="flex align-items-center text-center"
                                >
                                  <div>
                                    {{ col.name }}
                                  </div>
                                  <div>
                                    <p-treeTableSortIcon
                                      [field]="col.prop"
                                    ></p-treeTableSortIcon>
                                  </div>
                                </div>
                              </th>
                              <th style="width: 10%"></th>
                            </tr>
                          </ng-template>
                          <ng-template
                            pTemplate="body"
                            let-rowNode
                            let-rowData="rowData"
                            let-columns="columns"
                          >
                            <tr
                              [ngClass]="{
                                'has-children':
                                  rowNode.node &&
                                  rowNode.node.children.length > 0,
                                'is-child': rowNode.parent
                              }"
                            >
                              <td
                                *ngFor="let col of columns; let i = index"
                                [style.width]="col.width"
                              >
                                <p-treeTableToggler
                                  [rowNode]="rowNode"
                                  *ngIf="i == 0"
                                ></p-treeTableToggler>
                                {{ rowData[col.prop] }}
                              </td>
                              <td style="width: 10%">
                                <div class="example-button-container d-flex">
                                  <button
                                    (click)="onClick(rowData.id)"
                                    mat-mini-fab
                                    color="primary"
                                    style="margin-right: 10px"
                                    matTooltip="Detay"
                                  >
                                    <mat-icon>arrow_forward</mat-icon>
                                  </button>

                                  <button
                                    (click)="openModal(jobDelete, rowData.id)"
                                    mat-mini-fab
                                    color="primary"
                                    color="warn"
                                    *ngIf="user.data.claimId > 4"
                                    matTooltip="Sil"
                                  >
                                    <mat-icon>delete</mat-icon>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </ng-template>

                          <ng-template pTemplate="emptymessage">
                            <tr>
                              <td colspan="100%">
                                Gösterilecek veri bulunmamaktadır
                              </td>
                            </tr>
                          </ng-template>
                        </p-treeTable>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
              </div>

              <div class="col-md-2 projectDetails">
                <div class="card-body">
                  <p class="headerRight">
                    <strong> Proje Detayları </strong>
                  </p>
                  <p class="jobDetail">
                    <strong> Kurum </strong>
                    <br />
                    {{ projectDto?.customerName }}
                  </p>
                  <p class="jobDetail">
                    <strong> İş Onaylayan Kullanıcı </strong>
                    <br />
                    {{ projectDto?.managerUserName }}
                  </p>
                  <p class="jobDetail">
                    <strong> Analiz Onaylayan Kullanıcı </strong>
                    <br />
                    {{ projectDto?.analysisApproverUserName }}
                  </p>
                  <p class="jobDetail">
                    <strong> Gün Onaylayan Kullanıcı </strong>
                    <br />
                    {{ projectDto?.dayApproverUserName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-create-job
  #addJobs
  [projectId]="projectID"
  (onSave)="getEmit()"
></app-create-job>
<!-- <app-edit-project #editProject (onSave)="updateTable()"></app-edit-project> -->

<!-- Container-fluid Ends-->

<!-- Container-fluid Ends-->
<!-- <app-create-job #addCreateJob (onSave)="updateTable()"></app-create-job> -->

<div class="card" hidden style="border-radius: 20px">
  <div class="card-header">
    <h5>Modal with default options</h5>
  </div>
  <div class="card-body">
    <ng-template #jobDelete let-modal>
      <div class="modal-header">
        <h4 class="modal-title text-danger" id="modal-basic-title">İşi Sil</h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5>İş Silinecek Emin misin?</h5>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          (click)="modal.close('Cross click')"
        >
          Sil
        </button>
        <button
          type="button"
          class="btn btn-success"
          (click)="modal.dismiss('close')"
        >
          Vazgeç
        </button>
      </div>
    </ng-template>
  </div>
</div>
