import { Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";

@Injectable({
  providedIn: "root",
})
export class JobTypesService {
  public JobTypesList = [];
  constructor(private http: HttpClientService) {}

  async getAllJobTypes() {
    return await this.http.httpGet("/JobTypes/getall").then((res) => {
      return res.data;
    });
  }

  async getById(jobTypeID: Number) {
    return await this.http
      .httpGet("/JobTypes/getbyid?jobTypeID=" + jobTypeID)
      .then((res) => {
        return res.data;
      });
  }

  async addJobTypes(data) {
    return await this.http.httpPost("/JobTypes/add", data);
  }
  async deleteJobTypes(data) {
    return await this.http.httpDelete("/JobTypes/delete", data);
  }
  async updateJobTypes(data) {
    return await this.http.httpPut("/JobTypes/update", data);
  }
}
