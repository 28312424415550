import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(private http: HttpClientService) {}

  async getAllLogs() {
    return await this.http.httpGet("/Logs/getall").then((res) => {
      return res.data;
    });
  }

  async getById(logID: Number) {
    return await this.http
      .httpGet("/Logs/getbyid?logID=" + logID)
      .then((res) => {
        return res.data;
      });
  }
  async addLogs(data) {
    return await this.http.httpPost("/Logs/add", data);
  }
  async deleteLogs(data) {
    return await this.http.httpDelete("/Logs/delete", data);
  }
  async updateLogs(data) {
    return await this.http.httpPut("/Logs/update", data);
  }}
