import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/shared/services/auth.service";
import { JobStatusesService } from "src/app/shared/services/job-statuses.service";
import { UserNotificationsService } from "src/app/shared/services/user-notifications.service";
import { UserService } from "src/app/shared/services/user.service";
import * as CryptoJS from "crypto-js";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  public user: any;
  public notificationsOptions: any;
  public jobStatuses: any;
  public userNotifications: any;
  public newNotificationOption: any;

  public show1: boolean = false;
  public show2: boolean = false;
  public registerForm: FormGroup;

  constructor(
    private jobStatusesService: JobStatusesService,
    private userNotificationsService: UserNotificationsService,
    private userService: UserService,
    private fb: FormBuilder,
    private snackBarService: SnackbarService,
    private authService: AuthService
  ) {
    this.registerForm = this.fb.group(
      {
        password: [
          "",
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(30),
          ],
        ],
        repassword: [
          "",
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(30),
          ],
        ],
      },
      {
        validator: this.checkRepassword("password", "repassword"),
      }
    );
  }
  checkRepassword(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  showPassword1() {
    this.show1 = !this.show1;
  }
  showPassword2() {
    this.show2 = !this.show2;
  }

  async updatePassword() {
    await this.userService
      .updateUserPassword(this.user.data.id, this.registerForm.value.password)
      .then((res) => {
        //console.log(res);
        if (res.success === true) {
          this.snackBarService.openSnackBar(true);
          this.registerForm.reset();
        }
      });
  }

  async ngOnInit() {
    //this.user = this.authService.decyptedUser;
    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);

    this.notificationsOptions = {
      userId: this.user.data.id,
      jobStatusId: 0,
      sendNotification: false,
    };
    await this.jobStatusesService.getAllJobStatuses().then((res) => {
      this.jobStatuses = res;
    });
    await this.userNotificationsService
      .getByDTOUserId(this.user.data.id)
      .then((res) => {
        this.userNotifications = res;
      });
  }

  async notificationChange(jobStatusId) {
    this.userNotifications = {
      ...this.userNotifications,
      jobStatusId: jobStatusId,
    };

    await this.userNotificationsService.getById(jobStatusId).then((res) => {
      this.newNotificationOption = res;
      this.newNotificationOption = {
        ...this.newNotificationOption,
        sendNotification: !this.newNotificationOption.sendNotification,
      };
      this.userNotificationsService
        .updateUserNotifications(this.newNotificationOption)
        .then((res) => {
          // console.log(res);
        });
    });

    // console.log(this.newNotificationOption);
  }
}
