import { Component, OnInit, Input } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string;
  @Input() items: any[];
  @Input() active_item: string;
  public locationH: any;

  constructor(private location: Location, private router: Router) {}

  ngOnInit() {
    this.locationH = this.location;
  }

  back(): void {
    let currentLocation = window.location.href;
    const location = localStorage.getItem("location");

    if (
      currentLocation.includes("jobs/job-detail") &&
      !currentLocation.includes("jobs/job-detail?child=true") &&
      location
    ) {
      // localStorage.removeItem("location");

      this.router.navigate([location]);
    } else if (
      currentLocation.includes("jobs/job-detail?child=true") &&
      location
    ) {
      this.router
        .navigate(["/jobs/job-detail"])
        .then(() => window.location.reload());
    } else if (currentLocation.includes("projects/project-detail")) {
      this.router.navigate(["/projects"]);
    } else {
      this.location.back();
    }
  }
}
