import { Component, OnInit, Output, ViewChild } from "@angular/core";
import { JobTypesService } from "src/app/shared/services/job-types.service";
import { AddJobTypesComponent } from "./add-job-type/add-job-types.component";
import { AreYouSureComponent } from "./are-you-sure/are-you-sure.component";
import { EditJobTypeComponent } from "./edit-job-type/edit-job-type.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { LiveAnnouncer } from "@angular/cdk/a11y";

@Component({
  selector: "app-job-types",
  templateUrl: "./job-types.component.html",
  styleUrls: ["./job-types.component.scss"],
})
export class JobTypesComponent implements OnInit {
  @ViewChild("addJobTypes") AddJobTypes: AddJobTypesComponent;
  @ViewChild("editJobTypes") EditJobType: EditJobTypeComponent;
  @ViewChild("areyousure") DeleteJobTypes: AreYouSureComponent;
  public outputID: number;
  public jobTypes = [];
  public columns = [
    { prop: "id", name: "Numara" },
    { prop: "name", name: "İsim" },
    { prop: "details", name: "Detaylar" },
  ];

  public dynamicGlobalFilterFields: any;

  constructor(private jobTypeServ: JobTypesService) {}
  ngOnInit() {
    this.dynamicGlobalFilterFields = this.columns.map((col) => col.prop);

    this.jobTypeServ.getAllJobTypes().then((resp) => {
      this.jobTypes = resp;
    });
  }
  async updateTable() {
    await this.jobTypeServ.getAllJobTypes().then((resp) => {
      this.jobTypes = resp;
    });
  }
}
