import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { CreateJobComponent } from "src/app/components/job/create-job/create-job.component";
import { CustomerService } from "src/app/shared/services/customer.service";
import { JobEmitService } from "src/app/shared/services/jobEmit.service";
import { JobsService } from "src/app/shared/services/jobs.service";
import { ProjectEmitService } from "src/app/shared/services/projectEmit.service";
import { ProjectsService } from "src/app/shared/services/projects.service";
import { UserService } from "src/app/shared/services/user.service";
import { EditProjectComponent } from "../../edit-project/edit-project.component";

import * as XLSX from "xlsx";
import * as XLSXStyle from "xlsx-style";
import { JobPrioritiesService } from "src/app/shared/services/job-priorities.service";
import { JobStatusesService } from "src/app/shared/services/job-statuses.service";
import { JobTypesService } from "src/app/shared/services/job-types.service";
import { AuthService } from "src/app/shared/services/auth.service";
import * as CryptoJS from "crypto-js";

import {
  CdkDragDrop,
  CdkDragStart,
  moveItemInArray,
  transferArrayItem,
  CdkDragHandle,
} from "@angular/cdk/drag-drop";

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatTableDataSource } from "@angular/material/table";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { SprintsService } from "src/app/shared/services/sprints.service";
import { ProjectUsersService } from "src/app/shared/services/project-users.service";

@Component({
  selector: "app-project-detail",
  templateUrl: "./project-detail.component.html",
  styleUrls: ["./project-detail.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", visibility: "hidden" })
      ),
      state("expanded", style({ height: "*", visibility: "visible" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class ProjectDetailComponent implements OnInit, AfterViewInit {
  @ViewChild("addJobs") AddJobs: CreateJobComponent;
  @ViewChild("editProjects") EditProject: EditProjectComponent;
  @ViewChild("projectDatatable") datatable: DatatableComponent;

  user: any;
  projectID: any = 0;
  selectedIndex: number = 2;
  public customers: any;
  public users: any;
  public sprints: any;
  public projectDto: any;
  public projectObj: any;

  public jobs: any;
  public childProjects: any = [];

  public jobPriorities: any;
  public jobStatuses: any;
  public jobTypes: any;

  public filteredData: any = [{}];

  public columns = [
    { prop: "code", name: "İş Kodu", colspan: 2 },
    { prop: "name", name: "İş Adı", colspan: 2 },
    { prop: "projectName", name: "Proje Adı", colspan: 2 },

    { prop: "sprintName", name: "Sprint", colspan: 1 },
    { prop: "jobTypeName", name: "İş Tipi", colspan: 1 },
    { prop: "jobStatusName", name: "İş Durumu", colspan: 1 },

    { prop: "assignedUserName", name: "Atanan Kullanıcı", colspan: 1 },
  ];

  displayedColumns = [
    "code",
    "name",
    "sprintName",

    "jobTypeName",
    "jobStatusName",

    "assignedUserName",
    "button",
  ];

  dataSource: any = new MatTableDataSource<any>(this.filteredData);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public taskColumns = [
    { prop: "name", name: "İsim", colspan: 2 },
    { prop: "createUserName", name: "Oluşturan Kullanıcı", colspan: 3 },
    {
      prop: "createDate",
      name: "Oluşturulma Tarihi",
      colspan: 1,
      pipe: "dd MMM y",
    },

    { prop: "jobTypeName", name: "İş Tipi", colspan: 1 },
    { prop: "jobStatusName", name: "İş Durumu", colspan: 1 },
    { prop: "jobPriorityName", name: "İş Önceliği", colspan: 1 },
    { prop: "assignedUserName", name: "Atanan Kullanıcı", colspan: 1 },
  ];

  constructor(
    private projectServ: ProjectsService,
    private jobServ: JobsService,
    private custServ: CustomerService,
    private projectUserServ: ProjectUsersService,
    private router: Router,
    private emitProjectService: ProjectEmitService,
    private emitJobService: JobEmitService,
    private jobPrioritiesService: JobPrioritiesService,
    private sprintService: SprintsService,
    private jobStatusesService: JobStatusesService,
    private jobTypesService: JobTypesService,
    private authService: AuthService,
    private _liveAnnouncer: LiveAnnouncer,
    private modalService: NgbModal,
    private snackBarService: SnackbarService
  ) {}

  async ngOnInit() {
    //this.user = this.authService.decyptedUser;

    var decrypted = CryptoJS.AES.decrypt(
      sessionStorage.getItem("user"),
      "2233**superkeyKANLIsuperkey2233*"
    ).toString(CryptoJS.enc.Utf8);

    this.user = JSON.parse(decrypted);
    if (localStorage.getItem("projectDetailFilters")) {
      this.filters = JSON.parse(localStorage.getItem("projectDetailFilters"));
    }

    localStorage.removeItem("sprintDetailFilters");
    localStorage.removeItem("selectedSprint");
    localStorage.removeItem("selectedCustomer");
    localStorage.removeItem("selectedIsActive");
    localStorage.removeItem("jobsFilters");

    this.projectID = localStorage.getItem("projectId");
    await this.getEmit();

    await this.jobServ.getJobsByDTOProjectID(this.projectID).then((resp) => {
      this.jobs = resp;
      this.filteredData = resp;

      this.jobs = this.jobs.filter(
        (job) =>
          !(
            job.jobStatusName === "Yayınlandı" ||
            job.jobStatusName === "İptal edildi, Yapılmayacak"
          )
      );
      this.filteredData = this.filteredData.filter(
        (job) =>
          !(
            job.jobStatusName === "Yayınlandı" ||
            job.jobStatusName === "İptal edildi, Yapılmayacak"
          )
      );
      console.log(this.filters);

      if (this.filters) {
        //this.filteredData = this.applyFilters(this.filteredData, this.filters);
        if (
          this.filters.jobStatusName == "Yayınlandı" ||
          this.filters.jobStatusName == "İptal edildi, Yapılmayacak"
        ) {
          this.filterJobStatus(this.filters.jobStatusName);
        }

        this.filterData("", "");
      }

      this.dataSource = new MatTableDataSource<any>(this.filteredData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
    await this.projectServ.getByDTOId(this.projectID).then((resp) => {
      this.projectDto = resp[0];
      //console.log(this.projectDto);
    });

    await this.projectServ.getById(this.projectID).then((resp) => {
      this.projectObj = resp;
      this.projectUserServ
        .getListByDTOProjectID(this.projectObj.id)
        .then((resp) => {
          this.users = resp;
        });
    });
    await this.sprintService
      .getByDTOCustomerId(this.projectObj.customerId, null)
      .then((resp) => {
        this.sprints = resp;
      });

    await this.jobPrioritiesService.getAllJobPriorities().then((resp) => {
      this.jobPriorities = resp;
    });

    await this.jobStatusesService.getAllJobStatuses().then((resp) => {
      this.jobStatuses = resp;
    });
    await this.jobTypesService.getAllJobTypes().then((resp) => {
      this.jobTypes = resp;
    });
  }

  async getEmit() {
    await this.emitProjectService.getEmit.subscribe((data) => {
      this.updatePage();
    });
  }

  async updatePage() {
    await this.jobServ.getJobsByDTOProjectID(this.projectID).then((resp) => {
      this.jobs = resp;
      this.filteredData = this.jobs;

      this.dataSource = new MatTableDataSource<any>(this.filteredData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
    await this.projectServ.getByDTOId(this.projectID).then((resp) => {
      this.projectDto = resp[0];
      // console.log(this.projectDto);
    });
  }

  onClickView(index: number) {
    this.selectedIndex = index;
    if (this.selectedIndex === 1) {
    } else if (this.selectedIndex === 2) {
    }
  }

  onClick(id) {
    localStorage.setItem("jobId", id.toString());
    this.router.navigateByUrl("/jobs/job-detail");
    localStorage.setItem("location", "projects/project-detail");
  }

  async export() {
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const jobsToExcell = this.filteredData;

    // Sütun başlıklarını tanımlayın ve sıralı bir dizi kullanın
    const columnHeaders = [
      { key: "projectName", header: "Proje Adı" },
      { key: "code", header: "İş Kodu" },
      { key: "name", header: "İş Adı" },
      { key: "details", header: "Detay" },
      { key: "jobTypeName", header: "İş Tipi" },
      { key: "estimatedDay", header: "Toplam Efor" },
      { key: "billedDay", header: "Faturalanan Efor" },
      { key: "sprintName", header: "Sprint" },
      { key: "isBilled", header: "Fatura Durum" },
      { key: "testDate", header: "Test Tarihi" },
      { key: "prodDate", header: "Yayınlanma Tarihi" },
    ];

    // Gereksiz alanları kaldırarak güncellenmiş iş kayıtlarını oluşturun
    const updatedJobsToExcell = jobsToExcell.map(function (job) {
      const updatedJob = {};
      columnHeaders.forEach(function (column) {
        const { key, header } = column;
        if (job.hasOwnProperty(key)) {
          if (key === "isBilled") {
            updatedJob[header] = job[key] ? "Fatura Edildi" : "Fatura Edilmedi";
          } else {
            updatedJob[header] = job[key];
          }
        }
      });
      return updatedJob;
    });

    const workSheet = XLSX.utils.json_to_sheet(updatedJobsToExcell, {
      header: columnHeaders.map((column) => column.header),
    });

    XLSX.utils.book_append_sheet(workBook, workSheet, "data"); // add the worksheet to the book

    const fileName = this.projectDto.name + ".xlsx";
    XLSX.writeFile(workBook, fileName); // initiate a file download in browser
  }

  public filters = {
    sprint: "default",
    jobStatusName: "default",
    jobTypeName: "default",
    createUserName: "default",
    assignedUserName: "default",
    isBilled: "default",
  };

  bools = [
    { name: "Fatura Edildi", value: true },
    { name: "Fatura Edilmedi", value: false },
  ];

  setFilters() {
    localStorage.setItem("projectDetailFilters", JSON.stringify(this.filters));
  }

  filterData(data, condition: any) {
    //this.filters[condition] = data;

    this.filteredData = Object.keys(this.filters).reduce(
      (prev, cur, idx, arr) => {
        if (this.filters[cur] === "default") {
          return prev;
        }
        return prev.filter((d) => {
          return String(d[cur]) === this.filters[cur];
        });
      },
      this.jobs
    );

    this.dataSource = new MatTableDataSource<any>(this.filteredData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async filterJobStatus(name) {
    //console.log(name);

    if (name === "Yayınlandı" || name == "İptal edildi, Yapılmayacak") {
      // console.log("if");

      await this.jobServ.getJobsByDTOProjectID(this.projectID).then((resp) => {
        this.jobs = resp;
        this.filteredData = resp;

        this.jobs = this.jobs.filter((job) => job.jobStatusName === name);
        this.filteredData = this.filteredData.filter(
          (job) => job.jobStatusName === name
        );

        this.dataSource = new MatTableDataSource<any>(this.filteredData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
      this.filterData(name, "jobStatusName");
    } else {
      // console.log("else-if");
      await this.jobServ.getJobsByDTOProjectID(this.projectID).then((resp) => {
        this.jobs = resp;
        this.filteredData = resp;

        this.jobs = this.jobs.filter(
          (job) =>
            !(
              job.jobStatusName === "Yayınlandı" ||
              job.jobStatusName === "İptal edildi, Yapılmayacak"
            )
        );
        this.filteredData = this.filteredData.filter(
          (job) =>
            !(
              job.jobStatusName === "Yayınlandı" ||
              job.jobStatusName === "İptal edildi, Yapılmayacak"
            )
        );

        this.dataSource = new MatTableDataSource<any>(this.filteredData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
      this.filterData(name, "jobStatusName");
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.filteredData, event.previousIndex, event.currentIndex);
    console.log(event);

    this.filteredData[event.previousIndex].sprintOrder = event.currentIndex;
    console.log("sorted: ", this.filteredData);
  }

  deleteJob(id) {
    // console.log(id);
    if (this.user.data.claimId > 4) {
      this.jobServ
        .deleteJobs(id)
        .then((resp) => {
          this.updatePage();
          this.snackBarService.openSnackBar(true);
        })
        .catch((err) => {
          this.modalService.dismissAll();
          this.snackBarService.openSnackBar(false);
        });
    } else {
      this.modalService.dismissAll();
      this.snackBarService.openSnackBar(false);
    }
  }

  closeResult: any;
  openModal(content, id) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
        centered: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.deleteJob(id);
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  onActivate($event) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }
}
export type JobType =
  | "jobTypeName"
  | "jobStatusName"
  | "sprint"
  | "createUserName"
  | "assignedUserName"
  | "isBilled"
  | "reset";
