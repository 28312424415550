import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class JobEmitService {
  constructor() {}

  private setEmit = new BehaviorSubject<any>(undefined);
  public getEmit: Observable<any> = this.setEmit;

  setEmitServis(value) {
    this.setEmit.next(value);
  }
}
