import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProjectsTableComponent } from "../projects-table.component";
import { ProjectDetailComponent } from "./project-detail/project-detail.component";
import { ProjectDetail2Component } from "./project-detail2/project-detail2.component";

const routes: Routes = [
  { path: "", component: ProjectsTableComponent },
  { path: "project-detail2", component: ProjectDetailComponent },
  { path: "project-detail", component: ProjectDetail2Component },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectsTableRoutingModule {}
