import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JobApprovalTypesService } from "src/app/shared/services/job-approval-types.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";

@Component({
  selector: "app-edit-job-approval-type",
  templateUrl: "./edit-job-approval-type.component.html",
  styleUrls: ["./edit-job-approval-type.component.css"],
})
export class EditJobApprovalTypeComponent implements OnInit {
  @ViewChild("editJobApprovalTypes", { static: false })
  editJobApprovalTypes: TemplateRef<any>;
  @Output() onSave = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  public editJobApprovalTypeForm: FormGroup;
  public jobApprovalObj: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private jobApprovalTypeServ: JobApprovalTypesService,
    private snackBarService: SnackbarService
  ) {
    this.editJobApprovalTypeForm = this.fb.group({
      id: [{ value: "", disabled: true }, [Validators.required]],
      name: ["", [Validators.required]],
    });
  }

  async ngOnInit() {}

  async openModal(id: number) {
    if (isPlatformBrowser(this.platformId)) {
      await this.jobApprovalTypeServ.getById(id).then((resp) => {
        this.jobApprovalObj = resp;
        this.editJobApprovalTypeForm.get("id").setValue(this.jobApprovalObj.id);
        this.editJobApprovalTypeForm
          .get("name")
          .setValue(this.jobApprovalObj.name);
      });
      // For SSR
      this.modalService
        .open(this.editJobApprovalTypes, {
          size: "lg",
          ariaLabelledBy: "modal-bookmark",
          centered: true,
          windowClass: "modal-bookmark",
        })
        .result.then(
          (result) => {
            this.modalOpen = true;
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.editJobApprovalTypeForm.reset();
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  saveJobApprovalType() {
    this.jobApprovalTypeServ
      .updateJobApprovalTypes({
        id: this.jobApprovalObj.id,
        name: this.editJobApprovalTypeForm.value.name,
      })
      .then((a) => {
        this.editJobApprovalTypeForm.reset();
        this.modalService.dismissAll();
        this.onSave.emit();
        this.snackBarService.openSnackBar(true);
      })
      .catch((err) => {
        this.modalService.dismissAll();
        this.snackBarService.openSnackBar(false);
      });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
