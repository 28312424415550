<app-breadcrumb [title]="'Projeler'" [active_item]="'Projeler'">
</app-breadcrumb>
<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <!-- Individual column searching (text inputs) Starts-->
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Projeler</h5>
          <span
            >Bu tabloda projeler listenmiştir, mevcut tanımları düzenleyip
            silebilir, yeni proje ekleyebilirsiniz.
          </span>
        </div>
        <div class="card-body">
          <div class="row">
            <!-- <div class="col-sm-2 isActive">
              <div class="isActiveList">
                <div
                  class="nav-item listElement"
                  [ngClass]="
                    projectState == 0 ? 'listElementActive' : 'listElement'
                  "
                  (click)="getProjectList(0)"
                >
                  <i data-feather="target"></i>
                  <div>Hepsi</div>
                </div>

                <div
                  class="nav-item listElement"
                  [ngClass]="
                    projectState == 1 ? 'listElementActive' : 'listElement'
                  "
                  (click)="getProjectList(1)"
                >
                  <i data-feather="check-circle"></i> Aktif
                </div>

                <div
                  class="nav-item listElement"
                  [ngClass]="
                    projectState == -1 ? 'listElementActive' : 'listElement'
                  "
                  (click)="getProjectList(-1)"
                >
                  <i data-feather="minus-circle"></i> Pasif
                </div>
              </div>
            </div> -->
            <div class="col-sm-3 form-group">
              <select
                class="form-control"
                (change)="getProjectList($event.target.value)"
              >
                <option value="0">Hepsi</option>
                <option value="1" default selected>Aktif</option>
                <option value="-1">Pasif</option>
              </select>
            </div>
            <div class="col-sm-3 form-group" *ngIf="user.data.claimId > 4">
              <select
                class="form-control"
                name="selectKurum"
                [(ngModel)]="selectKurum"
                (input)="onChangeCustomer($event.target.value)"
              >
                <option value="-1" default selected>Kurum Seçiniz</option>
                <option [value]="item.name" *ngFor="let item of customers">
                  {{ item?.name }}
                </option>
              </select>
            </div>
            <div
              class="col-sm-6"
              *ngIf="
                user.data.claimId === 3 ||
                user.data.claimId === 5 ||
                user.data.claimId === 6
              "
            >
              <div class="text-right">
                <button
                  class="btn btn-info addButton"
                  type="button"
                  (click)="addProjects.openModal()"
                >
                  <span
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <app-feather-icons
                      style="margin-right: 5px; display: flex"
                      [icon]="'plus'"
                    >
                    </app-feather-icons>
                    Yeni Proje
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div>
            <div class="mat-elevation-z8">
              <p-table
                [value]="filteredProjects"
                [columns]="columns"
                [tableStyle]="{ 'min-width': '50rem' }"
                responsiveLayout="scroll"
                [rows]="10"
                [showCurrentPageReport]="true"
                [paginator]="true"
                [rowsPerPageOptions]="[10, 25, 50]"
                currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} gösteriliyor"
                #tt
                [globalFilterFields]="dynamicGlobalFilterFields"
                [scrollable]="true"
                scrollHeight="600px"
              >
                <ng-template pTemplate="caption">
                  <div class="flex justify-content-end align-items-center">
                    <div class="p-input-icon-left">
                      <i class="pi pi-search"></i>
                      <input
                        type="text"
                        pInputText
                        placeholder="Ara"
                        (input)="
                          tt.filterGlobal($event.target.value, 'contains')
                        "
                      />
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th
                      *ngFor="let col of columns"
                      [pSortableColumn]="col.prop"
                    >
                      {{ col.name }}
                      <p-sortIcon [field]="col.prop"></p-sortIcon>
                    </th>
                    <th></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>
                    <td *ngFor="let col of columns">
                      {{ rowData[col.prop] }}
                    </td>
                    <td>
                      <div class="example-button-container d-flex">
                        <button
                          (click)="onClick(rowData.id)"
                          mat-mini-fab
                          color="primary"
                          style="margin-right: 10px"
                          matTooltip="Detay"
                        >
                          <mat-icon>arrow_forward</mat-icon>
                        </button>
                        <app-edit-project
                          *ngIf="
                            user.data.claimId === 3 ||
                            user.data.claimId === 5 ||
                            user.data.claimId === 6
                          "
                          [id]="rowData?.id"
                          [users]="users"
                          [customers]="customers"
                        ></app-edit-project>
                        <button
                          *ngIf="
                            user.data.claimId === 5 || user.data.claimId === 6
                          "
                          (click)="
                            deleteProjectModal(projectDelete, rowData.id)
                          "
                          mat-mini-fab
                          color="warn"
                          matTooltip="Sil"
                        >
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="100%">Gösterilecek veri bulunmamaktadır</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
          <!-- <ngx-datatable
            *ngIf="projects"
            #projectDatatable
            class="bootstrap"
            [rows]="projects"
            [columns]="columns"
            [columnMode]="'force'"
            headerHeight="auto"
            rowHeight="auto"
            [footerHeight]="50"
            [limit]="10"
          >
            <ngx-datatable-column
              [width]="-100"
              *ngFor="let column of columns; let i = index"
              name="{{ column.name }}"
              prop="{{ column.prop }}"
            >
            </ngx-datatable-column>
            <ngx-datatable-column minWidth="300" [sortable]="false" name="">
              <ng-template
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                <div style="margin-top: -10px; display: flex">
                  <button
                    class="btn btn-primary"
                    style="margin-right: 10px"
                    (click)="onClick(row.id)"
                  >
                    <span
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <app-feather-icons
                        style="margin-right: 5px"
                        [icon]="'edit'"
                      >
                      </app-feather-icons>
                      İşler
                    </span>
                  </button>
                 
                  <app-edit-project
                    *ngIf="
                      user.data.claimId === 3 ||
                      user.data.claimId === 5 ||
                      user.data.claimId === 6
                    "
                    [id]="row?.id"
                    [users]="users"
                    [customers]="customers"
                  ></app-edit-project>
                  <button
                    *ngIf="
                      user.data.claimId === 3 ||
                      user.data.claimId === 5 ||
                      user.data.claimId === 6
                    "
                    class="btn btn-danger"
                    (click)="deleteProjectModal(projectDelete, row.id)"
                  >
                    <span
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <app-feather-icons
                        style="margin-right: 5px"
                        [icon]="'trash-2'"
                      >
                      </app-feather-icons>
                      Sil
                    </span>
                  </button>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable> -->
        </div>
      </div>
    </div>
    <!-- Individual column searching (text inputs) Ends-->
  </div>
</div>

<app-add-project #addProjects (onSave)="updateTable()"></app-add-project>

<div class="card" hidden style="border-radius: 20px">
  <div class="card-header">
    <h5>Modal with default options</h5>
  </div>
  <div class="card-body">
    <ng-template #projectDelete let-modal>
      <div class="modal-header">
        <h4 class="modal-title text-danger" id="modal-basic-title">
          Projeyi Sil
        </h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5>Proje Silinecek Emin misin?</h5>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          (click)="modal.close('Cross click')"
        >
          Sil
        </button>
        <button
          type="button"
          class="btn btn-success"
          (click)="modal.dismiss('close')"
        >
          Vazgeç
        </button>
      </div>
    </ng-template>
  </div>
</div>
<!-- <app-edit-project
  [users]="users"
  [customers]="customers"
  #editProject
  (onSave)="updateTable()"
></app-edit-project>
<app-delete-project
  #deleteProjects
  (onSave)="updateTable()"
></app-delete-project> -->

<!-- Container-fluid Ends-->
